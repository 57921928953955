import { gql, useQuery } from '@apollo/client'
import { OrderContentsResult } from '../../../graphql/aminTypes'

const orderDetailQuery = gql`
  query orderDetail($orderNumber: Int) {
    orderDetail(orderNumber: $orderNumber) {
      serviceType
      weightTonnes
      id
      container {
        id
      }
      product {
        id
      }
      terminal {
        id
      }
      supplier {
        id
      }
      supplierProfitWoVatKc
      containerFromDate
      containerToDate
      containerCount
      arriveTime
      collectionTime
      distanceTotalKm
      codToCollect
      totalForContentWoVatKc
      adminNote
      supplierNote
      customerNote
      wasteDescription
      onsiteContactFirstname
      onsiteContactLastname
      onsiteContactPhone
      orderContentStateReason
      orderContentState {
        id
        name
        sysName
      }
      orderContentPrices {
        id
        priceWoVatKc
        orderContentPriceItem {
          id
          name
          sysName
          partOfTotalPrice
          uiOrder
        }
      }
      order {
        id
        firstname
        lastname
        email
        phone
        street
        streetNumber
        companyName
        city
        zip
        totalWoVatKc
        totalInclVatKc
        paymentType
        orderNote
        ico
        dic
        targetCompanyIco
        targetCompanyName
        goPayPaymentStatus
        paymentStatus
        invoicingNote
        invoicingNoteDone
        gps {
          coordinates
        }
        b2bCustomerId
        notificationSettings {
          disableUnpaidOrderNotification
        }
        companyResidence {
          id
          street
          streetNumber
          city
          zip
          countryIso
        }
      }
    }
  }
`

export function useOrderDetailQuery(orderNumber: string | null, setupFields: (data: { orderDetail: OrderContentsResult }) => void) {
  return useQuery<{ orderDetail: OrderContentsResult }>(orderDetailQuery, {
    fetchPolicy: 'network-only',
    variables: { orderNumber: orderNumber ? parseInt(orderNumber) : 0 },
    skip: !orderNumber,
    onCompleted: setupFields
  })
}
