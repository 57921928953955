import { gql, useQuery } from '@apollo/client'
import { CustomerListPaginatedResponse } from '../../../../../graphql/aminTypes'

const customerListQuery = gql`
  query customerList {
    customerList {
      items {
        id
        companyIdentification
        companyTaxId
        companyName
        firstname
        lastname
        phone
        email
        b2bMarginCorrection
        metrakMarginCorrection
        city
        streetNumber
        street
        zip
        countryIso
        primaryPaymentMethod
        allowedPaymentMethods
      }
    }
  }
`

export function useCustomerListQuery() {
  return useQuery<{ customerList: CustomerListPaginatedResponse }>(customerListQuery)
}
