import {
  ColumnAlign,
  ColumnFilterTypes,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes,
  WayOfIdHandover
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { IOrderContentListItem } from '../queries/order-list.query'
import { moneyToLocaleString } from '../../../services/dataToString/decimalToLocaleString'
import { orderContentStateBadgeColors } from '../../../services/types/order-content-state-badge-colors'
import { httpBuildQueryString } from '../../../services/functions/httpBuildQuery'
import { AlertSvg } from '../../../components/svgIcons/AlertSvg'
import { FlagIcon } from '../../../components/svgIcons/FlagIcon'
import React from 'react'
import { OrderContentTypesEnum } from '../../../services/types/orderContentTypesEnum'
import { Link } from 'react-router-dom'
import { AuthorisationAlertSvg } from '../../../components/svgIcons/AuthorisationAlertSvg'
import UserAvatar from 'react-user-avatar'
import { decimalRound } from '../../../services/functions/decimalRound'
import { paymentMethodsOptions, paymentMethodBadgeColors } from '../../../services/types/payment-method'
import { avatarColor } from '../../../components/appComponents/avatar-color'
import classNames from 'classnames'
import { decideProperPaymentStatus } from '../../../services/functions/decideProperPaymentStatus'
import dayjs from 'dayjs'
import { serviceTypeTranslation } from '../../../services/types/service-type'
import { AccountingDocumentTranslation } from '../../../services/types/document-type'
import { paymentStateBadgeColors } from '../../../services/types/payment-status'

export function orderListDataDefinition(
  allproducts,
  toggleCertifFn: Function,
  toggleInvoicingNoteDone: Function,
  highlightFromDates?: string[],
  highlightToDates?: string[],
  isMobile?: boolean
): TableDefinitionTypes<IOrderContentListItem> {
  let tableDef = {
    columnDefinition: {
      id: { hidden: true },
      totalInclVatForSum: { hidden: true },
      totalWoVatForSum: { hidden: true },
      actionsMob: {
        name: 'Akce',
        columnFormatterType: ColumnFormatterTypes.actionsObject,
        headerStyle: { width: '100px' },
        align: ColumnAlign.right,
        hidden: !isMobile,
        isMobile: isMobile
      },
      adminOwner: {
        name: 'Vlastník',
        headerStyle: { width: '100px', zIndex: 300 },
        filterType: ColumnFilterTypes.customSelect,
        align: ColumnAlign.center
      },
      createdAt: {
        name: 'Vytvořena',
        filterType: ColumnFilterTypes.dateRange,
        headerStyle: { width: '80px', zIndex: 300 }
      },
      orderNumber: {
        name: 'Objedn.',
        columnFormatterType: ColumnFormatterTypes.showAsLink,
        filterType: ColumnFilterTypes.customText,
        remoteSort: true,
        headerStyle: { width: '120px' },
        defaultRemoteSort: 'desc'
      },
      name: { name: 'Jméno / IČO', filterType: ColumnFilterTypes.customText },
      serviceType: {
        name: 'Typ zakázky',
        filterType: ColumnFilterTypes.customSelect,
        align: ColumnAlign.center,
        headerStyle: { width: '150px' }
      },
      containerFromDate: {
        name: 'Přist.',
        remoteSort: true,
        filterType: ColumnFilterTypes.date,
        headerStyle: { width: '150px', zIndex: 340 },
        align: ColumnAlign.right,
        highlightDates: highlightFromDates
      },
      containerToDate: {
        name: 'Odvoz',
        remoteSort: true,
        filterType: ColumnFilterTypes.date,
        headerStyle: { width: '150px', zIndex: 320 },
        align: ColumnAlign.right,
        highlightDates: highlightToDates
      },
      city: { name: 'Adresa', filterType: ColumnFilterTypes.customText, headerStyle: { minWidth: '100px' } },
      product: {
        name: 'Produkt',
        filterType: ColumnFilterTypes.customSelect,
        headerStyle: { minWidth: '100px' },
        nameForGqlFilter: 'productId'
      },
      container: {
        name: 'Kontejner / Množství',
        filterType: ColumnFilterTypes.customSelect,
        headerStyle: { minWidth: '120px' },
        nameForGqlFilter: 'containerId'
      },
      supplier: {
        name: 'Dodavatel',
        filterType: ColumnFilterTypes.customText,
        columnFormatterType: ColumnFormatterTypes.showAsLink,
        headerStyle: { minWidth: '120px' }
      },
      totalWoVatKc: { name: 'Celkem bez DPH', align: ColumnAlign.right },
      totalInclVatKc: { name: 'Celkem s DPH', align: ColumnAlign.right },
      flags: { name: 'Příznaky', headerStyle: { width: '130px' }, filterType: ColumnFilterTypes.customSelect },
      paymentType: {
        name: 'Platba',
        columnFormatterType: ColumnFormatterTypes.showInBadge,
        align: ColumnAlign.center,
        badgeColours: paymentMethodBadgeColors,
        filterType: ColumnFilterTypes.customSelect,
        headerStyle: { minWidth: '90px' }
      },
      paymentStatus: {
        name: 'Stav platby',
        align: ColumnAlign.center,
        filterType: ColumnFilterTypes.customSelect,
        headerStyle: { minWidth: '90px' }
      },
      state: {
        name: 'Stav',
        align: ColumnAlign.center,
        filterType: ColumnFilterTypes.customSelect,
        headerStyle: { minWidth: '100px' }
      },
      gps: { name: 'gps', hidden: true },
      uuid: { hidden: true },
      supplierEmail: { hidden: true },
      stateSys: { hidden: true },
      actions: {
        name: 'Akce',
        columnFormatterType: ColumnFormatterTypes.actionsObject,
        headerStyle: { width: '100px' },
        align: ColumnAlign.right,
        hidden: isMobile,
        isMobile: isMobile
      }
    },
    sourceToColumnMapping: {
      id: x => x.id,
      totalInclVatForSum: x => x.order.totalInclVatKc,
      totalWoVatForSum: x => x.order.totalWoVatKc,
      adminOwner: x => {
        if (x.order.adminOwner && x.order.adminOwner.firstname) {
          const avatarName = `${x.order.adminOwner.firstname} ${x.order.adminOwner.lastname}`
          return <UserAvatar size={32} name={avatarName} colors={avatarColor(avatarName)} />
        } else return <strong>N/A</strong>
      },
      createdAt: x => dayjs(x.order.createdAt).format('L'),
      orderNumber: x => x.order.orderNumber,
      serviceType: x => serviceTypeTranslation[x.serviceType],
      name: x => (x.order.companyName ? x.order.companyName : x.order.firstname + ' ' + x.order.lastname),
      containerFromDate: x => (
        <>
          {`${dayjs(x.containerFromDate).format('ddd DD.MM.YYYY')} `} <br /> {x.arriveTime}
        </>
      ),
      containerToDate: x =>
        x.containerToDate ? (
          <>
            {dayjs(x.containerToDate).format('ddd DD.MM.YYYY')}
            <br /> {x.collectionTime ?? ''}
          </>
        ) : (
          ''
        ),
      city: x => (
        <a
          href={`http://www.google.com/maps/place/${x.order.gps.coordinates[1]},${x.order.gps.coordinates[0]}`}
          target="_blank"
          rel="noopener noreferrer"
        >{`${x.order.city}, ${x.order.street} ${x.order.streetNumber}`}</a>
      ),
      product: x => x.product.name,
      container: x => {
        if (x.serviceType === 'MATERIAL_DELIVERY') {
          const newProduct = allproducts.data.products.find(y => y.id === x.product.id)
          return (
            <>
              {decimalRound(x.weightTonnes / newProduct.tonToCubes, 2).toLocaleString('cs-CZ')} m<sup>3</sup> ({x.weightTonnes} t)
            </>
          )
        } else {
          return (
            <>
              <strong>{x.containerCount}× </strong>
              {x.container.name} {x.container.capacityM3} m³
            </>
          )
        }
      },
      supplier: x => (
        <>
          {x.supplier.capability.includes('BO') || x.supplier.capability.includes('O') || x.supplier.capability.length === 0 ? (
            <span className={'mr-1'} title={x.supplier.capability.length > 0 ? 'Oprávnění: ' + x.supplier.capability.join(' ') : 'Nemá oprávnění'}>
              {' '}
              <AuthorisationAlertSvg color={'red'} />
            </span>
          ) : null}
          {!x.supplier.email ? (
            <span title="Nemá zadanou emailovou adresu">
              <AlertSvg color={'red'} />
            </span>
          ) : null}
          <Link to={`/supplier-list?id=${x.supplier.id}`}>
            <strong>{`${x.supplier.name}`}</strong> {`(${x.supplier.regionCode ?? ''} - ${x.supplier.orpName ?? ''}) `}
          </Link>
        </>
      ),
      totalWoVatKc: x => moneyToLocaleString(x.order.totalWoVatKc, 'CZK', false),
      totalInclVatKc: x => <strong>{moneyToLocaleString(x.order.totalInclVatKc, 'CZK')}</strong>,
      paymentType: x => paymentMethodsOptions.find(pt => pt.value === x.order.paymentType)?.label,
      paymentStatus: x => {
        const ret = decideProperPaymentStatus(x.order.paymentType, x.order.goPayPaymentStatus, x.order.paymentStatus)
        const translatedPaymentStatus = ret.translatedPaymentStatus
        return (
          <>
            <span className={classNames('badge', 'badge-wrap', paymentStateBadgeColors[translatedPaymentStatus])}>{translatedPaymentStatus}</span>
            {ret.showWarning && (
              <span title={`Stav v GoPay ${x.order.goPayPaymentStatus}`}>
                <AlertSvg color={'red'} />
              </span>
            )}
          </>
        )
      },
      state: x => {
        return (
          <>
            <span className={classNames('badge', 'badge-wrap', orderContentStateBadgeColors[x.orderContentState.name])}>{x.orderContentState.name}</span>
            {x.approvedAt && x.orderContentState.sysName === 'WaitingForPayment' && (
              <span title={`Schváleno operátorem. Po zaplacení bude předáno na dodavatele`}>
                <AlertSvg color={'red'} />
              </span>
            )}
          </>
        )
      },
      gps: x => x.order.gps.coordinates,
      uuid: x => x.order.uuid,
      supplierEmail: x => x.supplier.email,
      stateSys: x => x.orderContentState.sysName,
      flags: x => (
        <>
          <span className="badge badge-pill bg-purple mr-2 pt-1" title={'Přiložených souborů'}>
            {x.filesCount}
          </span>
          {x.order.createdById && <FlagIcon className="icon-hand" value={true} desc={'Ruční objednávka'} />}
          {x.order.b2bCustomerId && <FlagIcon className="fe fe-briefcase fe-larger" value={true} desc={'Objednávka registrovaného uživatele'} />}
          {x.order.orderNote && <FlagIcon className="fe fe-message-circle fe-larger" value={true} desc={'Poznámka od zákazníka: ' + x.order.orderNote} />}
          {x.supplierNote && <FlagIcon className="fe fe-message-square fe-larger" value={true} desc={'Poznámka pro dodavatele: ' + x.supplierNote} />}
          {x.customerNote && <FlagIcon className="fe fe-book-open fe-larger" value={true} desc={'Poznámka pro zákazníka: ' + x.customerNote} />}
          {x.wasteDescription && <FlagIcon className="fe fe-trash fe-larger" value={true} desc={'Popis odpadu: ' + x.wasteDescription} />}
          {x.adminNote && <FlagIcon className="fe fe-type fe-larger" value={true} desc={'Poznámka admin: ' + x.adminNote} />}
          {x.hasZpoDelivered && <FlagIcon className="icon-zpo" desc={'Zpo bylo odesláno'} value={true} />}
          {x.supplier.zpoNote && <FlagIcon className="icon-zpo-note" desc={x.supplier.zpoNote} value={true} />}
          {x.hasWasteFinals && <FlagIcon className="icon-scale" value={true} desc={`Váženka`} />}
          {x.order.requireCertificateState > 0 && (
            <FlagIcon
              className={x.order.requireCertificateState === 1 ? 'icon-file-certificate--red' : 'icon-file-certificate--green'}
              value={true}
              desc={x.order.requireCertificateState === 1 ? 'Vyžadováno potvrzení o odvozu odpadu' : 'Potvrzení o odvozu odpadu vystaveno'}
              onClick={toggleCertifFn(x.order.id, x.order.requireCertificateState)}
            />
          )}
          {x.order.invoicingNote && (
            <FlagIcon
              className="fe fe-bookmark fe-larger"
              value={!x.order.invoicingNoteDone}
              desc={'Poznámka k fakturaci: ' + x.order.invoicingNote}
              onClick={toggleInvoicingNoteDone(x.order.id, x.order.invoicingNoteDone)}
            />
          )}
          {x.order.accountingDocuments.find(x => x.isGroupInvoice) && (
            <FlagIcon className="fe fe-layers fe-larger" desc={'Součástí hromadné faktury'} value={true} />
          )}
          {x.order.accountingDocuments.length !== 0 && (
            <FlagIcon
              className="fe fe-file-text fe-larger"
              desc={`Má účetní dokumenty: ${x.order.accountingDocuments.map(x => AccountingDocumentTranslation[x.type]).join(', ')}`}
              value={true}
            />
          )}
          {x.orderContentPrices?.map(oc => oc.orderContentPriceItem.sysName).indexOf('priceLimitDiscount') >= 0 && (
            <FlagIcon className="fe fe-alert-triangle fe-larger" desc={'Cena vypočtena pomocí cenového stropu'} value={true} />
          )}
          {x.order.unpaidNotificationSent && <FlagIcon className="fe fe-bell" desc={'Zaslána notifikace o nezaplacení'} value={true} />}
          {x.order.partnerSource && <FlagIcon className="fe fe-award" desc={`Objednávka přes partnera ${x.order.partnerSource}`} value={true} />}
        </>
      ),
      actions: x => {
        const ret: IRowAction[] = []
        if (x.orderContentState.sysName === OrderContentTypesEnum.WaitingForPayment.toString()) {
          ret.push({
            type: RowActionType.codeAction,
            value: 'skipPayment',
            icon: 'fe-chevrons-right',
            title: 'Nečekat na zaplacení, přejít do stavu "Čeká na schválení adminem"'
          })
        }
        if (['RefusedBySupplier', 'ForAdminToApprove'].includes(x.orderContentState.sysName)) {
          ret.push({
            type: RowActionType.codeAction,
            value: 'approveByAdmin',
            icon: 'fe-truck',
            title: 'Schválit objednávku a předat nabídku na dopravce'
          })
        }
        if (OrderContentTypesEnum.ForSupplierToConfirm.toString() === x.orderContentState.sysName) {
          ret.push({
            type: RowActionType.codeAction,
            value: 'undoApproveByAdmin',
            icon: 'fe-truck fe-disabled-icon',
            title: 'Vzít dopravci nabídku zpět'
          })
        }
        if (x.orderContentState.sysName === 'OrderFinishedBySupplier') {
          ret.push({
            type: RowActionType.codeAction,
            value: 'orderChargedByAdmin',
            icon: 'fe-dollar-sign',
            title: 'Zaúčtovat'
          })
        }
        if (!['OrderChargedByAdmin', 'Cancelled'].includes(x.orderContentState.sysName))
          ret.push({
            type: RowActionType.codeAction,
            value: 'editOrder',
            icon: 'fe-edit',
            title: 'Upravit'
          })
        ret.push({
          type: RowActionType.routerLink,
          value: 'add-order',
          wayOfIdHandover: WayOfIdHandover.search,
          searchStringBuilder: row =>
            httpBuildQueryString({
              orderNumber: row.orderNumber,
              action: 'duplicate'
            }),
          icon: 'fe-copy',
          title: 'Duplikovat'
        })
        return ret
      }
    }
  }
  tableDef.sourceToColumnMapping['actionsMob'] = tableDef.sourceToColumnMapping['actions']
  return tableDef as TableDefinitionTypes<IOrderContentListItem>
}
