import React from 'react'
import { useAccountingDocumentsQuery } from './query/accountingDocumentsQuery'
import useBootstrapTableDataMapper from '../../../../services/bootstrapTable/useBootstrapTableDataMapper'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import { accountingDocumentsDataDefinition } from './services/accountingDocumentsDataDefinition'
import { usePaymentActionMutation } from '../accounting-options/mutation/paymentActionMutation'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { useSyncAccountingDocumentHandler } from '../../../../services/mutations/sync-accounting-document-handler/sync-accounting-document-handler'

export default function AccountingDocuments({ orderId }: { orderId: number }) {
  const accountingDocumentsQR = useAccountingDocumentsQuery(orderId)
  const [paymentActionMut, paymentActionQR] = usePaymentActionMutation()
  const [syncAccountingDocumentHandler, syncAccountingDocumentQR] = useSyncAccountingDocumentHandler(['accountingDocuments'])
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper(accountingDocumentsDataDefinition)
  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      SendInvoiceByEmail: SendInvoiceByEmailHandler,
      RefundEmail2Customer: RefundEmail2CustomerHandler,
      SyncAccountingDocument: syncAccountingDocumentHandler
    }
  })

  let accountingDocuments: any[] = []
  if (accountingDocumentsQR.data && accountingDocumentsQR.data.accountingDocuments)
    accountingDocuments = mapDataArrayToTable(accountingDocumentsQR.data.accountingDocuments)
  const highlightCancelled = (row: any) => (row.isCancelled ? 'redBackground' : '')

  return (
    <fieldset className="form-fieldset">
      <header>
        <h2>Účetní dokumenty</h2>
      </header>
      <LoadingContainer showLoader={isAnyQueryLoading(accountingDocumentsQR, syncAccountingDocumentQR, paymentActionQR)}>
        <BootstrapTable
          bootstrap4
          striped
          condensed
          rowClasses={highlightCancelled}
          classes="table-responsive-lg digiTable"
          noDataIndication={() => <>Nejsou vytvořené žádné účetní dokumenty</>}
          keyField="id"
          data={accountingDocuments}
          columns={columnDefinition}
        />
      </LoadingContainer>
    </fieldset>
  )

  function SendInvoiceByEmailHandler(row: any) {
    if (!window.confirm('Odeslat email s fakturou zákazníkovi?')) return
    const promise = paymentActionMut({
      variables: {
        orderId,
        action: 'SendInvoiceByEmail',
        paramsJson: JSON.stringify({ accountingDocumentId: row.id })
      }
    })
    processPageOperation({
      promise: promise,
      successMessage: 'Email s fakturou byl odeslán zákazníkovi.',
      successAction: () => {
        accountingDocumentsQR.refetch()
      }
    })
  }
  function RefundEmail2CustomerHandler(row: any) {
    if (!window.confirm('Označit objednávku jako vrácenou, označit zaplacení ve Vyfakturuj a odeslat zákazníkovi emailem?')) return
    const promise = paymentActionMut({
      variables: {
        orderId,
        action: 'RefundEmail2Customer',
        paramsJson: '{}'
      }
    })
    processPageOperation({
      promise: promise,
      successMessage: 'Email s vratkou byl odeslán zákazníkovi a provedeno zaplacení ve Vyfakturuj a změn stav na vráceno.',
      successAction: () => {
        accountingDocumentsQR.refetch()
      }
    })
  }
}
