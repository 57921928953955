import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { useUpsertProductContainerMutation } from './mutations/upsert-product-container.mutation'
import { ProductContainerEntity } from '../../../../graphql/aminTypes'
import { setupFields } from '../../../../services/formsServices/setupFields'

export default function AddOrEditContainer({ containerToEdit, exitHandler }: { containerToEdit?: ProductContainerEntity; exitHandler: () => void }) {
  const formMethods = useForm()
  const [upsertProductContainerMut, upsertProductContainerQR] = useUpsertProductContainerMutation()
  const containerImages = [
    'small-container',
    'mid-container',
    'large-container1',
    'large-container2',
    'large-container3',
    'big-bag',
    'bath-tube-small',
    'bath-tube-mid',
    'bath-tube-large',
    'transport'
  ]
  useEffect(() => {
    if (containerToEdit) {
      setupFields(containerToEdit, { formMethods, includeKeys: ['name', 'tooltip', 'volumeM3', 'uiOrder', 'uiPicture'] })
    }
  }, [containerToEdit])
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(upsertProductContainerQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <fieldset className="form-fieldset">
            <div className="row mb-2">
              <div className="col-md-6">
                <FormField type={inputType.text} name={'name'} label={'Název'} required registerOptions={{ required: true }} />
              </div>
              <div className="col-md-6">
                <FormField type={inputType.text} name={'tooltip'} label={'Tooltip'} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-3">
                <FormField
                  type={inputType.number}
                  name={'volumeM3'}
                  label={'Objem [m³]'}
                  required
                  registerOptions={{ required: true }}
                  valueAsNumber
                  isDecimal
                />
              </div>
              <div className="col-md-3">
                <FormField type={inputType.number} name={'uiOrder'} label={'Pořadí zobrazení'} registerOptions={{ required: true }} valueAsNumber required />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <label className="form-label">Ikona pro uživatele</label>
                <div className="form-selectgroup">
                  {containerImages.map(containerUiPicture => (
                    <label className="form-selectgroup-item " key={containerUiPicture}>
                      <input
                        type="radio"
                        value={containerUiPicture}
                        className="form-selectgroup-input"
                        {...formMethods.register('uiPicture', { required: true })}
                      />
                      <span className="form-selectgroup-label">
                        <img
                          src={`https://${process.env.REACT_APP_FE_HOSTNAME}/assets/images/containers/${containerUiPicture}.svg`}
                          width="100"
                          alt={containerUiPicture}
                        />
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <div className="form-footer">
              <button type="submit" className="btn btn-primary w-100">
                {containerToEdit ? 'Uložit' : 'Přidat'}
              </button>
            </div>
          </fieldset>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function handleFormSubmit(data: Record<string | number | symbol, any>) {
    let resultPromise = upsertProductContainerMut({
      variables: { data, productContainerId: containerToEdit?.id }
    })

    processPageOperation({
      promise: resultPromise,
      successAction: exitHandler,
      successMessage: `Kontejner byl ${containerToEdit ? 'upraven' : 'přidán'}.`
    })
    return false
  }
}
