import React from 'react'
import FormField, { inputType } from '../../../components/formComponents/FormField'
import ToggleField from '../../../components/formComponents/ToggleField'
import classNames from 'classnames'

export function SettingsNode() {
  return (
    <>
      <div className="row mb-2">
        <div className="col-md-2">
          <FormField
            name={'settings.containerRentFreeForDays'}
            label={'Počet dní pronájmu kontejneru zdarma'}
            type={inputType.number}
            defaultValue={3}
            valueAsNumber
          />
        </div>
        <div className="col-md-3">
          <FormField name={'settings.includedKmInFlatRate'} label={'Km v ceně paušálu (paušál za celou službu)'} type={inputType.number} valueAsNumber />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-sm-12">
          <hr />
          <h3>Výpočet odděleně - doprava a koncovka</h3>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-2">
          <FormField name={'settings.manipulationsPerOrder'} label={'# manipulací na zakázku'} type={inputType.number} valueAsNumber />
        </div>
        <div className="col-md-2">
          <strong>Výpočet km/kč</strong>
          <fieldset className={classNames('form-fieldset', 'bg-orange-lt')}>
            <FormField name={'settings.minimumPriceOfTransportKc'} label={'Minimální cena za dopravu Kč'} type={inputType.number} valueAsNumber />
          </fieldset>
        </div>
        <div className="col-md-6">
          <strong>Výpočet přes paušály za dopravu</strong>
          <fieldset className={classNames('form-fieldset', 'bg-orange-lt')}>
            <div className="row">
              <div className="col-md-3">
                <FormField name={'settings.flatRateForDeliveryKc'} label={'Paušál za přistavení Kč'} type={inputType.number} valueAsNumber />
              </div>
              <div className="col-md-3">
                <FormField name={'settings.flatRateForPickupKc'} label={'Paušál za vyzvednutí Kč'} type={inputType.number} valueAsNumber />
              </div>
              <div className="col-md-6">
                <FormField
                  name={'settings.customerDistanceInFlatRateKm'}
                  label={'Vzdálenost od dodavatele zahrnutá v paušálu km'}
                  type={inputType.number}
                  valueAsNumber
                />
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </>
  )
}
