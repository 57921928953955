import { gql, useQuery } from '@apollo/client'
import { DemandChangeLogEntity, EmailHistory } from '../../../../../graphql/aminTypes'
import { contextEndpoint, EndpointEnum } from '../../../../../services/appConfiguration/contextEndpoint'

export const orderChangeLogQuery = gql`
  query orderChangeLog($orderId: Int!) {
    orderChangeLog(orderId: $orderId) {
      id
      createdAt
      login {
        id
        firstname
        lastname
      }
      operation
      variables
      result
      source
    }
  }
`

export function useOrderChangeLogQuery(orderId?: number | undefined) {
  return useQuery<{ orderChangeLog: DemandChangeLogEntity[] }>(orderChangeLogQuery, {
    context: contextEndpoint(EndpointEnum.admin),
    variables: { orderId },
    skip: !orderId,
    fetchPolicy: 'network-only'
  })
}
