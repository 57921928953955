import SelectField from '../../../../components/formComponents/SelectField'
import React from 'react'
import { useProductWasteListFlatQuery } from './queries/product-waste-list.query'
import { WasteCategory } from '../../../../graphql/aminTypes'

export function ProductWasteSelector({
  doNotShowWastes,
  availableWastes,
  wasteCategory,
  disabled,
  label
}: {
  doNotShowWastes?: string[]
  availableWastes?: string[]
  wasteCategory: WasteCategory
  disabled?: boolean
  label: string
}) {
  const productWasteListFlatQR = useProductWasteListFlatQuery(wasteCategory)
  const productWastes =
    productWasteListFlatQR.data?.productWasteListFlat
      .filter(x => !(doNotShowWastes ?? []).includes(x.id))
      .map(x => ({
        value: x.id,
        color: (availableWastes ?? []).includes(x.id) ? 'black' : '#ccc',
        label: `${x.hasAdditivesSpecification ? '* ' : ''}${x.parentWasteId ? '- ' : ''}${x.name}${
          x.childWastes && x.childWastes.length > 0 ? `(${x.childWastes.length})` : ''
        }`
      })) || []

  return <SelectField name={'productWasteId'} label={label} optionsData={productWastes} disabled={disabled} required />
}
