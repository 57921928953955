import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import classNames from 'classnames'
import { AccountingGroupOrders, OrderResult } from '../../../../../graphql/aminTypes'
import LoadingContainer from '../../../../../components/LoadingContainer'
import { processPageOperation } from '../../../../../services/formsServices/pageOperationProcessor'
import { useCreateGroupInvoiceForOrdersMutation } from './mutations/create-group-invoice-for-orders.mutation'
import BootstrapTable from 'react-bootstrap-table-next'
import { groupOrdersTableSelectRowConfig } from './services/table-select-row-setting'
import useBootstrapTableDataMapper from '../../../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { groupOrdersDataDefinition } from './services/group-orders-data-definition'
import DatePickerComp from '../../../../../components/formComponents/datePickerComp'
import FormField, { inputType } from '../../../../../components/formComponents/FormField'
import ToggleField from '../../../../../components/formComponents/ToggleField'
import { HtmlDataList } from '../../../../../components/HtmlDataList'
import { moneyToLocaleString } from '../../../../../services/dataToString/decimalToLocaleString'
import { formDefaults } from './services/form-defaults'
import { formatAddressToDisplay } from '../../../../../services/dataToString/formatAddressToDisplay'
import { convertDateForGql } from '../../../../../services/functions/convertDateForGql'
import { decimalRound } from '../../../../../services/functions/decimalRound'

export function CreateGroupInvoice({ groupOrdersData, isDek, onExit }: { groupOrdersData: AccountingGroupOrders; isDek: boolean; onExit: () => void }) {
  const [selectedTotals, setSelectedTotals] = useState({
    woVat: groupOrdersData.totalWoVatKc,
    withVat: groupOrdersData.totalInclVatKc
  })
  const [createGroupInvoiceForOrdersMut, createGroupInvoiceForOrdersQR] = useCreateGroupInvoiceForOrdersMutation()
  const formMethods = useForm({
    defaultValues: formDefaults(
      isDek,
      isDek ? groupOrdersData.invoicingCustomer?.email : (groupOrdersData.customer?.email ?? groupOrdersData.orders[0].email ?? '')
    )
  })
  const noTotalRounding = formMethods.watch('noTotalRounding') ? 1 : 0
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set(groupOrdersData.orders.map(order => order.id)))
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper<OrderResult>(groupOrdersDataDefinition())
  const columnDefinition = generateColumnListForBootstrapTable({})

  let groupOrderDataForTable: any[] = mapDataArrayToTable(groupOrdersData.orders)
  const invoiceRecap = [
    {
      label: 'Firma',
      text:
        groupOrdersData.customer?.companyName ??
        (groupOrdersData.orders[0].companyName || `${groupOrdersData.orders[0].firstname} ${groupOrdersData.orders[0].lastname}`)
    },
    { label: 'IČO', text: groupOrdersData.customer?.companyIdentification ?? groupOrdersData.orders[0].ico },
    { label: 'Adresa', text: groupOrdersData.customer ? formatAddressToDisplay(groupOrdersData.customer) : formatAddressToDisplay(groupOrdersData.orders[0]) }
  ]
  const invoicingCompany = [
    { label: 'Celkem bez DPH', text: moneyToLocaleString(selectedTotals.woVat, 'CZK') },
    { label: 'Celkem s DPH', text: moneyToLocaleString(selectedTotals.withVat, 'CZK') },
    {
      label: 'Firma',
      text:
        groupOrdersData.invoicingCustomer?.companyName ??
        (groupOrdersData.orders[0].companyName || `${groupOrdersData.orders[0].firstname} ${groupOrdersData.orders[0].lastname}`)
    },
    { label: 'IČO', text: groupOrdersData.invoicingCustomer?.companyIdentification ?? groupOrdersData.orders[0].ico },
    { label: 'DIČ', text: groupOrdersData.invoicingCustomer?.companyTaxId ?? groupOrdersData.orders[0].dic },
    {
      label: 'Adresa',
      text: groupOrdersData.invoicingCustomer
        ? formatAddressToDisplay(groupOrdersData.invoicingCustomer)
        : groupOrdersData.orders[0].companyResidence
          ? formatAddressToDisplay(groupOrdersData.orders[0].companyResidence)
          : formatAddressToDisplay(groupOrdersData.orders[0])
    }
  ]

  return (
    <LoadingContainer showLoader={createGroupInvoiceForOrdersQR.loading}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <fieldset className={classNames('form-fieldset')}>
            <div className="row mb-2">
              <div className="col-md-6">
                <DatePickerComp name={'createdDate'} label={'Datum vystavení'} required />
              </div>
              <div className="col-md-6">
                <FormField type={inputType.text} name={'externalReference'} label={'Číslo objednávky'} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6">
                <DatePickerComp name={'dueDate'} label={'Datum splatnosti'} required />
              </div>
              <div className="col-md-6">
                <FormField type={inputType.email} name={'clientEmail'} label={'Email pro zaslání faktury'} required />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6">
                <DatePickerComp name={'duzpDate'} label={'Datum zdanitelného plnění'} required />
              </div>
              <div className="col-md-6">
                <ToggleField name={'noTotalRounding'} label={'Částka s DPH bez zaokrouhlení'} registerOptions={{ onChange: e => updateSelectedTotals() }} />
                <ToggleField name={'separateOrdersToItems'} label={'Na faktuře uvést každou objednávku na novou řádku'} />
              </div>
            </div>
            <div className="row mb-2 mt-3">
              <div className="col-md-4">
                <HtmlDataList data={invoicingCompany} label={'Fakturace celkem'} />
              </div>
              <div className="col-md-4">
                <HtmlDataList data={invoiceRecap} label={'Hromadná faktura za'} />
              </div>
              <div className="col-md-4">
                <div className="text-right mt-4">
                  <button type="submit" className="btn btn-red ms-auto btn-lg" disabled={!formMethods.formState.errors}>
                    Vytvořit hromadnou fakturu
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </FormProvider>

      <div className="table-responsive-xl">
        <BootstrapTable
          bootstrap4
          striped
          condensed
          hover
          classes="table-responsive-lg digiTable"
          noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné objednávky"</>}
          keyField="id"
          selectRow={groupOrdersTableSelectRowConfig(handleOnSelect, handleOnSelectAll, Array.from(selectedRows))}
          data={groupOrderDataForTable}
          columns={columnDefinition}
        />
      </div>
    </LoadingContainer>
  )

  function handleOnSelect(row: { id: number }, isSelect: boolean) {
    const newSelectedRows = new Set(selectedRows)

    if (isSelect) {
      newSelectedRows.add(row.id)
    } else {
      newSelectedRows.delete(row.id)
    }

    setSelectedRows(newSelectedRows)
    updateSelectedTotals(newSelectedRows)

    return true
  }

  function handleOnSelectAll(isSelect: boolean, rows: any[]) {
    if (isSelect) {
      rows.forEach((r: any) => selectedRows.add(r.id))
    } else {
      rows.forEach((r: any) => selectedRows.delete(r.id))
    }
    const newSelectedRows = new Set(selectedRows)
    setSelectedRows(newSelectedRows)
    updateSelectedTotals(newSelectedRows)
    return true
  }

  function updateSelectedTotals(newSelectedRows?: Set<number>) {
    if (!newSelectedRows) newSelectedRows = selectedRows
    const woVatTotal = Array.from(newSelectedRows).reduce(
      (prev, curr) => prev + (groupOrdersData.orders.find(order => order.id === curr)?.totalWoVatKc ?? 0),
      0
    )
    const actualValueNoTotalRounding = formMethods.watch('noTotalRounding')
    setSelectedTotals({
      woVat: woVatTotal,
      withVat: actualValueNoTotalRounding ? decimalRound(woVatTotal * 1.21, 2) : decimalRound(woVatTotal * 1.21, 0)
    })
  }
  function handleFormSubmit(data: Record<string | number | symbol, any>) {
    const variables = {
      orderIds: Array.from(selectedRows),
      opts: {
        clientEmail: data.clientEmail,
        customerId: isDek ? groupOrdersData?.invoicingCustomer?.id : groupOrdersData.customer?.id,
        createdDate: convertDateForGql(data.createdDate),
        dueDate: convertDateForGql(data.dueDate),
        duzpDate: convertDateForGql(data.duzpDate),
        externalReference: data.externalReference,
        noTotalRounding: data.noTotalRounding,
        separateOrdersToItems: data.separateOrdersToItems
      }
    }
    let resultPromise = createGroupInvoiceForOrdersMut({ variables })

    processPageOperation({
      promise: resultPromise,
      successMessage: 'Transakce byla úspěšně přiřazena k objednávce.',
      successAction: () => {
        onExit()
      }
    })
  }
}
