import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { SupplierMaterialPriceListEntity } from '../../../graphql/aminTypes'
import { moneyToLocaleString } from '../../../services/dataToString/decimalToLocaleString'

export const supplierMaterialPriceListDataDefinition: TableDefinitionTypes<SupplierMaterialPriceListEntity> = {
  columnDefinition: {
    id: { hidden: true },
    isActive: {
      name: 'Aktivní',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      headerStyle: { width: '80px' }
    },
    name: { name: 'Materiál' },
    priceKc: { name: 'Cena za 1t pro Metrák.cz' },
    priceForPublicKc: { name: 'Cena za 1t pro veřejnost' },
    actions: { name: 'Akce', columnFormatterType: ColumnFormatterTypes.actionsObject, align: ColumnAlign.center, headerStyle: { width: '80px' } }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    isActive: x => x.isActive,
    name: x => `${x.material.name}`,
    priceKc: x => moneyToLocaleString(x.priceKc),
    priceForPublicKc: x => (x.priceForPublicKc ? moneyToLocaleString(x.priceForPublicKc) : ''),
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deletePriceList',
        icon: 'fe-x',
        title: 'Odstranit ceník'
      })
      return ret
    }
  }
}
