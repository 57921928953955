import PageCard from '../../components/Cards/pageCard'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import React, { useContext, useEffect, useRef } from 'react'
import { useOrderDetailQuery } from './queries/orderDetailQuery'
import { getParamFromUrl } from '../../services/functions/getParamFromUrl'
import { WarningAlert } from '../../components/formComponents/alert'
import { HtmlDataList } from '../../components/HtmlDataList'
import { getContentData, getCustomerData, getOrderData, getPriceSeparation, getTechnicalData } from './services/prepareData'
import Timeline from './components/Timeline/Timeline'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import ImportDropZone from '../../components/DropZone/ImportDropZone'
import { useListOrderContentFilesQuery } from './queries/listfOfFilesQuery'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { fileListDataDefinition } from './services/fileListDataDefinition'
import BootstrapTable from 'react-bootstrap-table-next'
import LoadingContainer from '../../components/LoadingContainer'
import { useDeleteFileMutation } from './mutations/deleteFileMutation'
import { useChangeOwnerMutation } from './mutations/changeOwner'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { runOrderOperationEnum, useRunOrderOperationMutation } from './mutations/runOrderOperationMutation'
import { pageCardHeaderButton } from '../../components/Cards/pageCardHeaderButton'
import { useNavigate } from 'react-router-dom'
import { useReturnBackWithHighlight } from '../../services/pageHooks/highlightRowAfterReturn'
import ChangeOwnerDropdown from './components/ChangeOwnerDropdown'
import ZpoMainComponent from './components/zpo/ZpoMainComponent'
import { useDownloadS3File } from '../../hooks/useGetDownloadUrl'
import { toast } from 'react-toastify'
import WasteFinals from './components/WasteFinals/WasteFinals'
import axios from 'axios'
import { LoginContext } from '../../services/loginAndToken/useLogin'
import EmailHistory from './components/EmailHistory/EmailHistory'
import AccountingOptionsComponent from './components/accounting-options'
import OrderMap from './components/OrderMap/OrderMap'
import { serviceTypeTranslation } from '../../services/types/service-type'
import { useRecheckGPPaymentStatus } from './mutations/recheck-gp-payment-status'
import UserAvatar from 'react-user-avatar'
import { avatarColor } from '../../components/appComponents/avatar-color'
import AccountingDocuments from './components/AccountingDocuments/AccountingDocuments'
import OrderOperations from './components/order-operations/OrderOperations'
import { useForceFioTransactionDownloadMutation } from '../Accounting/transaction-pairing/mutations/force-fio-transaction-download.mutation'
import { useCheckAdvanceAccountStatusQuery } from './queries/check-advance-account-status.query'
import { usePayOrderFromAdvanceAccountMutation } from './mutations/pay-order-from-advance-account.mutation'
import OrderLockInfo from '../../components/OrderLockInfo/OrderLockInfo'
import OrderComments from '../../components/order-comments/OrderComments'
import OrderChangeLog from './components/OrderChangeLog/OrderChangeLog'

export default function OrderDetail() {
  const { downloadS3FileHandler, s3FileLoading } = useDownloadS3File()
  const navigate = useNavigate()
  const currentLogin = useContext(LoginContext)
  const { showModal, hideModal } = useContext(ModalContext)
  const [runOrderOperationMut, runOrderOperationQR] = useRunOrderOperationMutation()
  const orderNumber = useRef(getParamFromUrl('orderNumber')) ?? ''
  const orderDetailQR = useOrderDetailQuery()
  const orderContent = orderDetailQR.data && orderDetailQR.data.orderDetail ? orderDetailQR.data.orderDetail : undefined
  const advanceAccountStatusQR = useCheckAdvanceAccountStatusQuery(orderContent?.order.id, orderContent?.order.paymentType)
  const [payOrderFromAdvanceAccountMut, payOrderFromAdvanceAccountQR] = usePayOrderFromAdvanceAccountMutation()
  const [recheckGPPaymentStatus, recheckGPPaymentStatusQR] = useRecheckGPPaymentStatus(orderContent?.id)
  const [forceFioTransactionDownloadMut, forceFioTransactionDownloadQR] = useForceFioTransactionDownloadMutation()
  const isDraft = orderContent?.orderContentState.sysName === 'Draft'

  useEffect(() => {
    if (orderContent) orderNumber.current = orderContent.order.orderNumber.toString()
  }, [orderContent])

  const { returnBack } = useReturnBackWithHighlight()
  const [deleteFileMutation, deleteFileQR] = useDeleteFileMutation(() => orderContent?.id ?? 0)
  const [changeOwnerMutation, changeOwnerQR] = useChangeOwnerMutation()
  const orderContentFilesQR = useListOrderContentFilesQuery(orderContent?.id)
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper(fileListDataDefinition)
  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick,
    actionsReference: {
      deleteFile: deleteFileHandler
    }
  })
  let fileListData: any[] = []
  if (orderContentFilesQR.data && orderContentFilesQR.data.listOrderContentFiles)
    fileListData = mapDataArrayToTable(orderContentFilesQR.data.listOrderContentFiles)

  const adminOwnerName = orderContent?.order.adminOwner ? `${orderContent?.order.adminOwner.firstname} ${orderContent?.order.adminOwner.lastname}` : undefined

  return (
    <PageCard
      showLoader={isAnyQueryLoading(
        orderDetailQR,
        deleteFileQR,
        runOrderOperationQR,
        changeOwnerQR,
        recheckGPPaymentStatusQR,
        forceFioTransactionDownloadQR,
        payOrderFromAdvanceAccountQR
      )}
      documentTitle={`Objednávka ${orderNumber.current}`}
      headerTitle={
        <>
          {adminOwnerName && <UserAvatar size={32} name={adminOwnerName} colors={avatarColor(adminOwnerName)} />}
          <span className={'ml-3 d-inline-block'}>{'Detail objednávky číslo ' + (orderNumber.current ?? '')}</span>
        </>
      }
      cardOptions={[
        pageCardHeaderButton('Zpět', returnBack, 'back', '', 'btn-green'),
        pageCardHeaderButton('Převzít objednávku', assignOrder, 'assignOrder', '', 'btn-warning'),
        pageCardHeaderButton('Upravit objednávku', () => navigate(`/edit-order?orderNumber=${orderNumber.current}`), 'EditOrder')
      ]}
    >
      {!orderNumber.current && <WarningAlert message={'Nebyla vybrána zakázka'} />}
      {orderContent && (
        <>
          {isDraft && (
            <div className="row">
              <div className="col-md-12">
                <WarningAlert message={'Tato objednávka je pouze DRAFT'} />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-5">
              <HtmlDataList
                label={serviceTypeTranslation[orderContent.serviceType ?? '']}
                data={getOrderData(orderContent, refreshGPStatus, forceFioTransactionDownload, advanceAccountStatusQR, payOrderFromAdvanceAccountHandler)}
              />
            </div>
            <div className="col-md-4">
              <OrderMap orderContent={orderContent} />
              <OrderOperations
                orderContentId={orderContent.id}
                disabled={orderContent.orderContentState.sysName !== 'WaitingForPayment'}
                approvedAt={orderContent.approvedAt}
                approvedBy={`${orderContent.approvedBy?.firstname ?? ''} ${orderContent.approvedBy?.lastname ?? ''}`}
              />
            </div>
            <div className="col-md-3">
              <>
                {!isDraft && (
                  <>
                    <button type="button" className="btn btn-purple w-100 m-2" onClick={changeOwnerHandler}>
                      Změnit vlastníka objednávky
                    </button>
                    <button type="button" className="btn btn-azure w-100 m-2" onClick={sendOrderAcceptedEmailHandler}>
                      Odeslat ZÁKAZNÍKOVI email o přijetí objednávky
                    </button>
                    <button type="button" className="btn btn-cyan w-100 m-2" onClick={sendSupplierFullOrderInfoHandler}>
                      Odeslat DODAVATELI email shrnutí objednávky
                    </button>
                    <button type="button" className="btn btn-lime w-100 m-2" onClick={showAccountingComponentHandler}>
                      Finanční operace
                    </button>
                  </>
                )}
                <HtmlDataList label={'Technické info'} data={getTechnicalData(orderContent)} className={'p-3'} />
                <button type="button" className="btn btn-sm btn-blue w-100 mb-3" onClick={() => showOrderChangeLogHandler()}>
                  Historie změn
                </button>
                <OrderLockInfo orderId={orderContent.order.id} toastNotifyAboutLock />
              </>
            </div>
          </div>
          <div className="row">
            <h1>Zakázka</h1>
          </div>
          <div className="row">
            <div className="col-md-4">
              <fieldset className="form-fieldset">
                <HtmlDataList label="Detailní infomace k zakázce" data={getContentData(orderContent)} />
              </fieldset>
              {orderContent.serviceType === 'WASTE_COLLECTION' && !isDraft && (
                <>
                  <ZpoMainComponent orderContentId={orderContent.id} zpoNote={orderContent.supplier?.zpoNote} />
                  <WasteFinals orderContentId={orderContent.id} supplierIcz={orderContent.supplier?.iczNumber} />
                </>
              )}
            </div>
            <div className="col-md-4">
              <fieldset className="form-fieldset">
                <HtmlDataList label="Zákazník" data={getCustomerData(orderContent)} />
              </fieldset>
              <EmailHistory orderContentId={orderContent.id} />
            </div>
            <div className="col-md-4">
              <fieldset className="form-fieldset">
                <HtmlDataList label="Rozpad ceny" data={getPriceSeparation(orderContent.orderContentPrices)} />
              </fieldset>
              <AccountingDocuments orderId={orderContent.order.id} />
              <fieldset className="form-fieldset">
                <header>
                  <h2>Historie</h2>
                </header>
                <Timeline orderContentId={orderContent.id} />
              </fieldset>
              <fieldset className="form-fieldset">
                <header>
                  <h2>Komentáře</h2>
                </header>
                <OrderComments orderId={orderContent.order.id} />
              </fieldset>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <ImportDropZone processImport={processImport} />
            </div>
            <div className="col-md-6">
              <LoadingContainer showLoader={s3FileLoading}>
                <BootstrapTable
                  bootstrap4
                  hover
                  striped
                  condensed
                  classes="table-responsive-lg digiTable"
                  noDataIndication={() => <>Zakázka u sebe nemá žádné soubory</>}
                  keyField="id"
                  data={fileListData}
                  columns={columnDefinition}
                />
              </LoadingContainer>
            </div>
          </div>
          <div className="card-footer ">
            <div className="justify-content-center d-flex">
              <button type="button" onClick={() => returnBack()} className="btn btn-primary ms-auto btn-lg">
                Zpět
              </button>
            </div>
          </div>
        </>
      )}
    </PageCard>
  )

  function refreshGPStatus() {
    const promise = recheckGPPaymentStatus({
      variables: {
        orderId: orderContent?.id
      }
    })
    processPageOperation({
      promise,
      successMessage: 'Stav platby byl aktualizován',
      successAction: orderDetailQR.refetch
    })
  }

  function forceFioTransactionDownload() {
    const promise = forceFioTransactionDownloadMut()
    processPageOperation({
      promise,
      successMessage: 'Platby z banky byly načteny',
      successAction: orderDetailQR.refetch
    })
  }

  function handleTableClick(e: Event, column: any, columnIndex: number, row: any) {
    if (columnIndex === 0) downloadS3FileHandler(row.key)
  }

  async function processImport(acceptedFiles: File[]) {
    if (acceptedFiles.length !== 1) {
      toast.error('Soubor může být jen jeden o velikosti max 10MB.')
      return
    }
    const endpoint = `${process.env.REACT_APP_CONNECTION_PROTOCOL ?? 'https'}://${process.env.REACT_APP_BACKEND_HOSTNAME}/api/admin/upload`
    const formData = new FormData()
    formData.append('file', acceptedFiles[0])
    formData.append('orderContentId', `${orderContent?.id}`)
    const response = await axios.post(endpoint, formData, {
      headers: {
        Authorization: `Bearer ${currentLogin?.at}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    if (response.status === 200) {
      toast.success('Soubor byl nahrán')
      await orderContentFilesQR.refetch()
    } else {
      toast.error('Nahrání souboru se nezdařilo')
    }
  }

  function showAccountingComponentHandler() {
    showModal({
      hideFooter: true,
      title: 'Finanční operace',
      modalContent: <AccountingOptionsComponent onFinish={hideModalAndRefetch()} />
    })
  }

  function hideModalAndRefetch() {
    return () => {
      orderDetailQR.refetch().then(() => hideModal())
    }
  }

  function sendOrderAcceptedEmailHandler() {
    if (!orderContent) return
    const promise = runOrderOperationMut({
      variables: { orderId: orderContent.order.id, operation: runOrderOperationEnum.SEND_ORDER_ACCEPTED_EMAIL_TO_CUSTOMER }
    })
    processPageOperation({
      promise,
      successMessage: 'Email byl zákazníkovi odeslán'
    })
  }

  function sendSupplierFullOrderInfoHandler() {
    if (!orderContent) return
    const promise = runOrderOperationMut({
      variables: { orderId: orderContent.order.id, operation: runOrderOperationEnum.SEND_SUPPLIER_FULL_ORDER_INFO_EMAIL }
    })
    processPageOperation({
      promise,
      successMessage: 'Email byl dodavateli odeslán'
    })
  }

  function deleteFileHandler(row: any) {
    if (window.confirm('Opravdu nevratně odstranit soubor ' + row.filename)) {
      const promise = deleteFileMutation({
        variables: {
          key: row.key,
          orderContentId: orderContent?.id
        }
      })
      processPageOperation({
        promise: promise,
        successMessage: `Soubor ${row.filename} byl smazán`
      })
    }
  }

  function assignOrder() {
    if (window.confirm('Chcete se stát vlastníkem objednávky?')) {
      const promise = changeOwnerMutation({
        variables: {
          orderId: orderContent?.order?.id
        }
      })
      processPageOperation({
        promise: promise,
        successMessage: `Jste vlastníkem objednávky`,
        successAction: async result => await orderDetailQR.refetch()
      })
    }
  }
  function payOrderFromAdvanceAccountHandler() {
    if (window.confirm('Chcete zaplatit objednávku ze zálohového účtu?')) {
      const promise = payOrderFromAdvanceAccountMut({
        variables: {
          orderId: orderContent?.order?.id
        }
      })
      processPageOperation({
        promise: promise,
        successMessage: `Objednávka byla zaúčtována ze zálohového účtu`
      })
    }
  }

  function changeOwnerHandler() {
    showModal({
      hideFooter: true,
      title: 'Změnit vlastníka',
      modalContent: (
        <ChangeOwnerDropdown
          exitHandler={async () => {
            hideModal()
            await orderDetailQR.refetch()
          }}
          orderData={orderContent}
        />
      )
    })
  }
  function showOrderChangeLogHandler() {
    showModal({
      hideFooter: false,
      title: 'Historie změn',
      modalContent: <OrderChangeLog orderId={orderContent?.order.id} />
    })
  }
}
