import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { SupplierTruckEntity } from '../../../graphql/aminTypes'
import { moneyToLocaleString } from '../../../services/dataToString/decimalToLocaleString'

export const supplierServiceLoadTransportDataDefinition: TableDefinitionTypes<SupplierTruckEntity> = {
  columnDefinition: {
    id: { hidden: true },
    isActive: {
      name: 'Aktivní',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      headerStyle: { width: '80px' }
    },
    name: { name: 'Název' },
    truckType: { name: 'Typ vozidla' },
    capacityTonne: { name: 'Doložnost [t]' },
    pricePerKmKc: { name: 'Cena za km' },
    minimumPriceOfTransport: { name: 'Minimální cena za dopravu' },
    hasHydraulicArm: { name: 'Hydraulická tuka', align: ColumnAlign.center, columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter },
    actions: { name: 'Akce', columnFormatterType: ColumnFormatterTypes.actionsObject, align: ColumnAlign.center, headerStyle: { width: '80px' } }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    isActive: x => x.isActive,
    name: x => x.name,
    truckType: x => x.truckType,
    capacityTonne: x => x.capacityTonne,
    pricePerKmKc: x => moneyToLocaleString(x.pricePerKmKc),
    minimumPriceOfTransport: x => moneyToLocaleString(x.minimumPriceOfTransport),
    hasHydraulicArm: x => x.hasHydraulicArm,
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteTruck',
        icon: 'fe-x',
        title: 'Odstranit auto'
      })
      return ret
    }
  }
}
