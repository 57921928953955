import Select, { SingleValue } from 'react-select'
import React, { useEffect, useState } from 'react'
import { useSuggestIczQuery } from './suggest-icz.query'
import { useFormContext } from 'react-hook-form'
import { IReactSelectItem } from '../../../services/types/ReactSelect'
import { FormComponentBaseInput } from '../../formComponents/components/FormComponentBaseInput'
import FormField, { inputType } from '../../formComponents/FormField'

export default function WasteTerminalAutocomplete({ isForSupplier }: { isForSupplier: boolean }) {
  const formMethods = useFormContext()
  const [selectedOption, setSelectedOption] = useState<SingleValue<IReactSelectItem<string>> | undefined>(undefined)

  const [iczOptions, setIczOptions] = useState<IReactSelectItem<string>[]>([])
  const suggestIczQuery = useSuggestIczQuery(isForSupplier)

  const handleInputChange = (inputValue: string) => {
    if (inputValue.length < 1) return
    suggestIczQuery.refetch({ icz: inputValue }).then(result => {
      setIczOptions(result.data.suggestIcz.map(x => ({ value: x.icz, label: x.icz, data: x.name })))
    })
  }
  formMethods.register('icz')
  useEffect(() => {
    if (formMethods.watch('icz') && !selectedOption) {
      setSelectedOption({ value: formMethods.watch('icz'), label: formMethods.watch('icz') })
    }
  }, [formMethods.watch('icz')])

  function setValue(value: SingleValue<IReactSelectItem<string>>) {
    setSelectedOption(value)
    formMethods.setValue('icz', value?.value ?? '')
    formMethods.setValue('terminalName', iczOptions.find(x => x.value === value?.value)?.data ?? '')
  }

  return (
    <>
      <FormComponentBaseInput name={'icz'} label={'IČZ'} registerOptions={{ required: true }}>
        <Select
          value={selectedOption}
          onChange={setValue}
          onInputChange={handleInputChange}
          options={iczOptions}
          isSearchable={true}
          isClearable={true}
          menuPlacement="auto"
          menuPosition="fixed"
          className={`react-select`}
          classNamePrefix="reactSelect"
        />
      </FormComponentBaseInput>
      <FormField type={inputType.text} name={'terminalName'} label={'Jméno koncovky'} registerOptions={{ required: false }} maxLength={300} />
    </>
  )
}
