import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import ToggleField from '../../../../components/formComponents/ToggleField'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { useSupplierSupplierListQuery } from '../../queries/supplier-supplier-list.query'
import { useCreateSupplierSupplierMutation } from './mutations/create-supplier-supplier.mutation'
import { useUpdateSupplierSupplierMutation } from './mutations/edit-supplier-supplier.mutation'
import { SupplierSupplierEntity, SupplierSupplierInputArgs } from '../../../../graphql/aminTypes'
import AddressPicker from '../../../../components/formComponents/AddressPicker/AddressPicker'
import { useFindByCompanyIdQuery } from './queries/find-by-company-id.query'
import { nullFieldsWithEmptyStrings } from '../../../../services/formsServices/null-fields-with-empty-strings'
import CompanyNamePicker from '../../../../components/formComponents/CompanyNamePicker/CompanyNamePicker'
import { SubjectInfoType } from '../../../../graphql/publicTypes'
import { setupFields } from '../../../../services/formsServices/setupFields'

export default function SupplierSupplierEditor({ supplierSupplierId, exitHandler }: { supplierSupplierId?: string | undefined; exitHandler: () => void }) {
  const formMethods = useForm<SupplierSupplierInputArgs>({ defaultValues: { isActive: true } })
  const supplierSupplierListQR = useSupplierSupplierListQuery({ id: supplierSupplierId }, { skip: supplierSupplierId === undefined })
  const [createSupplierMut, createSupplierQR] = useCreateSupplierSupplierMutation()
  const [updateSupplierMut, updateSupplierQR] = useUpdateSupplierSupplierMutation()
  const findByCompanyIdQR = useFindByCompanyIdQuery()

  useEffect(() => {
    if (supplierSupplierListQR.data?.supplierSupplierList.items.length === 1 && supplierSupplierListQR.data?.supplierSupplierList.items[0]) {
      const data = supplierSupplierListQR.data?.supplierSupplierList.items[0]
      setupFields(data, { formMethods, includeKeys: ['isActive', 'companyIdentification', 'name', 'companyTaxId', 'website', 'note'] })
      setupFields(data.invoicingAddress, { formMethods, includeKeys: ['street', 'streetNumber', 'city', 'zip'], prefix: 'invoicingAddress' })
      setupFields(data.businessContact, { formMethods, includeKeys: ['name', 'email', 'phone'], prefix: 'businessContact' })
      setupFields(data.invoicingContact, { formMethods, includeKeys: ['name', 'email', 'phone'], prefix: 'invoicingContact' })
    }
  }, [formMethods.setValue, supplierSupplierListQR.data])

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(supplierSupplierListQR, createSupplierQR, updateSupplierQR, findByCompanyIdQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <div className="row mb-2">
            <div className="col-md-12">
              <ToggleField name={'isActive'} label={'Aktivní'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <CompanyNamePicker
                name={'companyNameSearch'}
                label={'Vyhledat firmu podle názvu'}
                setupCompanyDataFromSelection={setupCompanyDataFromSelection}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <FormField type={inputType.text} name={'companyIdentification'} label={'IČO'} required registerOptions={{ required: true }} />
            </div>
            <div className="col-md-2 pt-3">
              <button type="button" className="btn btn-secondary" onClick={loadSupplierFromIco}>
                Načíst z ARES
              </button>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <FormField type={inputType.text} name={'name'} label={'Jméno'} required registerOptions={{ required: true }} />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.text} name={'companyTaxId'} label={'DIČ'} />
            </div>
          </div>
          <div className="row mb-2">
            <AddressPicker name={'invoicingAddress'} label={'Fakturační adresa'} />
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <FormField type={inputType.text} name={'businessContact.name'} label={'Kontaktní jméno'} />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.email} name={'businessContact.email'} label={'Kontaktní Email'} />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.phone} name={'businessContact.phone'} label={'Kontaktní telefon'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <FormField type={inputType.text} name={'invoicingContact.name'} label={'Fakturační jméno'} />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.email} name={'invoicingContact.email'} label={'Fakturační Email'} />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.phone} name={'invoicingContact.phone'} label={'Fakturační telefon'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-12">
              <FormField type={inputType.text} name={'note'} label={'Poznámka'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-12">
              <FormField type={inputType.text} name={'website'} label={'Website'} />
            </div>
          </div>

          <div className="form-footer">
            <button type="submit" className="btn btn-primary w-100">
              {supplierSupplierId ? 'Uložit' : 'Přidat'}
            </button>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function loadSupplierFromIco() {
    const ico = formMethods.watch('companyIdentification')
    if (!ico) return
    findByCompanyIdQR.refetch({ companyId: ico }).then(resp => {
      if (resp.data?.findByCompanyId) {
        const result = resp.data.findByCompanyId
        formMethods.setValue('name', result.ares.name)
        formMethods.setValue('invoicingAddress', {
          street: result.ares.street,
          streetNumber: result.ares.streetNumber ?? '',
          city: result.ares.city ?? '',
          zip: result.ares.zip ?? ''
        })
        formMethods.setValue('companyTaxId', result.ares.dic ?? '')
      }
    })
  }
  function handleFormSubmit(data: SupplierSupplierInputArgs) {
    data.businessContact = data.businessContact ? nullFieldsWithEmptyStrings(data.businessContact) : undefined
    data.invoicingContact = data.invoicingContact ? nullFieldsWithEmptyStrings(data.invoicingContact) : undefined
    const transformedData = nullFieldsWithEmptyStrings(data)
    let resultPromise = supplierSupplierId
      ? updateSupplierMut({ variables: { id: supplierSupplierId, data: transformedData } })
      : createSupplierMut({ variables: { data: transformedData } })

    processPageOperation({
      promise: resultPromise,
      successAction: exitHandler,
      successMessage: 'Dodavatel byl úspěšně uložen'
    })
    return false
  }
  function setupCompanyDataFromSelection(company: SubjectInfoType) {
    if (!company) return
    formMethods.setValue('name', company.name)
    formMethods.setValue('companyTaxId', company.dic)
    formMethods.setValue('companyIdentification', company.ico)
    formMethods.setValue('invoicingAddress', {
      street: company.street,
      streetNumber: company.streetNumber ?? '',
      city: company.city ?? '',
      zip: company.zip ?? ''
    })
  }
}
