import { IReactSelectItem } from '../../../../../services/types/ReactSelect'
import { AccountingDocument, OrderContentsResult, PaymentType } from '../../../../../graphql/aminTypes'

export function accountingOperations(
  orderContent: OrderContentsResult,
  accountingDocuments?: AccountingDocument[],
  showAccountingOptions?: boolean
): IReactSelectItem<any>[] {
  const order = orderContent.order
  const invoice = accountingDocuments?.find(doc => doc.type === 'INVOICE')
  const proforma = accountingDocuments?.find(doc => doc.type === 'PROFORMA_INVOICE')
  const receipt = accountingDocuments?.find(doc => doc.type === 'INCOME_RECEIPT')

  const options: IReactSelectItem<any>[] = []

  options.push({
    label: 'Vytvořit novou GoPay platbu',
    value: 'CreateNewGoPayPayment',
    isDisabled: order.paymentType !== 'PAYMENT_GW' || order.paymentStatus === 'PAID'
  })
  if (showAccountingOptions)
    options.push({
      label: 'Vrátit platbu',
      value: 'Refund',
      isDisabled: !['MaterialDelivered', 'ContainerTakenBack', 'OrderFinishedBySupplier', 'OrderChargedByAdmin', 'Cancelled'].includes(
        orderContent.orderContentState.sysName
      )
    })
  options.push({ label: 'Objednávka zaplacena', value: 'MarkOrderAsPaid', isDisabled: order.paymentStatus === 'PAID' })
  options.push({
    label: 'Objednávka zaplacena a provést související akce',
    value: 'ProcessOrderPayment',
    isDisabled: order.paymentStatus === 'PAID' || order.paymentType === PaymentType.AdvancePayment
  })
  options.push({
    label: 'Změnit platební metodu',
    value: 'ChangePaymentMethod',
    isDisabled: order.paymentStatus === 'PAID' || !!invoice
  })
  if (showAccountingOptions)
    options.push({
      label: 'Odpojit od nadřazeného dokumentu ve Vyfakturuj',
      value: 'UnlinkInvoice',
      isDisabled: !(invoice && proforma)
    })
  if (showAccountingOptions)
    options.push({
      label: 'Vytvořit novou fakturu ve Vyfakturuj',
      value: 'CreateNewInvoice',
      isDisabled: !!invoice || !(order.paymentType === PaymentType.Invoice || order.paymentType === PaymentType.InvoiceAdvance)
    })
  options.push({ label: 'Vytvořit fakturu pro dofakturaci', value: 'CreateAdditionalInvoice', isDisabled: !invoice })
  if (showAccountingOptions)
    options.push({
      label: 'Vytvořit příjmový pokladní doklad ve Vyfakturuj',
      value: 'CreateIncomeReceipt',
      isDisabled: !!receipt || order.paymentType !== PaymentType.Cod
    })
  if (showAccountingOptions)
    options.push({
      label: 'Připojit k objednávce dokument ručně vytvořený ve Vyfakturuj',
      value: 'LinkVyfakturujInvoice'
    })
  options.push({
    label: 'Odeslat platební informace o platbě převodem zákazníkovi',
    value: 'SendPaymentInstructionEmail',
    isDisabled: !['PAYMENT_GW', 'BANK_TRANSFER', 'INVOICE_ADVANCE'].includes(orderContent.order.paymentType ?? '') || order.paymentStatus === 'PAID'
  })
  options.push({
    label: 'Odeslat notifikaci o nezaplacení objednávky',
    value: 'OrderNotPaidEmailToClient',
    isDisabled:
      order.paymentStatus !== 'UNPAID' ||
      orderContent.orderContentState.sysName === 'Cancelled' ||
      !['PROFORMA_INVOICE', 'PAYMENT_GW', 'BANK_TRANSFER', 'INVOICE_ADVANCE'].includes(orderContent.order.paymentType ?? '')
  })
  if (showAccountingOptions) {
    options.push({
      label: 'Změnit stav zaplacení',
      value: 'ChangePaymentStatus',
      isDisabled: order.paymentType === PaymentType.AdvancePayment
    })
    options.push({
      label: 'Zrušit uhrazení objednávky na zálohu',
      value: 'CancelPaymentAdvanceOrderPayment',
      isDisabled: order.paymentType !== PaymentType.AdvancePayment || order.paymentStatus !== 'PAID'
    })
  }
  return options
}
