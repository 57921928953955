import BootstrapTable from 'react-bootstrap-table-next'
// @ts-ignore
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { useNavigate } from 'react-router-dom'
import React, { useContext } from 'react'
import { ModalContext } from '../../../components/Modal/ModalProvider'
import usePagingFromURLQuery from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { AccountingGroupInvoice } from '../../../graphql/aminTypes'
import useBootstrapTableDataMapper from '../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { groupInvoicesDataDefinition } from './services/group-invoices-data-definition'
import { useAccountingGroupInvoicesQuery } from './queries/accounting-group-invoices.query'
import useBootstrapTablePagingAndFiltration from '../../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
import { usePrepareFilterOptions } from '../../OrderList/services/usePrepareFilterOptions'
import { isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import LoadingContainer from '../../../components/LoadingContainer'
import { BootstrapTablePagingWrapper } from '../../../components/bootstrapTable/BootstrapTablePagingWrapper'
import { useUpdateGroupInvoiceMutation } from './mutations/update-group-invoice.mutation'
import cellEditFactory from 'react-bootstrap-table2-editor'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import dayjs from 'dayjs'
import { useDownloadExport } from '../../../hooks/useDownloadExport'
import { useSyncAccountingDocumentHandler } from '../../../services/mutations/sync-accounting-document-handler/sync-accounting-document-handler'

export default function GroupInvoices() {
  const download = useDownloadExport()
  const [syncAccountingDocumentHandler, syncAccountingDocumentQR] = useSyncAccountingDocumentHandler(['accountingGroupInvoices'])

  const [updateGroupInvoiceMut, updateGroupInvoiceQR] = useUpdateGroupInvoiceMutation()
  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation, defaultSort } =
    useBootstrapTableDataMapper<AccountingGroupInvoice>(groupInvoicesDataDefinition)

  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery(
    ['createdDateFrom', 'createdDateTo', 'customer', 'emailSentAt', 'externalReference', 'variableSymbol'],
    nameForGqlTranslation,
    { defaultSort, defaultPageSize: 50 }
  )
  const groupInvoicesQR = useAccountingGroupInvoicesQuery(gqlQueryParams)
  const { bootstrapTablePaginationSetup, handleTableChange } = useBootstrapTablePagingAndFiltration(
    pageConfiguration,
    undefined,
    undefined,
    groupInvoicesQR.data?.accountingGroupInvoices.total
  )

  const { filterOptions } = usePrepareFilterOptions()
  filterOptions.addFilterOption('emailSentAt', [
    { value: true, label: 'Odesláno' },
    { value: false, label: 'Neodesláno' }
  ])
  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick,
    filterDefaultValues: pageConfiguration.filterBy,
    currentSort: pageConfiguration.sort,
    handleTableChange: handleTableChange,
    filterOptions4CustomSelectCreator: filterOptions,
    actionsReference: {
      markAsEmailSent: markAsEmailSentHandler,
      markAsPaid: markAsPaidHandler,
      downloadCsv: downloadCSVHandler,
      SyncAccountingDocument: syncAccountingDocumentHandler
    }
  })

  let groupInvoices: any[] = []
  if (groupInvoicesQR.data && groupInvoicesQR.data.accountingGroupInvoices.data)
    groupInvoices = mapDataArrayToTable(groupInvoicesQR.data.accountingGroupInvoices.data)

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(groupInvoicesQR, updateGroupInvoiceQR, syncAccountingDocumentQR)}>
      <PaginationProvider pagination={paginationFactory(bootstrapTablePaginationSetup)}>
        {({ paginationProps, paginationTableProps }: { paginationProps: any; paginationTableProps: any }) => (
          <div className="table-responsive-xl">
            <BootstrapTablePagingWrapper paginationProps={paginationProps} paginationTableProps={paginationTableProps}>
              <BootstrapTable
                bootstrap4
                striped
                hover
                condensed
                remote
                classes="table-responsive-lg digiTable"
                noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné objednávky"</>}
                keyField="id"
                data={groupInvoices}
                columns={columnDefinition}
                filter={filterFactory()}
                filterPosition={'inline'}
                sort={pageConfiguration.sort}
                onTableChange={handleTableChange}
                {...paginationTableProps}
                cellEdit={cellEditFactory({
                  mode: 'click',
                  blurToSave: true,
                  autoSelectText: true,
                  beforeSaveCell: updateGroupInvoiceHandler
                })}
              />
            </BootstrapTablePagingWrapper>
          </div>
        )}
      </PaginationProvider>
    </LoadingContainer>
  )

  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    const rowData = groupInvoicesQR.data?.accountingGroupInvoices[row['index']]
    if (!rowData) return
  }

  function markAsPaidHandler(row: any) {
    updateGroupInvoice(row.id, JSON.stringify({ paidOn: dayjs().format('YYYY-MM-DD') }))
  }
  function markAsEmailSentHandler(row: any) {
    updateGroupInvoice(row.id, JSON.stringify({ emailSentAt: dayjs().toISOString() }))
  }
  function updateGroupInvoice(id: string, updates: string) {
    const promise = updateGroupInvoiceMut({ variables: { id, updates } })
    processPageOperation({
      promise: promise,
      successAction: async result => {
        await groupInvoicesQR.refetch()
      },
      successMessage: 'Změny byly uloženy'
    })
  }

  function downloadCSVHandler(row: any) {
    download(`/api/export-group-invoice-orders?groupInvoiceId=${row.id}`)
  }

  async function updateGroupInvoiceHandler(oldValue, newValue, row, column, doneCallback) {
    if (!row.id || oldValue === newValue) return
    const promise = updateGroupInvoiceMut({ variables: { id: row.id, updates: JSON.stringify({ [column.dataField]: newValue }) } })
    processPageOperation({
      promise: promise,
      successAction: async result => {
        await groupInvoicesQR.refetch()
      },
      successMessage: 'Změny byly uloženy'
    })
  }
}
