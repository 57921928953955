import { SelectedSupplierModalContext } from '../SelectedSupplierModal/SelectedSupplierModal'
import React, { useContext } from 'react'
import PageCard from '../../components/Cards/pageCard'
import SupplierServiceEnabler from '../common/components/SupplierServiceEnabler/SupplierServiceEnabler'
import { ModalContext } from '../../components/Modal/ModalProvider'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { SupplierMaterialPriceListEntity } from '../../graphql/aminTypes'
import { supplierMaterialPriceListDataDefinition } from './services/supplier-material-price-list-data-definition'
import BootstrapTable from 'react-bootstrap-table-next'
import { SettingsNode } from './components/SettingsNode'
import usePagingFromURLQuery from '../../services/bootstrapTable/usePagingFromURLQuery'
import useBootstrapTablePagingAndFiltration from '../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
import filterFactory from 'react-bootstrap-table2-filter'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import { useSupplierMaterialPriceListsQuery } from './queries/supplier-material-price-lists.query'
import SupplierEditMaterialPrice from './components/SupplierEditMaterialPrice/SupplierEditMaterialPrice'
import { useDeleteSupplierMaterialPriceListMutation } from './mutations/delete-supplier-material-price-list.mutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'

const serviceCategory = 'MATERIAL_SELLER'
export default function SupplierServiceMaterialPriceList() {
  const { state } = useContext(SelectedSupplierModalContext)
  const { showModal, hideModal } = useContext(ModalContext)
  const currentServiceSetting = state.selectedBranch?.branchServices?.find(x => x.serviceCategory === serviceCategory)
  const [deleteMaterialPriceListMut, deleteMaterialPriceListQR] = useDeleteSupplierMaterialPriceListMutation()
  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation } =
    useBootstrapTableDataMapper<SupplierMaterialPriceListEntity>(supplierMaterialPriceListDataDefinition)
  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery([], nameForGqlTranslation, { defaultPageSize: 100 })
  const materialPriceListQR = useSupplierMaterialPriceListsQuery(currentServiceSetting?.id)

  const { bootstrapTablePaginationSetup, handleTableChange } = useBootstrapTablePagingAndFiltration(pageConfiguration, undefined, true)

  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick,
    handleTableChange,
    filterDefaultValues: pageConfiguration.filterBy,
    actionsReference: {
      deletePriceList: deleteMaterialPriceListHandler
    }
  })
  const tableData = mapDataArrayToTable(materialPriceListQR.data?.supplierMaterialPriceLists)

  return (
    <PageCard showLoader={isAnyQueryLoading(materialPriceListQR, deleteMaterialPriceListQR)} headerTitle={'Prodej materiálu'}>
      <SupplierServiceEnabler currentServiceSetting={currentServiceSetting} serviceCategory={serviceCategory} settingsNode={<SettingsNode />} />
      {currentServiceSetting && (
        <>
          <button type="button" className="btn btn-primary btn-block mb-2" onClick={addPriceListHandler}>
            Přidat materiál
          </button>
          <BootstrapTable
            bootstrap4
            striped
            hover
            condensed
            filter={filterFactory()}
            onTableChange={handleTableChange}
            classes="table-responsive-lg digiTable"
            noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
            keyField="id"
            data={tableData ? tableData : []}
            columns={columnDefinition}
          />
        </>
      )}
    </PageCard>
  )

  function addPriceListHandler() {
    showModal({
      title: 'Přidat ceník',
      modalContent: (
        <SupplierEditMaterialPrice
          branchServiceId={currentServiceSetting?.id}
          onClose={hideModal}
          doNotShowMaterials={materialPriceListQR.data?.supplierMaterialPriceLists.map(x => x.productMaterialId) ?? []}
        />
      ),
      hideFooter: true
    })
  }

  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (columnIndex > 3) return
    const item = materialPriceListQR.data?.supplierMaterialPriceLists.find(x => x.id === row.id)
    showModal({
      title: 'Editovat ceník',
      modalContent: <SupplierEditMaterialPrice branchServiceId={currentServiceSetting?.id} onClose={hideModal} editItem={item} doNotShowMaterials={[]} />,
      hideFooter: true
    })
  }

  function deleteMaterialPriceListHandler(row: any) {
    const promise = deleteMaterialPriceListMut({ variables: { id: row.id } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Ceník byl smazán'
    })
  }
}
