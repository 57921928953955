import { gql, useQuery } from '@apollo/client'

export const findIczQuery = gql`
  query findIcz($companyId: String!, $street: String!) {
    findIcz(companyId: $companyId, street: $street)
  }
`

export function useFindIczQuery() {
  return useQuery<{ findIcz: string }>(findIczQuery, {
    fetchPolicy: 'network-only',
    skip: true
  })
}
