import React, { useEffect } from 'react'
import { isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import { FormProvider, useForm } from 'react-hook-form'
import AddressPicker from '../../../components/formComponents/AddressPicker/AddressPicker'
import ToggleField from '../../../components/formComponents/ToggleField'
import FormField, { inputType } from '../../../components/formComponents/FormField'
import { useUpdateCustomerMutation } from './mutations/udate-customer.mutation'
import { useReturnBackWithHighlight } from '../../../services/pageHooks/highlightRowAfterReturn'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import LoadingContainer from '../../../components/LoadingContainer'
import { OperationVariables, QueryResult } from '@apollo/client'
import { CustomerListPaginatedResponse } from '../../../graphql/aminTypes'
import SelectField from '../../../components/formComponents/SelectField'
import { countryIsoOptions } from '../../../services/values/country-iso.options'
import { CustomerFormData, customerFormFields } from './types/customer-form-data'
import { setupFields } from '../../../services/formsServices/setupFields'

export default function CustomerEditor({ customerQR }: { customerQR: QueryResult<{ customerList: CustomerListPaginatedResponse }, OperationVariables> }) {
  const formMethods = useForm<CustomerFormData>()
  const [updateCustomerMut, updateCustomerMR] = useUpdateCustomerMutation()
  const { returnBack } = useReturnBackWithHighlight()

  useEffect(() => {
    if (customerQR.data) {
      const address = {
        street: customerQR.data.customerList.items[0].street,
        streetNumber: customerQR.data.customerList.items[0].streetNumber,
        city: customerQR.data.customerList.items[0].city,
        zip: customerQR.data.customerList.items[0].zip
      }
      setupFields(customerQR.data.customerList.items[0], { formMethods, includeKeys: customerFormFields })
      formMethods.setValue('address', address)
    }
  }, [customerQR.data])

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(customerQR, updateCustomerMR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <fieldset className="form-fieldset bg-lime-lt">
            <div className="row mb-2">
              <div className="col-md-4">
                <FormField type={inputType.text} name={'companyName'} label={'Jméno firmy'} />
              </div>
              <div className="col-md-4">
                <FormField type={inputType.text} name={'companyIdentification'} label={'IČO'} />
              </div>
              <div className="col-md-4">
                <FormField type={inputType.text} name={'companyTaxId'} label={'DIČ'} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6">
                <FormField type={inputType.text} name={'firstname'} label={'Jméno'} />
              </div>
              <div className="col-md-6">
                <FormField type={inputType.text} name={'lastname'} label={'Příjmení'} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-4">
                <FormField type={inputType.text} name={'email'} label={'Email'} disabled />
              </div>
              <div className="col-md-4">
                <FormField type={inputType.text} name={'phone'} label={'Telefon'} />
              </div>
              <div className="col-md-4">
                <FormField type={inputType.text} name={'invoicingEmail'} label={'Fakturační email (pro ručně zasílané doklady přes obálku)'} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-4">
                <SelectField name={'countryIso'} label={'Stát'} required optionsData={countryIsoOptions} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <AddressPicker
                  label={'Fakturační adresa'}
                  name={'address'}
                  required
                  registerOptions={{ required: 'Poloha nebyla nalezena.' }}
                  className="bg-orange-lt"
                  countryIso={formMethods.watch('countryIso')}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-4">
                <FormField name={'b2bMarginCorrection'} label="Úprava marže pro B2B" type={inputType.number} isDecimal valueAsNumber />
              </div>
              <div className="col-md-4">
                <FormField name={'metrakMarginCorrection'} label="Úprava marže Metrák" type={inputType.number} isDecimal valueAsNumber />
              </div>
              <div className="col-md-4">
                <FormField name={'customerDiscount'} label="Zákaznická sleva" type={inputType.number} isDecimal valueAsNumber />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <FormField name={'notes'} label="Poznámky" type={inputType.text} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <ToggleField name={'limitEndCustomerEmails'} label="Koncovému zákazníkovi posílat jen 3 emaily" />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <ToggleField name={'createInvoices'} label="Vytvářet faktury pro objednávky" />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <ToggleField name={'isResellCompany'} label="Přeprodej (DEK)" />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <ToggleField name={'hasFixedPrice'} label="Fixní ceny u vlastních produktů" />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <ToggleField name={'advanceAccountsSetup.isUsingAdvanceAccounts'} label="Používat zálohové účty" />
                <div className="row mb-2 ml-2">
                  <div className="col-md-12">
                    <FormField
                      name={'advanceAccountsSetup.negativeBalanceAllowance'}
                      label="Povolit jít do mínusu (na každé akci zvlášť) až o"
                      type={inputType.number}
                      valueAsNumber
                    />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <div className="row mb-2">
            <div className="col-md-2 mt-2 offset-10">
              <button type="submit" className="btn btn-primary ms-auto btn-lg">
                {'Uložit změny'}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function handleFormSubmit(data: Record<string | number | symbol, any>) {
    const address = data.address
    delete data.address
    delete data.email
    delete address.lat
    delete address.lng

    const resultPromise = updateCustomerMut({
      variables: {
        data: { id: customerQR.data?.customerList.items[0].id, ...data, ...address, updatedAt: customerQR.data?.customerList.items[0].updatedAt ?? null }
      }
    })
    processPageOperation({
      promise: resultPromise,
      successAction: returnBack
    })
  }
}
