import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { SupplierSupplierListResponse } from '../../../graphql/aminTypes'

export const supplierSupplierListQuery = gql`
  query supplierSupplierList($id: ID, $name: String, $limit: Int, $offset: Int, $order: String, $serviceCategory: ServiceCategory) {
    supplierSupplierList(id: $id, name: $name, limit: $limit, offset: $offset, order: $order, serviceCategory: $serviceCategory) {
      total
      items {
        id
        isActive
        name
        companyIdentification
        companyTaxId
        businessContact {
          name
          phone
          email
        }
        invoicingContact {
          name
          phone
          email
        }
        website
        note
        branches {
          id
          setupIsFinal
          icz
          address {
            id
            city
          }
          branchServices {
            id
            serviceCategory
          }
        }
        invoicingAddress {
          id
          street
          streetNumber
          city
          zip
        }
      }
    }
  }
`

export function useSupplierSupplierListQuery(params: IGqlPagingQueryParams, opts?: { skip?: boolean }) {
  return useQuery<{ supplierSupplierList: SupplierSupplierListResponse }>(supplierSupplierListQuery, {
    fetchPolicy: 'network-only',
    variables: params,
    skip: opts?.skip
  })
}
