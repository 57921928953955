import { FormProvider, useForm } from 'react-hook-form'
import React, { useState } from 'react'
import AddressPicker from '../../components/formComponents/AddressPicker/AddressPicker'
import PageCard from '../../components/Cards/pageCard'
import { getServiceType, ServiceCategoryEnum, serviceCategoryTranslation } from '../../services/types/service-category.type'
import DistanceMap from './components/DistanceMap'
import { useGetAvailableServicesForLocalityQuery } from './queries/get-available-services-for-locality.query'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import dayjs from 'dayjs'
import DatePickerComp from '../../components/formComponents/datePickerComp'
import FormField, { inputType } from '../../components/formComponents/FormField'
import ServicesSelector from './components/ServicesSelector'
import WasteCollectionSolid from './components/waste-collection-solid/WasteCollectionSolid'
import { useCalculateCollectionServiceOptionsQuery } from './queries/calculate-collection-service-options.query'
import { PriceCalculatorResponse } from '../../graphql/aminTypes'
import CalculationResultVisualizer from './components/calculation-result-visualizer/CalculationResultVisualizer'
import { NetworkStatus } from '@apollo/client'
import { formatAddressToDisplay } from '../../services/dataToString/formatAddressToDisplay'
import classNames from 'classnames'

export default function Calculator() {
  const formMethods = useForm({
    defaultValues: { radiusKm: 20, calculationDate: dayjs().format('YYYY-MM-DD'), customerAddress: undefined, serviceCategory: undefined }
  })
  const address = formMethods.watch('customerAddress')
  const [calculationsResults, setCalculationsResults] = useState<PriceCalculatorResponse[] | undefined>(undefined)
  const [selectedResultIndex, setSelectedResultIndex] = useState<{ resultIndex: number; optionIndex: number } | undefined>(undefined)
  const [refetchIsRunnning, setRefetchIsRunning] = useState(false)
  const availableServicesQR = useGetAvailableServicesForLocalityQuery({
    customerLatLng: address ? { lat: parseFloat(address['lat']), lng: parseFloat(address['lng']) } : undefined,
    radiusKm: formMethods.watch('radiusKm'),
    calculationDate: dayjs(formMethods.watch('calculationDate')).format('YYYY-MM-DD')
  })
  const calculateCollectionServiceOptionsQR = useCalculateCollectionServiceOptionsQuery()

  const serviceCategory = formMethods.watch('serviceCategory')
  const serviceType = getServiceType(serviceCategory)
  return (
    <PageCard headerTitle="Výpočet cen za služby" showLoader={isAnyQueryLoading(calculateCollectionServiceOptionsQR, availableServicesQR) || refetchIsRunnning}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <div className="row mb-2">
            <div className="col-md-4">
              <FormField type={inputType.number} name={'radiusKm'} label={'Radius vzdušnou čarou'} valueAsNumber />
            </div>
            <div className="col-md-4">
              <DatePickerComp name={'calculationDate'} label={'Datum výpočtu (ovlivní platnost ceníků služeb)'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-8">
              {!address && (
                <AddressPicker name={'customerAddress'} required registerOptions={{ required: 'Poloha nebyla nalezena.' }} className="bg-orange-lt" />
              )}
              {address && (
                <fieldset className={classNames('form-fieldset', 'bg-orange-lt')}>
                  Máte vybranou adresu <strong>{formatAddressToDisplay(address)}</strong>{' '}
                  <button type="button" className={'btn-link'} onClick={() => formMethods.setValue('customerAddress', undefined)}>
                    Změnit
                  </button>
                </fieldset>
              )}
              <ServicesSelector servicesList={availableServicesQR.data?.getAvailableServicesForLocality?.availableServices} />

              {serviceCategory === ServiceCategoryEnum.WASTE_COLLECTION_SOLID && <WasteCollectionSolid />}
              {calculationsResults && serviceCategory && address && (
                <CalculationResultVisualizer calculationResults={calculationsResults} setSelectedResultIndex={setSelectedResultIndex} />
              )}
            </div>
            <div className="col-md-4 border-1 border-danger ">
              {address && (
                <DistanceMap
                  encodedGeometry={
                    selectedResultIndex && calculationsResults && calculationsResults[selectedResultIndex.resultIndex]
                      ? calculationsResults?.[selectedResultIndex.resultIndex].calculationOptions[selectedResultIndex.optionIndex].encodedGeometry
                      : undefined
                  }
                  customerPosition={address ? { lat: parseFloat(address['lat']), lng: parseFloat(address['lng']) } : undefined}
                  supplierPosition={
                    selectedResultIndex && calculationsResults && calculationsResults[selectedResultIndex.resultIndex]
                      ? calculationsResults[selectedResultIndex.resultIndex].supplierBranch.gps
                      : undefined
                  }
                  terminalPosition={
                    selectedResultIndex && calculationsResults && calculationsResults[selectedResultIndex.resultIndex]
                      ? calculationsResults?.[selectedResultIndex.resultIndex].calculationOptions[selectedResultIndex.optionIndex].terminalBranch?.gps
                      : undefined
                  }
                  availableBranches={availableServicesQR.data?.getAvailableServicesForLocality?.supplierBranches}
                />
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </PageCard>
  )

  function handleFormSubmit(data: any) {
    const params = {
      customerLatLng: { lat: parseFloat(data.customerAddress['lat']), lng: parseFloat(data.customerAddress['lng']) },
      radiusKm: data.radiusKm,
      calculationDate: data.calculationDate,
      allowNearestLargerContainer: data.allowNearestLargerContainer,
      routingStrategy: data.routingStrategy,
      containerId: data.productContainerId,
      productId: data.productWasteId,
      wasteCategory: 'SOLID_WASTE',
      containerRentLengthDays: data.containerRentLengthDays,
      customContainerCapacity: data.customContainerCapacity
    }
    setRefetchIsRunning(true)
    calculateCollectionServiceOptionsQR
      .refetch(params)
      .then(result => {
        setCalculationsResults(result.data?.calculateCollectionServiceOptions)
        if (result.data?.calculateCollectionServiceOptions.length > 0) {
          setSelectedResultIndex({ resultIndex: 0, optionIndex: 0 })
        }
        setRefetchIsRunning(false)
      })
      .catch(() => setRefetchIsRunning(false))
  }
}
