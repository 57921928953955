export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
};

export type AccountingAdvanceAccountEntity = {
  __typename?: 'AccountingAdvanceAccountEntity';
  accountingDocuments?: Maybe<Array<AccountingDocumentEntity>>;
  address: AddressEntity;
  addressId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  customerId: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  remainingKc: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  transactions?: Maybe<Array<AccountingAdvanceAccountTransactionEntity>>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type AccountingAdvanceAccountTransactionEntity = {
  __typename?: 'AccountingAdvanceAccountTransactionEntity';
  advanceAccountId: Scalars['String']['output'];
  amountKc: Scalars['Float']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fioTransaction?: Maybe<FioTransactionPairingEntity>;
  fioTransactionId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  newBalanceKc: Scalars['Float']['output'];
  order?: Maybe<OrderEntity>;
  orderId?: Maybe<Scalars['Int']['output']>;
  type: AdvanceAccountTransactionTypeEnum;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type AccountingDocument = {
  __typename?: 'AccountingDocument';
  amountInclVatKc?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  documentId?: Maybe<Scalars['Int']['output']>;
  documentUrl?: Maybe<Scalars['String']['output']>;
  documentWebUrl?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  emailSentAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isAdditionalInvoice?: Maybe<Scalars['Boolean']['output']>;
  isCancelled?: Maybe<Scalars['Boolean']['output']>;
  isFullRefund?: Maybe<Scalars['Boolean']['output']>;
  isGroupInvoice?: Maybe<Scalars['Boolean']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  type: AccountingDocumentType;
};

export type AccountingDocumentEntity = {
  __typename?: 'AccountingDocumentEntity';
  advanceAccountId?: Maybe<Scalars['ID']['output']>;
  amountInclVatKc?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  datePaid?: Maybe<Scalars['String']['output']>;
  documentId: Scalars['Int']['output'];
  documentNumber: Scalars['String']['output'];
  documentUrl: Scalars['String']['output'];
  documentWebUrl: Scalars['String']['output'];
  dueDate?: Maybe<Scalars['String']['output']>;
  emailSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  groupInvoiceId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  isAdditionalInvoice: Scalars['Boolean']['output'];
  isCancelled: Scalars['Boolean']['output'];
  isFullRefund?: Maybe<Scalars['Boolean']['output']>;
  isGroupInvoice: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  type: AccountingDocumentType;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  variableSymbol?: Maybe<Scalars['String']['output']>;
};

export enum AccountingDocumentType {
  AdvanceInvoice = 'ADVANCE_INVOICE',
  DanovyDoklad = 'DANOVY_DOKLAD',
  IncomeReceipt = 'INCOME_RECEIPT',
  Invoice = 'INVOICE',
  OpravnyDanovyDoklad = 'OPRAVNY_DANOVY_DOKLAD',
  ProformaInvoice = 'PROFORMA_INVOICE'
}

export type AccountingGroupInvoice = {
  __typename?: 'AccountingGroupInvoice';
  amountInclVatKc: Scalars['Float']['output'];
  clientEmail?: Maybe<Scalars['String']['output']>;
  createdDate: Scalars['String']['output'];
  customer?: Maybe<Customer>;
  documentId: Scalars['Int']['output'];
  documentUrl: Scalars['String']['output'];
  documentWebUrl: Scalars['String']['output'];
  dueDate?: Maybe<Scalars['String']['output']>;
  duzpDate?: Maybe<Scalars['String']['output']>;
  emailSentAt?: Maybe<Scalars['String']['output']>;
  externalReference?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orders: Array<OrderResult>;
  paidOn?: Maybe<Scalars['String']['output']>;
  variableSymbol?: Maybe<Scalars['String']['output']>;
};

export type AccountingGroupInvoiceResult = {
  __typename?: 'AccountingGroupInvoiceResult';
  data: Array<Maybe<AccountingGroupInvoice>>;
  total: Scalars['Int']['output'];
};

export type AccountingGroupOrders = {
  __typename?: 'AccountingGroupOrders';
  customer?: Maybe<Customer>;
  invoicingCustomer?: Maybe<Customer>;
  orders: Array<OrderResult>;
  totalInclVatKc: Scalars['Float']['output'];
  totalWoVatKc: Scalars['Float']['output'];
};

export type AddSupplierFlatRateMutationResult = {
  __typename?: 'AddSupplierFlatRateMutationResult';
  data?: Maybe<SupplierFlatRateList>;
  status?: Maybe<MutationStatus>;
};

export type AddSupplierToLoginMutationResult = {
  __typename?: 'AddSupplierToLoginMutationResult';
  data?: Maybe<LoginToSupplier>;
  status?: Maybe<MutationStatus>;
};

export type AdditionalFeeItem = {
  __typename?: 'AdditionalFeeItem';
  priceKcWoVat?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<ExtraFeesEnumContainer>;
};

export type AdditionalFeeItemOrder = {
  __typename?: 'AdditionalFeeItemOrder';
  priceKcWoVat?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<ExtraFeesEnumOrder>;
};

export type AddressEntity = {
  __typename?: 'AddressEntity';
  city: Scalars['String']['output'];
  countryIso: Scalars['String']['output'];
  gps?: Maybe<GpsCoordinationClass>;
  id: Scalars['ID']['output'];
  street?: Maybe<Scalars['String']['output']>;
  streetNumber: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  countryIso?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type AddressType = {
  __typename?: 'AddressType';
  city?: Maybe<Scalars['String']['output']>;
  countryIso?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type AddressTypeInclGpsInput = {
  city: Scalars['String']['input'];
  lat: Scalars['String']['input'];
  lng: Scalars['String']['input'];
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type AddressTypeInput = {
  city: Scalars['String']['input'];
  lat?: InputMaybe<Scalars['String']['input']>;
  lng?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export enum AdvanceAccountTransactionTypeEnum {
  Credit = 'CREDIT',
  Debit = 'DEBIT'
}

export type AvailableServicesSettings = {
  additionalContainerPickupAdditionalManipulations?: InputMaybe<Scalars['Float']['input']>;
  additionalContainerPickupIncludedKmInMinimumTransportPrice?: InputMaybe<Scalars['Float']['input']>;
  additionalContainerPickupMinimumPriceOfTransportKc?: InputMaybe<Scalars['Float']['input']>;
  bigBagCapacityTonne?: InputMaybe<Scalars['Float']['input']>;
  bigBagPriceKc?: InputMaybe<Scalars['Float']['input']>;
  containerRentFreeForDays?: InputMaybe<Scalars['Float']['input']>;
  conveyorFlatPrice?: InputMaybe<Scalars['Float']['input']>;
  conveyorPricePerTonne?: InputMaybe<Scalars['Float']['input']>;
  customerDistanceInFlatRateKm?: InputMaybe<Scalars['Float']['input']>;
  finishedOnlyWhenAllDocumentsAreUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  flatRateForDeliveryKc?: InputMaybe<Scalars['Float']['input']>;
  flatRateForPickupKc?: InputMaybe<Scalars['Float']['input']>;
  flatRateForSelfAsSupplierKc?: InputMaybe<Scalars['Float']['input']>;
  hasContractWithMetrak?: InputMaybe<Scalars['Boolean']['input']>;
  includedKmInFlatRate?: InputMaybe<Scalars['Float']['input']>;
  includedKmInMinimumTransportPrice?: InputMaybe<Scalars['Float']['input']>;
  includedKmInMinimumTransportPriceHeavyMachinery?: InputMaybe<Scalars['Float']['input']>;
  includedKmInMinimumTransportPriceMediumMachinery?: InputMaybe<Scalars['Float']['input']>;
  includedKmInMinimumTransportPriceSmallMachinery?: InputMaybe<Scalars['Float']['input']>;
  includedKmInMinimumTransportPriceTruck?: InputMaybe<Scalars['Float']['input']>;
  includedKmInMinimumTransportPriceTruckContainer?: InputMaybe<Scalars['Float']['input']>;
  includedKmInMinimumTransportPriceTruckMix?: InputMaybe<Scalars['Float']['input']>;
  manipulationsPerOrder?: InputMaybe<Scalars['Float']['input']>;
  manipulationsPerOrderHeavyMachinery?: InputMaybe<Scalars['Float']['input']>;
  manipulationsPerOrderMediumMachinery?: InputMaybe<Scalars['Float']['input']>;
  manipulationsPerOrderSmallMachinery?: InputMaybe<Scalars['Float']['input']>;
  manipulationsPerOrderTruck?: InputMaybe<Scalars['Float']['input']>;
  manipulationsPerOrderTruckContainer?: InputMaybe<Scalars['Float']['input']>;
  manipulationsPerOrderTruckMix?: InputMaybe<Scalars['Float']['input']>;
  minimumPriceOfTransportHeavyMachineryKc?: InputMaybe<Scalars['Float']['input']>;
  minimumPriceOfTransportKc?: InputMaybe<Scalars['Float']['input']>;
  minimumPriceOfTransportMediumMachineryKc?: InputMaybe<Scalars['Float']['input']>;
  minimumPriceOfTransportSmallMachineryKc?: InputMaybe<Scalars['Float']['input']>;
  minimumPriceOfTransportTruckContainerKc?: InputMaybe<Scalars['Float']['input']>;
  minimumPriceOfTransportTruckKc?: InputMaybe<Scalars['Float']['input']>;
  minimumPriceOfTransportTruckMixKc?: InputMaybe<Scalars['Float']['input']>;
  offersConveyorByWeight?: InputMaybe<Scalars['Boolean']['input']>;
  offersConveyorFlatPrice?: InputMaybe<Scalars['Boolean']['input']>;
  offersDeliveryInBigBag?: InputMaybe<Scalars['Boolean']['input']>;
  offersPump?: InputMaybe<Scalars['Boolean']['input']>;
  priceForPumpingPerCubic?: InputMaybe<Scalars['Float']['input']>;
  sendMaterialOrderWhenPaidAndIAmNotTransportingOrder?: InputMaybe<Scalars['Boolean']['input']>;
  sendZPOWhenContainerArrivedToCustomer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type B2bPriceList = {
  __typename?: 'B2bPriceList';
  b2bCustomer: Customer;
  container?: Maybe<Container>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  fixedPrice?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  priceCorrectionPercent?: Maybe<Scalars['Float']['output']>;
  product: Product;
  supplier?: Maybe<Supplier>;
};

export type B2bPriceListDataInput = {
  b2bCustomerId?: InputMaybe<Scalars['Int']['input']>;
  containerId?: InputMaybe<Scalars['Int']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  fixedPrice?: InputMaybe<Scalars['Float']['input']>;
  priceCorrectionPercent?: InputMaybe<Scalars['Float']['input']>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  supplierId?: InputMaybe<Scalars['Int']['input']>;
};

export type BaseMutationResult = {
  __typename?: 'BaseMutationResult';
  status?: Maybe<MutationStatus>;
};

export type BaseMutationResultWithId = {
  __typename?: 'BaseMutationResultWithId';
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<MutationStatus>;
};

export type BasePricePartsType = {
  __typename?: 'BasePricePartsType';
  b2bMargin?: Maybe<Scalars['Float']['output']>;
  basePriceTotal?: Maybe<Scalars['Float']['output']>;
  flatRate?: Maybe<Scalars['Float']['output']>;
  margin?: Maybe<Scalars['Float']['output']>;
  marginDiscount?: Maybe<Scalars['Float']['output']>;
  priceLimitDiscount?: Maybe<Scalars['Float']['output']>;
  terminalPrice?: Maybe<Scalars['Float']['output']>;
  transferPrice?: Maybe<Scalars['Float']['output']>;
};

export type CalculateDeliveryPriceAdminResult = {
  __typename?: 'CalculateDeliveryPriceAdminResult';
  distanceKm?: Maybe<Scalars['Float']['output']>;
  supplierId: Scalars['Int']['output'];
  totalPrice?: Maybe<PriceResult>;
};

export type CalculatePriceAdminResult = {
  __typename?: 'CalculatePriceAdminResult';
  routeSpecification?: Maybe<RouteSpecification>;
  totalPrice?: Maybe<PriceResult>;
};

export type CalculationOptionResponse = {
  __typename?: 'CalculationOptionResponse';
  distanceKm: Scalars['Float']['output'];
  encodedGeometry: Scalars['String']['output'];
  priceBreakdown: Array<PricePack>;
  priceInclVatKc: Scalars['Float']['output'];
  priceWoVatKc: Scalars['Float']['output'];
  terminalBranch?: Maybe<SupplierBranchEntity>;
  type: CalculationTypeEnum;
};

export enum CalculationTypeEnum {
  FlatRate = 'FLAT_RATE',
  TransportAndTerminal = 'TRANSPORT_AND_TERMINAL'
}

export enum CapabilityEnum {
  Bo = 'BO',
  Iczm = 'ICZM',
  Iczsr = 'ICZSR',
  Iczss = 'ICZSS',
  O = 'O'
}

export type CheckAdvanceAccountAvailabilityResponse = {
  __typename?: 'CheckAdvanceAccountAvailabilityResponse';
  accountRemainingBalanceKc?: Maybe<Scalars['Float']['output']>;
  response: CheckAdvanceAccountStatusResponseEnum;
};

export enum CheckAdvanceAccountStatusResponseEnum {
  AdvanceAccountNotEnabled = 'ADVANCE_ACCOUNT_NOT_ENABLED',
  InsufficientBalance = 'INSUFFICIENT_BALANCE',
  MatchingAdvanceAccountFound = 'MATCHING_ADVANCE_ACCOUNT_FOUND',
  MatchingAdvanceAccountHasEnoughBalance = 'MATCHING_ADVANCE_ACCOUNT_HAS_ENOUGH_BALANCE',
  NoMatchingAdvanceAccountFound = 'NO_MATCHING_ADVANCE_ACCOUNT_FOUND',
  OrderIsNotForRegisteredCustomer = 'ORDER_IS_NOT_FOR_REGISTERED_CUSTOMER',
  WrongPaymentMethod = 'WRONG_PAYMENT_METHOD'
}

export enum ConcreteComponentEnum {
  Aggregate = 'AGGREGATE',
  Consistency = 'CONSISTENCY',
  StrengthClass = 'STRENGTH_CLASS',
  Surcharge = 'SURCHARGE'
}

export enum ConcreteComponentType {
  Aggregate = 'AGGREGATE',
  Consistency = 'CONSISTENCY',
  StrengthClass = 'STRENGTH_CLASS',
  Surcharge = 'SURCHARGE'
}

export type ContactType = {
  __typename?: 'ContactType';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type ContactTypeInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type Container = {
  __typename?: 'Container';
  capacityM3: Scalars['Float']['output'];
  disabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  offerOnUiAsDefault?: Maybe<Scalars['Boolean']['output']>;
  priceFrom?: Maybe<Scalars['Float']['output']>;
  tooltip?: Maybe<Scalars['String']['output']>;
  uiOrder?: Maybe<Scalars['Int']['output']>;
  uiPicture?: Maybe<Scalars['String']['output']>;
  wasteCapacities?: Maybe<Array<Maybe<ContainerWasteCapacity>>>;
};

export type ContainerInput = {
  capacityM3?: InputMaybe<Scalars['Float']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offerOnUiAsDefault?: InputMaybe<Scalars['Boolean']['input']>;
  priceFrom?: InputMaybe<Scalars['Float']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  uiOrder?: InputMaybe<Scalars['Int']['input']>;
  uiPicture?: InputMaybe<Scalars['String']['input']>;
};

export type ContainerPrice = {
  __typename?: 'ContainerPrice';
  additionalFees?: Maybe<Array<Maybe<AdditionalFeeItem>>>;
  basePriceKcWoVat?: Maybe<Scalars['Float']['output']>;
  basePriceParts?: Maybe<BasePricePartsType>;
  supplierProfitWoVatKc?: Maybe<Scalars['Float']['output']>;
};

export type ContainerWasteCapacity = {
  __typename?: 'ContainerWasteCapacity';
  fullCapacityTonne: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  product: Product;
};

export type CreateAdvanceAccountInputArgs = {
  actionAddress: AddressTypeInput;
  name: Scalars['String']['input'];
  note: Scalars['String']['input'];
};

export type CreateAdvanceAccountInvoiceInputArgs = {
  amountInclVatKc: Scalars['Float']['input'];
  dueDate: Scalars['DateTimeISO']['input'];
  invoiceItemName: Scalars['String']['input'];
  note: Scalars['String']['input'];
};

export type CreateGroupInvoiceOptsInput = {
  clientEmail: Scalars['String']['input'];
  createdDate: Scalars['String']['input'];
  customerId?: InputMaybe<Scalars['Int']['input']>;
  dueDate: Scalars['String']['input'];
  duzpDate: Scalars['String']['input'];
  externalReference: Scalars['String']['input'];
  noTotalRounding: Scalars['Boolean']['input'];
  separateOrdersToItems?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateMaterialInput = {
  name: Scalars['String']['input'];
  tonToCubes?: InputMaybe<Scalars['Float']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  type: MaterialTypeEnum;
  uiOrder?: InputMaybe<Scalars['Int']['input']>;
  uiPicture?: InputMaybe<Scalars['String']['input']>;
};

export type Customer = {
  __typename?: 'Customer';
  allowedPaymentMethods?: Maybe<Array<PaymentType>>;
  b2bMarginCorrection: Scalars['Float']['output'];
  city?: Maybe<Scalars['String']['output']>;
  companyIdentification?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyTaxId?: Maybe<Scalars['String']['output']>;
  countryIso?: Maybe<Scalars['String']['output']>;
  createInvoices: Scalars['Boolean']['output'];
  customerDiscount: Scalars['Float']['output'];
  email: Scalars['String']['output'];
  firstname?: Maybe<Scalars['String']['output']>;
  hasFixedPrice: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isResellCompany: Scalars['Boolean']['output'];
  lastname?: Maybe<Scalars['String']['output']>;
  limitEndCustomerEmails: Scalars['Boolean']['output'];
  loginId: Scalars['Int']['output'];
  metrakMarginCorrection: Scalars['Float']['output'];
  passwordSetEmailExpiry?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  primaryPaymentMethod: Scalars['String']['output'];
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  totalContainersOrdered: Scalars['Int']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type CustomerAdvanceAccountSetup = {
  __typename?: 'CustomerAdvanceAccountSetup';
  isUsingAdvanceAccounts: Scalars['Boolean']['output'];
  negativeBalanceAllowance?: Maybe<Scalars['Float']['output']>;
  useMultipleAccounts?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomerAdvanceAccountSetupInput = {
  isUsingAdvanceAccounts?: Scalars['Boolean']['input'];
  negativeBalanceAllowance?: InputMaybe<Scalars['Float']['input']>;
  useMultipleAccounts?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomerListPaginatedResponse = {
  __typename?: 'CustomerListPaginatedResponse';
  items: Array<CustomerResponse>;
  total: Scalars['Int']['output'];
};

export type CustomerResponse = {
  __typename?: 'CustomerResponse';
  advanceAccountsSetup?: Maybe<CustomerAdvanceAccountSetup>;
  allowedPaymentMethods: Array<PaymentMethodEnum>;
  b2bMarginCorrection: Scalars['Float']['output'];
  city: Scalars['String']['output'];
  companyIdentification?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyTaxId?: Maybe<Scalars['String']['output']>;
  countryIso: Scalars['String']['output'];
  createInvoices: Scalars['Boolean']['output'];
  createdAt: Scalars['String']['output'];
  customerDiscount: Scalars['Float']['output'];
  email: Scalars['String']['output'];
  firstname?: Maybe<Scalars['String']['output']>;
  hasFixedPrice: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  invoicingEmail?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  isResellCompany: Scalars['Boolean']['output'];
  lastname?: Maybe<Scalars['String']['output']>;
  limitEndCustomerEmails: Scalars['Boolean']['output'];
  loginId: Scalars['Int']['output'];
  metrakMarginCorrection: Scalars['Float']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  passwordSetEmailExpiry?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  primaryPaymentMethod: PaymentMethodEnum;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber: Scalars['String']['output'];
  totalContainersOrdered: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

export type DefaultMutationReturnPlain = {
  __typename?: 'DefaultMutationReturnPlain';
  status: MutationStatus;
};

export type DeleteSupplierToLoginMutationResult = {
  __typename?: 'DeleteSupplierToLoginMutationResult';
  id: Scalars['Int']['output'];
  status?: Maybe<MutationStatus>;
};

export type DeliveryPriceList = {
  __typename?: 'DeliveryPriceList';
  baseTransportPriceBiggerTruckKc?: Maybe<Scalars['Float']['output']>;
  baseTransportPriceKc?: Maybe<Scalars['Float']['output']>;
  baseTransportRadiusKm?: Maybe<Scalars['Float']['output']>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  margin?: Maybe<Scalars['Float']['output']>;
  pricePerKmBiggerTruckKc?: Maybe<Scalars['Float']['output']>;
  pricePerKmKc?: Maybe<Scalars['Float']['output']>;
  pricePerTonneKc?: Maybe<Scalars['Float']['output']>;
  product?: Maybe<Product>;
  supplier?: Maybe<Supplier>;
};

export type DeliveryPriceListDataInput = {
  baseTransportPriceBiggerTruckKc?: InputMaybe<Scalars['Float']['input']>;
  baseTransportPriceKc?: InputMaybe<Scalars['Float']['input']>;
  baseTransportRadiusKm?: InputMaybe<Scalars['Float']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  margin?: InputMaybe<Scalars['Float']['input']>;
  pricePerKmBiggerTruckKc?: InputMaybe<Scalars['Float']['input']>;
  pricePerKmKc?: InputMaybe<Scalars['Float']['input']>;
  pricePerTonneKc?: InputMaybe<Scalars['Float']['input']>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  supplierId?: InputMaybe<Scalars['Int']['input']>;
};

export type DemandChangeLogEntity = {
  __typename?: 'DemandChangeLogEntity';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  login?: Maybe<LoginEntity>;
  loginId?: Maybe<Scalars['Int']['output']>;
  operation: Scalars['String']['output'];
  orderId: Scalars['Int']['output'];
  result?: Maybe<Scalars['JSONObject']['output']>;
  source: Scalars['String']['output'];
  variables: Scalars['JSONObject']['output'];
};

export type EmailHistory = {
  __typename?: 'EmailHistory';
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  recipientsTo: Array<Maybe<Scalars['String']['output']>>;
  subject: Scalars['String']['output'];
  templateName: Scalars['String']['output'];
};

export enum ExtraFeesEnumContainer {
  ContainerRent = 'containerRent',
  EndDuringWeekend = 'endDuringWeekend',
  ExpressStart = 'expressStart',
  StartDuringWeekend = 'startDuringWeekend'
}

export enum ExtraFeesEnumOrder {
  Cod = 'COD',
  CustomerDiscount = 'customerDiscount'
}

export type FileList = {
  __typename?: 'FileList';
  filename?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  supplier?: Maybe<Scalars['String']['output']>;
};

export type FindByCompanyIdResponse = {
  __typename?: 'FindByCompanyIdResponse';
  ares: SubjectInfoType;
};

export type FioSyncLatestResult = {
  __typename?: 'FioSyncLatestResult';
  foundTransactions?: Maybe<Scalars['Int']['output']>;
  latestSync?: Maybe<Scalars['String']['output']>;
  pairedTransactions?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type FioSyncSettings = {
  __typename?: 'FioSyncSettings';
  allowAutoPaymentPaired?: Maybe<Scalars['Boolean']['output']>;
  allowScheduledSync?: Maybe<Scalars['Boolean']['output']>;
};

export type FioTransaction = {
  __typename?: 'FioTransaction';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  groupInvoiceId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  order?: Maybe<OrderResult>;
  orderId?: Maybe<Scalars['Int']['output']>;
  payerAccountNumber: Scalars['String']['output'];
  payerName: Scalars['String']['output'];
  paymentType: Scalars['String']['output'];
  processingStatus: ProcessingStatusEnum;
  status: PairingStatusEnum;
  transactionDate: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
  variableSymbol?: Maybe<Scalars['String']['output']>;
};

export type FioTransactionListResult = {
  __typename?: 'FioTransactionListResult';
  data: Array<FioTransaction>;
  total: Scalars['Int']['output'];
};

export type FioTransactionPairingEntity = {
  __typename?: 'FioTransactionPairingEntity';
  accountingDocument?: Maybe<AccountingDocumentEntity>;
  accountingDocumentId?: Maybe<Scalars['ID']['output']>;
  amount: Scalars['Float']['output'];
  groupInvoiceId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['String']['output'];
  orderId?: Maybe<Scalars['ID']['output']>;
  transactionId: Scalars['String']['output'];
};

export type FioTransactionStateStat = {
  __typename?: 'FioTransactionStateStat';
  count: Scalars['Int']['output'];
  status: PairingStatusEnum;
};

export enum FlagEnum {
  ActiveOrders = 'ACTIVE_ORDERS',
  ByB2B = 'BY_B2B',
  CertDone = 'CERT_DONE',
  CertReq = 'CERT_REQ',
  InclFinals = 'INCL_FINALS',
  InvoicingNoteDone = 'INVOICING_NOTE_DONE',
  InvoicingNoteNotDone = 'INVOICING_NOTE_NOT_DONE',
  ManualOrder = 'MANUAL_ORDER',
  OrdersWoInvoice = 'ORDERS_WO_INVOICE',
  PriceLimitOrders = 'PRICE_LIMIT_ORDERS',
  WithGroupInvoice = 'WITH_GROUP_INVOICE',
  WithZpoNote = 'WITH_ZPO_NOTE',
  WoFinals = 'WO_FINALS'
}

export type FromToDatesResult = {
  __typename?: 'FromToDatesResult';
  fromDates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  toDates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GetAvailableResourcesForWasteCollectionResponse = {
  __typename?: 'GetAvailableResourcesForWasteCollectionResponse';
  availableContainers?: Maybe<Array<Scalars['String']['output']>>;
  availableWastes?: Maybe<Array<Scalars['String']['output']>>;
};

export type GetAvailableServicesForLocalityResponse = {
  __typename?: 'GetAvailableServicesForLocalityResponse';
  availableServices: Array<ServiceCategory>;
  supplierBranches: Array<SupplierBranchEntity>;
};

export type GpsCoordinationClass = {
  __typename?: 'GpsCoordinationClass';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type GpsCoordinationInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type GpsCoordinations = {
  lat?: InputMaybe<Scalars['String']['input']>;
  lng?: InputMaybe<Scalars['String']['input']>;
};

export type IczSuggest = {
  __typename?: 'IczSuggest';
  icz: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type LatLngInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type ListOrp = {
  __typename?: 'ListOrp';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ListOrpEntity = {
  __typename?: 'ListOrpEntity';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ListPouEntity = {
  __typename?: 'ListPouEntity';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orp: ListOrpEntity;
};

export type ListZuj = {
  __typename?: 'ListZuj';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orp?: Maybe<ListOrp>;
  zip: Scalars['String']['output'];
};

export type Login = {
  __typename?: 'Login';
  email: Scalars['String']['output'];
  firstname?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isMasterAdmin: Scalars['Boolean']['output'];
  lastname?: Maybe<Scalars['String']['output']>;
  loginToSupplier?: Maybe<Array<Maybe<LoginToSupplier>>>;
};

export type LoginEntity = {
  __typename?: 'LoginEntity';
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastname: Scalars['String']['output'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  isMasterAdmin: Scalars['Boolean']['input'];
  lastname: Scalars['String']['input'];
};

export type LoginToSupplier = {
  __typename?: 'LoginToSupplier';
  id?: Maybe<Scalars['Int']['output']>;
  supplier?: Maybe<Supplier>;
};

export type MasterDataItem = {
  __typename?: 'MasterDataItem';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  sysName: Scalars['String']['output'];
};

export type Material = {
  __typename?: 'Material';
  id: Scalars['Int']['output'];
  isFraction: Scalars['Boolean']['output'];
  isMaterial: Scalars['Boolean']['output'];
  isType: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  tonToCubes?: Maybe<Scalars['Float']['output']>;
  tooltip?: Maybe<Scalars['String']['output']>;
  uiOrder?: Maybe<Scalars['Int']['output']>;
  uiPicture?: Maybe<Scalars['String']['output']>;
};

export enum MaterialComponentType {
  Fraction = 'FRACTION',
  Material = 'MATERIAL',
  Type = 'TYPE'
}

export enum MaterialTypeEnum {
  Fraction = 'FRACTION',
  Material = 'MATERIAL',
  Type = 'TYPE'
}

export type Mutation = {
  __typename?: 'Mutation';
  activateDraft?: Maybe<BaseMutationResult>;
  /** Add product-container to supplier */
  addContainerToSupplier: DefaultMutationReturnPlain;
  addOrderComment: DefaultMutationReturnPlain;
  addSupplierFlatRate?: Maybe<AddSupplierFlatRateMutationResult>;
  addSupplierToLogin?: Maybe<AddSupplierToLoginMutationResult>;
  approveFioTransactions?: Maybe<BaseMutationResult>;
  /** Updates customer */
  approveOrderContent: DefaultMutationReturnPlain;
  cancelAccountingDocument: DefaultMutationReturnPlain;
  changeOrderState?: Maybe<BaseMutationResult>;
  changeOwner?: Maybe<BaseMutationResult>;
  clearCalcCache?: Maybe<BaseMutationResult>;
  /** Create new advance account for customer */
  createAdvanceAccount: DefaultMutationReturnPlain;
  /** Create new advance account for customer */
  createAdvanceAccountInvoice: DefaultMutationReturnPlain;
  createB2bPriceList?: Maybe<BaseMutationResult>;
  createContainer?: Maybe<BaseMutationResult>;
  createDeliveryPriceList?: Maybe<BaseMutationResult>;
  createGroupInvoiceForOrders?: Maybe<BaseMutationResult>;
  createLogin?: Maybe<BaseMutationResult>;
  createMaterial?: Maybe<BaseMutationResult>;
  createOrder?: Maybe<BaseMutationResult>;
  createProduct?: Maybe<BaseMutationResult>;
  createProductConcreteComponent: DefaultMutationReturnPlain;
  createProductMaterialComponent: DefaultMutationReturnPlain;
  createSupplier?: Maybe<BaseMutationResultWithId>;
  createSupplierPriceList?: Maybe<BaseMutationResultWithId>;
  /** Add a new supplier */
  createSupplierSupplier: DefaultMutationReturnPlain;
  createSupplierTransportSupplierLink: DefaultMutationReturnPlain;
  createTerminal?: Maybe<BaseMutationResult>;
  createTerminalPriceList?: Maybe<BaseMutationResult>;
  /** Add a link to terminal branch */
  createWasteCollectionLink: DefaultMutationReturnPlain;
  createWasteFinal?: Maybe<BaseMutationResult>;
  deleteAdvanceAccount: DefaultMutationReturnPlain;
  deleteB2bPriceList?: Maybe<BaseMutationResult>;
  deleteContainerToSupplier: DefaultMutationReturnPlain;
  deleteContainerWasteType?: Maybe<BaseMutationResult>;
  deleteDeliveryPriceList?: Maybe<BaseMutationResult>;
  deleteDraft?: Maybe<BaseMutationResult>;
  deleteFile?: Maybe<BaseMutationResult>;
  deleteLogin?: Maybe<BaseMutationResult>;
  deleteMaterial?: Maybe<BaseMutationResult>;
  deleteProduct?: Maybe<BaseMutationResult>;
  deleteProductConcreteComponent: DefaultMutationReturnPlain;
  deleteProductContainer: DefaultMutationReturnPlain;
  deleteProductContainerCapacity: DefaultMutationReturnPlain;
  deleteProductMaterial: DefaultMutationReturnPlain;
  deleteProductMaterialComponent: DefaultMutationReturnPlain;
  deleteProductWaste: DefaultMutationReturnPlain;
  deleteSellerConcretePriceList: DefaultMutationReturnPlain;
  deleteSupplier?: Maybe<BaseMutationResult>;
  deleteSupplierFlatRate?: Maybe<BaseMutationResult>;
  deleteSupplierMaterialPriceList: DefaultMutationReturnPlain;
  deleteSupplierPriceList?: Maybe<BaseMutationResult>;
  deleteSupplierServiceLoadTransport: DefaultMutationReturnPlain;
  /** Drop supplier */
  deleteSupplierSupplier: DefaultMutationReturnPlain;
  deleteSupplierTerminalPriceList: DefaultMutationReturnPlain;
  deleteSupplierToLogin?: Maybe<DeleteSupplierToLoginMutationResult>;
  /** Removes a link to terminal branch */
  deleteSupplierTransportSupplierLink: DefaultMutationReturnPlain;
  deleteSupplierWasteCollectionFlatRate: DefaultMutationReturnPlain;
  deleteTerminal?: Maybe<BaseMutationResult>;
  deleteTerminalPriceList?: Maybe<BaseMutationResult>;
  /** Removes a link to terminal branch */
  deleteWasteCollectionLink: DefaultMutationReturnPlain;
  deleteWasteFinal?: Maybe<BaseMutationResult>;
  /** Duplicate a supplier branch */
  duplicateSupplierBranch: DefaultMutationReturnPlain;
  /** Enable service for branch */
  enableBranchService: DefaultMutationReturnPlain;
  forceFioTransactionDownload?: Maybe<BaseMutationResult>;
  forceMatchFioTransaction?: Maybe<BaseMutationResult>;
  generateWasteFinalConfirmation?: Maybe<BaseMutationResult>;
  generateZpo?: Maybe<BaseMutationResult>;
  lockOrder: DefaultMutationReturnPlain;
  markManualDelivery?: Maybe<BaseMutationResult>;
  migrateSupplier?: Maybe<BaseMutationResult>;
  pairFioTransactionWithOrder?: Maybe<BaseMutationResult>;
  payMatchedFioTransactions?: Maybe<BaseMutationResult>;
  payOrderFromAdvanceAccount: DefaultMutationReturnPlain;
  paymentAction: DefaultMutationReturnPlain;
  performGroupChange?: Maybe<BaseMutationResult>;
  performStateAction?: Maybe<BaseMutationResult>;
  performZpoEmailDelivery?: Maybe<BaseMutationResult>;
  reGenerateZpo?: Maybe<BaseMutationResult>;
  /** Recheck GoPay payment status */
  recheckGPPaymentStatus: DefaultMutationReturnPlain;
  releaseOrderLock: DefaultMutationReturnPlain;
  runOrderOperation?: Maybe<BaseMutationResult>;
  sendAdvanceAccountInvoiceEmail: DefaultMutationReturnPlain;
  sendConfirmationByEmail?: Maybe<BaseMutationResult>;
  sendPwdSetupEmailForB2bCustomer?: Maybe<BaseMutationResult>;
  sendSetPwdEmail?: Maybe<BaseMutationResult>;
  setFioStartIndex?: Maybe<BaseMutationResult>;
  setFioSyncSettings?: Maybe<BaseMutationResult>;
  syncAccountingDocument: DefaultMutationReturnPlain;
  updateAdvanceAccount: DefaultMutationReturnPlain;
  updateB2bPriceList?: Maybe<BaseMutationResult>;
  /** Update service for branch */
  updateBranchService: DefaultMutationReturnPlain;
  updateContainer?: Maybe<BaseMutationResult>;
  updateContainerDisabled?: Maybe<BaseMutationResult>;
  /** Updates customer */
  updateCustomer: DefaultMutationReturnPlain;
  updateDeliveryPriceList?: Maybe<BaseMutationResult>;
  updateField?: Maybe<BaseMutationResult>;
  updateFinishData?: Maybe<BaseMutationResult>;
  updateGroupInvoice?: Maybe<BaseMutationResult>;
  updateLogin?: Maybe<BaseMutationResult>;
  updateMaterial?: Maybe<BaseMutationResult>;
  updateOrder?: Maybe<BaseMutationResult>;
  updateOrderContentFinals?: Maybe<BaseMutationResult>;
  updateProduct?: Maybe<BaseMutationResult>;
  updateProductConcreteComponent: DefaultMutationReturnPlain;
  updateProductMaterialComponent: DefaultMutationReturnPlain;
  updateSupplier?: Maybe<BaseMutationResult>;
  /** Edit a supplier */
  updateSupplierContainer: DefaultMutationReturnPlain;
  updateSupplierDisabled?: Maybe<BaseMutationResult>;
  updateSupplierFlatRate?: Maybe<BaseMutationResult>;
  updateSupplierPriceList?: Maybe<BaseMutationResult>;
  /** Edit a supplier */
  updateSupplierSupplier: DefaultMutationReturnPlain;
  updateTerminal?: Maybe<BaseMutationResult>;
  updateTerminalDisabled?: Maybe<BaseMutationResult>;
  updateTerminalPriceList?: Maybe<BaseMutationResult>;
  updateWasteCollectionLink: DefaultMutationReturnPlain;
  updateWasteFinal?: Maybe<BaseMutationResult>;
  updateZpoDetail?: Maybe<BaseMutationResult>;
  upsertContainerWasteType?: Maybe<BaseMutationResult>;
  upsertProductContainer: DefaultMutationReturnPlain;
  upsertProductContainerCapacity: DefaultMutationReturnPlain;
  upsertProductMaterial: DefaultMutationReturnPlain;
  upsertProductWaste: DefaultMutationReturnPlain;
  /** Enable service for branch */
  upsertSellerConcretePriceList: DefaultMutationReturnPlain;
  /** Upsert a supplier branch */
  upsertSupplierBranch: DefaultMutationReturnPlain;
  /** Add a flat rate price list for waste collection */
  upsertSupplierMaterialPriceList: DefaultMutationReturnPlain;
  /** Enable service for branch */
  upsertSupplierServiceLoadTransport: DefaultMutationReturnPlain;
  /** Add a flat rate price list for waste collection */
  upsertSupplierTerminalPriceList: DefaultMutationReturnPlain;
  /** Add a flat rate price list for waste collection */
  upsertSupplierWasteCollectionFlatRate: DefaultMutationReturnPlain;
};


export type MutationActivateDraftArgs = {
  orderId: Scalars['Int']['input'];
};


export type MutationAddContainerToSupplierArgs = {
  branchId: Scalars['ID']['input'];
  data: SupplierContainerInputArgs;
};


export type MutationAddOrderCommentArgs = {
  comment: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
};


export type MutationAddSupplierFlatRateArgs = {
  flatPriceKc?: InputMaybe<Scalars['Float']['input']>;
  productId: Scalars['Int']['input'];
  supplierPriceListId: Scalars['Int']['input'];
};


export type MutationAddSupplierToLoginArgs = {
  loginId: Scalars['Int']['input'];
  supplierId: Scalars['Int']['input'];
};


export type MutationApproveFioTransactionsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationApproveOrderContentArgs = {
  cancelApprove?: InputMaybe<Scalars['Boolean']['input']>;
  orderContentId: Scalars['Int']['input'];
};


export type MutationCancelAccountingDocumentArgs = {
  accountingDocumentId: Scalars['ID']['input'];
};


export type MutationChangeOrderStateArgs = {
  orderContentId: Scalars['Int']['input'];
  orderContentStateSys: Scalars['String']['input'];
};


export type MutationChangeOwnerArgs = {
  loginId?: InputMaybe<Scalars['Int']['input']>;
  orderId: Scalars['Int']['input'];
};


export type MutationCreateAdvanceAccountArgs = {
  customerId: Scalars['ID']['input'];
  data: CreateAdvanceAccountInputArgs;
};


export type MutationCreateAdvanceAccountInvoiceArgs = {
  advanceAccountId: Scalars['ID']['input'];
  data: CreateAdvanceAccountInvoiceInputArgs;
};


export type MutationCreateB2bPriceListArgs = {
  data?: InputMaybe<B2bPriceListDataInput>;
};


export type MutationCreateContainerArgs = {
  data: ContainerInput;
};


export type MutationCreateDeliveryPriceListArgs = {
  data?: InputMaybe<DeliveryPriceListDataInput>;
};


export type MutationCreateGroupInvoiceForOrdersArgs = {
  opts: CreateGroupInvoiceOptsInput;
  orderIds: Array<Scalars['Int']['input']>;
};


export type MutationCreateLoginArgs = {
  assignSupplierId?: InputMaybe<Scalars['Int']['input']>;
  data: LoginInput;
  sendNewAccountEmail?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateMaterialArgs = {
  data?: InputMaybe<CreateMaterialInput>;
};


export type MutationCreateOrderArgs = {
  data: OrderDataInput;
  isDraft: Scalars['Boolean']['input'];
};


export type MutationCreateProductArgs = {
  data: ProductDataInput;
  productType: ProductType;
};


export type MutationCreateProductConcreteComponentArgs = {
  data: ProductConcreteComponentInputArgs;
};


export type MutationCreateProductMaterialComponentArgs = {
  data: ProductMaterialComponentInputArgs;
};


export type MutationCreateSupplierArgs = {
  data: SupplierDataInput;
  duplicatePriceListFrom?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateSupplierPriceListArgs = {
  data: SupplierPriceListDataInput;
  supplierId: Scalars['Int']['input'];
};


export type MutationCreateSupplierSupplierArgs = {
  data: SupplierSupplierInputArgs;
};


export type MutationCreateSupplierTransportSupplierLinkArgs = {
  branchServiceId: Scalars['ID']['input'];
  supplierBranchId: Scalars['ID']['input'];
  transportCategory: TransportCategory;
};


export type MutationCreateTerminalArgs = {
  data: TerminalDataInput;
};


export type MutationCreateTerminalPriceListArgs = {
  data: TerminalPriceListDataInput;
  terminalId: Scalars['Int']['input'];
};


export type MutationCreateWasteCollectionLinkArgs = {
  branchServiceId: Scalars['ID']['input'];
  terminalBranchId: Scalars['ID']['input'];
  wasteCategory: WasteCategory;
};


export type MutationCreateWasteFinalArgs = {
  data: WasteFinalDateInput;
  generateConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  orderContentId: Scalars['Int']['input'];
};


export type MutationDeleteAdvanceAccountArgs = {
  advanceAccountId: Scalars['ID']['input'];
};


export type MutationDeleteB2bPriceListArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteContainerToSupplierArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteContainerWasteTypeArgs = {
  containerWasteTypeId: Scalars['Int']['input'];
};


export type MutationDeleteDeliveryPriceListArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteDraftArgs = {
  orderId: Scalars['Int']['input'];
};


export type MutationDeleteFileArgs = {
  key: Scalars['String']['input'];
  orderContentId: Scalars['Int']['input'];
};


export type MutationDeleteLoginArgs = {
  loginId: Scalars['Int']['input'];
};


export type MutationDeleteMaterialArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteProductArgs = {
  productId: Scalars['Int']['input'];
};


export type MutationDeleteProductConcreteComponentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProductContainerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProductContainerCapacityArgs = {
  containerCapacityId: Scalars['ID']['input'];
};


export type MutationDeleteProductMaterialArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProductMaterialComponentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProductWasteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSellerConcretePriceListArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSupplierArgs = {
  supplierId: Scalars['Int']['input'];
};


export type MutationDeleteSupplierFlatRateArgs = {
  flatRateId: Scalars['Int']['input'];
};


export type MutationDeleteSupplierMaterialPriceListArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSupplierPriceListArgs = {
  supplierPriceListId: Scalars['Int']['input'];
};


export type MutationDeleteSupplierServiceLoadTransportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSupplierSupplierArgs = {
  supplierId: Scalars['String']['input'];
};


export type MutationDeleteSupplierTerminalPriceListArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSupplierToLoginArgs = {
  supplierToLoginId: Scalars['Int']['input'];
};


export type MutationDeleteSupplierTransportSupplierLinkArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSupplierWasteCollectionFlatRateArgs = {
  branchServiceId: Scalars['ID']['input'];
  productWasteId: Scalars['ID']['input'];
};


export type MutationDeleteTerminalArgs = {
  terminalId: Scalars['Int']['input'];
};


export type MutationDeleteTerminalPriceListArgs = {
  terminalPriceListId: Scalars['Int']['input'];
};


export type MutationDeleteWasteCollectionLinkArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteWasteFinalArgs = {
  wasteFinalId: Scalars['ID']['input'];
};


export type MutationDuplicateSupplierBranchArgs = {
  branchId: Scalars['ID']['input'];
};


export type MutationEnableBranchServiceArgs = {
  branchId: Scalars['ID']['input'];
  serviceCategory: ServiceCategory;
};


export type MutationForceMatchFioTransactionArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGenerateWasteFinalConfirmationArgs = {
  wasteFinalId: Scalars['ID']['input'];
};


export type MutationGenerateZpoArgs = {
  orderContentId: Scalars['Int']['input'];
};


export type MutationLockOrderArgs = {
  lockDurationMin?: InputMaybe<Scalars['Int']['input']>;
  orderId: Scalars['Int']['input'];
};


export type MutationMarkManualDeliveryArgs = {
  orderContentId: Scalars['Int']['input'];
};


export type MutationMigrateSupplierArgs = {
  branchForSupplierWithName?: InputMaybe<Scalars['String']['input']>;
  overwriteExisting?: InputMaybe<Scalars['Boolean']['input']>;
  supplierId: Scalars['Int']['input'];
  treatAsBranch?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPairFioTransactionWithOrderArgs = {
  fioTransactionPairingId: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
  performOrderContentStateTransition: Scalars['Boolean']['input'];
};


export type MutationPayMatchedFioTransactionsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationPayOrderFromAdvanceAccountArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationPaymentActionArgs = {
  action: PaymentActionEnum;
  orderId: Scalars['Int']['input'];
  paramsJson?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPerformGroupChangeArgs = {
  action: Scalars['String']['input'];
  orderContentId: Array<InputMaybe<Scalars['Int']['input']>>;
  payload?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPerformStateActionArgs = {
  action: PerformStateActionEnum;
  orderContentId: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type MutationPerformZpoEmailDeliveryArgs = {
  additionalMessage?: InputMaybe<Scalars['String']['input']>;
  orderContentId: Scalars['Int']['input'];
};


export type MutationReGenerateZpoArgs = {
  orderContentId: Scalars['Int']['input'];
};


export type MutationRecheckGpPaymentStatusArgs = {
  orderId: Scalars['Int']['input'];
};


export type MutationReleaseOrderLockArgs = {
  orderId: Scalars['Int']['input'];
};


export type MutationRunOrderOperationArgs = {
  operation: OrderOperationEnum;
  orderId?: InputMaybe<Scalars['Int']['input']>;
  paramsDate?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendAdvanceAccountInvoiceEmailArgs = {
  accountingDocumentId: Scalars['ID']['input'];
};


export type MutationSendConfirmationByEmailArgs = {
  wasteFinalId: Scalars['ID']['input'];
};


export type MutationSendPwdSetupEmailForB2bCustomerArgs = {
  loginId: Scalars['Int']['input'];
};


export type MutationSendSetPwdEmailArgs = {
  loginId: Scalars['Int']['input'];
};


export type MutationSetFioStartIndexArgs = {
  index: Scalars['String']['input'];
};


export type MutationSetFioSyncSettingsArgs = {
  allowAutoPaymentPaired?: InputMaybe<Scalars['Boolean']['input']>;
  allowScheduledSync?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSyncAccountingDocumentArgs = {
  accountingDocumentId: Scalars['ID']['input'];
};


export type MutationUpdateAdvanceAccountArgs = {
  advanceAccountId: Scalars['ID']['input'];
  stringifiesUpdates: Scalars['String']['input'];
};


export type MutationUpdateB2bPriceListArgs = {
  data?: InputMaybe<B2bPriceListDataInput>;
  id: Scalars['Int']['input'];
};


export type MutationUpdateBranchServiceArgs = {
  branchServiceId: Scalars['ID']['input'];
  data: SupplierBranchServiceInputArgs;
};


export type MutationUpdateContainerArgs = {
  containerId: Scalars['Int']['input'];
  data: ContainerInput;
};


export type MutationUpdateContainerDisabledArgs = {
  containerId: Scalars['Int']['input'];
  disabled: Scalars['Boolean']['input'];
};


export type MutationUpdateCustomerArgs = {
  data: UpdateCustomerInputArgs;
};


export type MutationUpdateDeliveryPriceListArgs = {
  data?: InputMaybe<DeliveryPriceListDataInput>;
  id: Scalars['Int']['input'];
};


export type MutationUpdateFieldArgs = {
  field: UpdateFieldEnum;
  newValue: Scalars['String']['input'];
  orderContentId: Scalars['Int']['input'];
};


export type MutationUpdateFinishDataArgs = {
  orderItem?: InputMaybe<Scalars['Int']['input']>;
  orderNumber: Scalars['Int']['input'];
  realTerminalPrice?: InputMaybe<Scalars['Float']['input']>;
  realTransportPrice?: InputMaybe<Scalars['Float']['input']>;
  realWeight?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdateGroupInvoiceArgs = {
  id: Scalars['ID']['input'];
  updates: Scalars['String']['input'];
};


export type MutationUpdateLoginArgs = {
  data: LoginInput;
  loginId: Scalars['Int']['input'];
};


export type MutationUpdateMaterialArgs = {
  data?: InputMaybe<UpdateMaterialInput>;
  id: Scalars['Int']['input'];
};


export type MutationUpdateOrderArgs = {
  data: OrderDataInput;
  orderId: Scalars['Int']['input'];
};


export type MutationUpdateOrderContentFinalsArgs = {
  finalTerminal?: InputMaybe<Scalars['String']['input']>;
  finalWasteType?: InputMaybe<Scalars['String']['input']>;
  finalWeight?: InputMaybe<Scalars['Int']['input']>;
  orderContentId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateProductArgs = {
  data: ProductDataInput;
  productId: Scalars['Int']['input'];
};


export type MutationUpdateProductConcreteComponentArgs = {
  data: ProductConcreteComponentInputArgs;
  id: Scalars['ID']['input'];
};


export type MutationUpdateProductMaterialComponentArgs = {
  data: ProductMaterialComponentInputArgs;
  id: Scalars['ID']['input'];
};


export type MutationUpdateSupplierArgs = {
  data: SupplierDataInput;
  supplierId: Scalars['Int']['input'];
};


export type MutationUpdateSupplierContainerArgs = {
  data: UpdateSupplierContainerInputArgs;
  id: Scalars['ID']['input'];
};


export type MutationUpdateSupplierDisabledArgs = {
  disabled: Scalars['Boolean']['input'];
  supplierId: Scalars['Int']['input'];
};


export type MutationUpdateSupplierFlatRateArgs = {
  flatPriceKc: Scalars['Float']['input'];
  flatRateId: Scalars['Int']['input'];
};


export type MutationUpdateSupplierPriceListArgs = {
  data: SupplierPriceListDataInput;
  supplierPriceListId: Scalars['Int']['input'];
};


export type MutationUpdateSupplierSupplierArgs = {
  data: SupplierSupplierInputArgs;
  id: Scalars['ID']['input'];
};


export type MutationUpdateTerminalArgs = {
  data: TerminalDataInput;
  terminalId: Scalars['Int']['input'];
};


export type MutationUpdateTerminalDisabledArgs = {
  disabled: Scalars['Boolean']['input'];
  terminalId: Scalars['Int']['input'];
};


export type MutationUpdateTerminalPriceListArgs = {
  data: TerminalPriceListDataInput;
  terminalPriceListId: Scalars['Int']['input'];
};


export type MutationUpdateWasteCollectionLinkArgs = {
  includedKmInFlatRate?: InputMaybe<Scalars['Int']['input']>;
  transportFlatRateKc?: InputMaybe<Scalars['Float']['input']>;
  wasteCollectionLinkId: Scalars['ID']['input'];
};


export type MutationUpdateWasteFinalArgs = {
  data: WasteFinalDateInput;
  wasteFinalId: Scalars['ID']['input'];
};


export type MutationUpdateZpoDetailArgs = {
  data: ZpoDetailInput;
  orderContentId: Scalars['Int']['input'];
};


export type MutationUpsertContainerWasteTypeArgs = {
  capacity: Scalars['Float']['input'];
  containerId: Scalars['Int']['input'];
  productId: Scalars['Int']['input'];
};


export type MutationUpsertProductContainerArgs = {
  data: ProductContainerInputArgs;
  productContainerId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpsertProductContainerCapacityArgs = {
  containerCapacityId?: InputMaybe<Scalars['ID']['input']>;
  data: ProductContainerCapacityInputArgs;
};


export type MutationUpsertProductMaterialArgs = {
  data: ProductMaterialInputArgs;
  productMaterialId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpsertProductWasteArgs = {
  data: ProductWasteInputArgs;
  productWasteId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpsertSellerConcretePriceListArgs = {
  branchServiceId: Scalars['ID']['input'];
  data: SupplierServiceConcreteSellerInput;
};


export type MutationUpsertSupplierBranchArgs = {
  data: SupplierBranchInputArgs;
  id?: InputMaybe<Scalars['ID']['input']>;
  supplierId: Scalars['String']['input'];
};


export type MutationUpsertSupplierMaterialPriceListArgs = {
  branchServiceId: Scalars['ID']['input'];
  data: SupplierMaterialPriceListInputArgs;
  materialPriceListId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpsertSupplierServiceLoadTransportArgs = {
  branchServiceId: Scalars['ID']['input'];
  data: ServiceLoadTransportInput;
};


export type MutationUpsertSupplierTerminalPriceListArgs = {
  branchServiceId: Scalars['ID']['input'];
  data: SupplierTerminalPriceListInputArgs;
  terminalPriceListId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpsertSupplierWasteCollectionFlatRateArgs = {
  branchServiceId: Scalars['ID']['input'];
  data: SupplierWasteCollectionFlatRateInputArgs;
};

export type MutationStatus = {
  __typename?: 'MutationStatus';
  reason?: Maybe<Scalars['String']['output']>;
  result: ResultEnum;
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  disableUnpaidOrderNotification?: Maybe<Scalars['Boolean']['output']>;
};

export type NotificationSettingsInput = {
  disableUnpaidOrderNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrderCommentsEntity = {
  __typename?: 'OrderCommentsEntity';
  comment: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  login: LoginEntity;
  loginId: Scalars['Int']['output'];
  order: OrderEntity;
  orderId: Scalars['Int']['output'];
};

export type OrderContentInput = {
  adminNote?: InputMaybe<Scalars['String']['input']>;
  arriveTime: Scalars['String']['input'];
  codToCollect?: InputMaybe<Scalars['Float']['input']>;
  collectionTime?: InputMaybe<Scalars['String']['input']>;
  containerCount?: InputMaybe<Scalars['Int']['input']>;
  containerFromDate?: InputMaybe<Scalars['String']['input']>;
  containerId?: InputMaybe<Scalars['Int']['input']>;
  containerToDate?: InputMaybe<Scalars['String']['input']>;
  customerNote?: InputMaybe<Scalars['String']['input']>;
  distanceTotalKm?: InputMaybe<Scalars['Float']['input']>;
  onsiteContactFirstname?: InputMaybe<Scalars['String']['input']>;
  onsiteContactLastname?: InputMaybe<Scalars['String']['input']>;
  onsiteContactPhone?: InputMaybe<Scalars['String']['input']>;
  orderContentPriceItems?: InputMaybe<Array<InputMaybe<OrderContentPricesInput>>>;
  productId: Scalars['Int']['input'];
  serviceType?: InputMaybe<ServiceTypeEnum>;
  supplierId: Scalars['Int']['input'];
  supplierNote?: InputMaybe<Scalars['String']['input']>;
  supplierProfitWoVatKc?: InputMaybe<Scalars['Float']['input']>;
  terminalId?: InputMaybe<Scalars['Int']['input']>;
  totalForContentWoVatKc?: InputMaybe<Scalars['Float']['input']>;
  wasteDescription?: InputMaybe<Scalars['String']['input']>;
  weightTonnes?: InputMaybe<Scalars['Float']['input']>;
};

export type OrderContentListResult = {
  __typename?: 'OrderContentListResult';
  items: Array<OrderContentsResult>;
  total: Scalars['Int']['output'];
};

export type OrderContentPriceItem = {
  __typename?: 'OrderContentPriceItem';
  id: Scalars['Int']['output'];
  incomeForSupplier: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  partOfTotalPrice: Scalars['Boolean']['output'];
  sysName: Scalars['String']['output'];
  uiOrder: Scalars['Int']['output'];
};

export type OrderContentPrices = {
  __typename?: 'OrderContentPrices';
  id: Scalars['Int']['output'];
  orderContentPriceItem: OrderContentPriceItem;
  priceWoVatKc: Scalars['Float']['output'];
};

export type OrderContentPricesInput = {
  orderContentPriceItemSys?: InputMaybe<Scalars['String']['input']>;
  priceWoVatKc?: InputMaybe<Scalars['Float']['input']>;
};

export type OrderContentStateHistory = {
  __typename?: 'OrderContentStateHistory';
  id: Scalars['Int']['output'];
  nextState: OrderContentStateType;
  stateReason?: Maybe<Scalars['String']['output']>;
  supplier?: Maybe<Supplier>;
  timestamp: Scalars['String']['output'];
};

export type OrderContentStateType = {
  __typename?: 'OrderContentStateType';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  sysName: Scalars['String']['output'];
};

export type OrderContentsResult = {
  __typename?: 'OrderContentsResult';
  adminNote?: Maybe<Scalars['String']['output']>;
  approvedAt?: Maybe<Scalars['String']['output']>;
  approvedBy?: Maybe<Login>;
  approvedById?: Maybe<Scalars['Int']['output']>;
  arriveTime: Scalars['String']['output'];
  codToCollect?: Maybe<Scalars['Float']['output']>;
  collectionTime?: Maybe<Scalars['String']['output']>;
  container?: Maybe<Container>;
  containerCount?: Maybe<Scalars['Int']['output']>;
  containerFromDate: Scalars['String']['output'];
  containerToDate?: Maybe<Scalars['String']['output']>;
  customerNote?: Maybe<Scalars['String']['output']>;
  distanceTotalKm?: Maybe<Scalars['Float']['output']>;
  filesCount?: Maybe<Scalars['Int']['output']>;
  hasWasteFinals?: Maybe<Scalars['Boolean']['output']>;
  hasZpoDelivered?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  isCancellableByAdmin?: Maybe<Scalars['Boolean']['output']>;
  onsiteContactFirstname?: Maybe<Scalars['String']['output']>;
  onsiteContactLastname?: Maybe<Scalars['String']['output']>;
  onsiteContactPhone?: Maybe<Scalars['String']['output']>;
  order: OrderResult;
  orderContentPrices?: Maybe<Array<OrderContentPrices>>;
  orderContentState: OrderContentStateType;
  orderContentStateReason?: Maybe<Scalars['String']['output']>;
  product: Product;
  serviceType?: Maybe<ServiceTypeEnum>;
  supplier?: Maybe<Supplier>;
  supplierNote?: Maybe<Scalars['String']['output']>;
  supplierProfitOriginalWoVatKc?: Maybe<Scalars['Float']['output']>;
  supplierProfitWoVatKc?: Maybe<Scalars['Float']['output']>;
  terminal?: Maybe<Terminal>;
  totalForContentWoVatKc?: Maybe<Scalars['Float']['output']>;
  wasteDescription?: Maybe<Scalars['String']['output']>;
  weightTonnes?: Maybe<Scalars['Float']['output']>;
};

export type OrderDataInput = {
  adminNote?: InputMaybe<Scalars['String']['input']>;
  b2bCustomerId?: InputMaybe<Scalars['Int']['input']>;
  city: Scalars['String']['input'];
  companyCountryIso: Scalars['String']['input'];
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyResidence?: InputMaybe<AddressInput>;
  dic?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstname: Scalars['String']['input'];
  gps: GpsCoordinations;
  ico?: InputMaybe<Scalars['String']['input']>;
  invoicingNote?: InputMaybe<Scalars['String']['input']>;
  invoicingNoteDone?: InputMaybe<Scalars['Boolean']['input']>;
  lastname: Scalars['String']['input'];
  notificationSettings?: InputMaybe<NotificationSettingsInput>;
  orderContents?: InputMaybe<Array<InputMaybe<OrderContentInput>>>;
  orderNote?: InputMaybe<Scalars['String']['input']>;
  paymentType: PaymentType;
  phone: Scalars['String']['input'];
  street: Scalars['String']['input'];
  streetNumber: Scalars['String']['input'];
  targetCompanyIco?: InputMaybe<Scalars['String']['input']>;
  targetCompanyName?: InputMaybe<Scalars['String']['input']>;
  totalWoVatKc?: InputMaybe<Scalars['Float']['input']>;
  zip: Scalars['String']['input'];
};

export type OrderDeliverySettings = {
  __typename?: 'OrderDeliverySettings';
  allowOrdersForToday: Scalars['Boolean']['output'];
  requiredDaysBeforeOrderStart: Scalars['Int']['output'];
  shiftOrderPossibleStartByOneFromTodayTime: Scalars['String']['output'];
  todayDeliveryAvailableTillTime: Scalars['String']['output'];
};

export type OrderDeliverySettingsInput = {
  allowOrdersForToday: Scalars['Boolean']['input'];
  requiredDaysBeforeOrderStart: Scalars['Int']['input'];
  shiftOrderPossibleStartByOneFromTodayTime: Scalars['String']['input'];
  todayDeliveryAvailableTillTime: Scalars['String']['input'];
};

export type OrderEntity = {
  __typename?: 'OrderEntity';
  addressSearch: Scalars['String']['output'];
  city: Scalars['String']['output'];
  companyCountryIso: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  dic: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  ico: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDraft: Scalars['Boolean']['output'];
  lastname: Scalars['String']['output'];
  nameSearch: Scalars['String']['output'];
  orderNumber: Scalars['Int']['output'];
  orderNumberSearch: Scalars['String']['output'];
  street: Scalars['String']['output'];
  streetNumber: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type OrderLocksEntity = {
  __typename?: 'OrderLocksEntity';
  id: Scalars['ID']['output'];
  lockedAt: Scalars['DateTimeISO']['output'];
  lockedByLogin: LoginEntity;
  lockedByLoginId: Scalars['Int']['output'];
  lockedUntil: Scalars['DateTimeISO']['output'];
  order: OrderEntity;
  orderContentId: Scalars['Int']['output'];
  orderId: Scalars['Int']['output'];
};

export type OrderResult = {
  __typename?: 'OrderResult';
  accountingDocuments?: Maybe<Array<AccountingDocument>>;
  adminOwner?: Maybe<Login>;
  b2bCustomerId?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyCountryIso: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  companyResidence?: Maybe<AddressType>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Login>;
  createdById?: Maybe<Scalars['Int']['output']>;
  customer?: Maybe<Customer>;
  dic?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  goPayPaymentStatus?: Maybe<Scalars['String']['output']>;
  goPayTransactionId?: Maybe<Scalars['String']['output']>;
  gps: Point;
  ico?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  invoicingNote?: Maybe<Scalars['String']['output']>;
  invoicingNoteDone?: Maybe<Scalars['Boolean']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  notificationSettings?: Maybe<NotificationSettings>;
  orderContent?: Maybe<OrderContentsResult>;
  orderNote?: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  partnerSource?: Maybe<Scalars['String']['output']>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentType?: Maybe<PaymentType>;
  phone?: Maybe<Scalars['String']['output']>;
  refundedKc?: Maybe<Scalars['Float']['output']>;
  requireCertificateState: Scalars['Int']['output'];
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  targetCompanyIco?: Maybe<Scalars['String']['output']>;
  targetCompanyName?: Maybe<Scalars['String']['output']>;
  totalInclVatKc: Scalars['Float']['output'];
  totalInclVatRoundingKc?: Maybe<Scalars['Float']['output']>;
  totalWoVatKc: Scalars['Float']['output'];
  unpaidNotificationSent?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Login>;
  uuid: Scalars['String']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export enum PairingStatusEnum {
  ErrorProcessingPayment = 'ERROR_PROCESSING_PAYMENT',
  Matched = 'MATCHED',
  MatchedAndPaid = 'MATCHED_AND_PAID',
  NotProcessed = 'NOT_PROCESSED',
  OrderAlreadyPaid = 'ORDER_ALREADY_PAID',
  OrderHasAdvancePaymentPaymentType = 'ORDER_HAS_ADVANCE_PAYMENT_PAYMENT_TYPE',
  OrderNotFound = 'ORDER_NOT_FOUND',
  ProblematicalWoGopay = 'PROBLEMATICAL_WO_GOPAY',
  TransactionHasDifferentAmountPaid = 'TRANSACTION_HAS_DIFFERENT_AMOUNT_PAID',
  TransactionHasNoOrderNumber = 'TRANSACTION_HAS_NO_ORDER_NUMBER'
}

export enum PaymentActionEnum {
  CancelPaymentAdvanceOrderPayment = 'CancelPaymentAdvanceOrderPayment',
  ChangePaymentMethod = 'ChangePaymentMethod',
  ChangePaymentStatus = 'ChangePaymentStatus',
  CreateAdditionalInvoice = 'CreateAdditionalInvoice',
  CreateIncomeReceipt = 'CreateIncomeReceipt',
  CreateNewGoPayPayment = 'CreateNewGoPayPayment',
  CreateNewInvoice = 'CreateNewInvoice',
  LinkVyfakturujInvoice = 'LinkVyfakturujInvoice',
  MarkOrderAsPaid = 'MarkOrderAsPaid',
  OrderNotPaidEmailToClient = 'OrderNotPaidEmailToClient',
  ProcessOrderPayment = 'ProcessOrderPayment',
  Refund = 'Refund',
  RefundEmail2Customer = 'RefundEmail2Customer',
  SendInvoiceByEmail = 'SendInvoiceByEmail',
  SendPaymentInstructionEmail = 'SendPaymentInstructionEmail',
  UnlinkInvoice = 'UnlinkInvoice'
}

export enum PaymentMethodEnum {
  AdvancePayment = 'ADVANCE_PAYMENT',
  BankTransfer = 'BANK_TRANSFER',
  Cod = 'COD',
  Invoice = 'INVOICE',
  InvoiceAdvance = 'INVOICE_ADVANCE',
  PaymentGw = 'PAYMENT_GW',
  ProformaInvoice = 'PROFORMA_INVOICE'
}

export enum PaymentStatus {
  Paid = 'PAID',
  Refunded = 'REFUNDED',
  Unpaid = 'UNPAID'
}

export enum PaymentType {
  AdvancePayment = 'ADVANCE_PAYMENT',
  BankTransfer = 'BANK_TRANSFER',
  Cod = 'COD',
  Invoice = 'INVOICE',
  InvoiceAdvance = 'INVOICE_ADVANCE',
  PaymentGw = 'PAYMENT_GW',
  ProformaInvoice = 'PROFORMA_INVOICE'
}

export type Point = {
  __typename?: 'Point';
  coordinates: Array<Scalars['Float']['output']>;
};

export type PriceBreakdownItemEntity = {
  __typename?: 'PriceBreakdownItemEntity';
  id: Scalars['ID']['output'];
  isMetrakMarginApplicable: Scalars['Boolean']['output'];
  isPartOfSubtotalPrice: Scalars['Boolean']['output'];
  isPartOfTotalPrice: Scalars['Boolean']['output'];
  isTransportCost: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  priceType: Scalars['Int']['output'];
  sysName: Scalars['String']['output'];
  uiOrder: Scalars['Int']['output'];
};

export type PriceCalculatorResponse = {
  __typename?: 'PriceCalculatorResponse';
  calculationOptions: Array<CalculationOptionResponse>;
  container: ProductContainerEntity;
  supplierBranch: SupplierBranchEntity;
};

export type PricePack = {
  __typename?: 'PricePack';
  amount: Scalars['Float']['output'];
  note?: Maybe<Scalars['String']['output']>;
  priceItemType: PriceBreakdownItemEntity;
};

export type PriceResult = {
  __typename?: 'PriceResult';
  additionalFees?: Maybe<Array<Maybe<AdditionalFeeItemOrder>>>;
  containerPrices?: Maybe<Array<Maybe<ContainerPrice>>>;
  totalDiscountInclVat?: Maybe<Scalars['Float']['output']>;
  totalDiscountWoVat?: Maybe<Scalars['Float']['output']>;
  totalPriceKcInclVat?: Maybe<Scalars['Float']['output']>;
  totalPriceKcWoVat?: Maybe<Scalars['Float']['output']>;
};

export enum ProcessingStatusEnum {
  Approved = 'APPROVED',
  NewByPaymentService = 'NEW_BY_PAYMENT_SERVICE'
}

export type Product = {
  __typename?: 'Product';
  acceptedWaste?: Maybe<Scalars['String']['output']>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  fraction?: Maybe<Material>;
  id: Scalars['Int']['output'];
  ignoreContainerRentPrice?: Maybe<Scalars['Boolean']['output']>;
  isCollection?: Maybe<Scalars['Boolean']['output']>;
  isDelivery?: Maybe<Scalars['Boolean']['output']>;
  isForPublic?: Maybe<Scalars['Boolean']['output']>;
  margin?: Maybe<Scalars['Float']['output']>;
  material?: Maybe<Material>;
  name: Scalars['String']['output'];
  tonToCubes?: Maybe<Scalars['Float']['output']>;
  tooltip?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Material>;
  uiOrder?: Maybe<Scalars['Int']['output']>;
  uiPicture?: Maybe<Scalars['String']['output']>;
  unsupportedWaste?: Maybe<Scalars['String']['output']>;
  wasteDescription?: Maybe<Scalars['String']['output']>;
  wasteType?: Maybe<WasteType>;
};

export type ProductConcreteComponentEntity = {
  __typename?: 'ProductConcreteComponentEntity';
  cubicToTonnes?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  isForPublic: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  tooltip?: Maybe<Scalars['String']['output']>;
  type: ConcreteComponentEnum;
  uiOrder: Scalars['Float']['output'];
  uiPicture?: Maybe<Scalars['String']['output']>;
};

export type ProductConcreteComponentInputArgs = {
  cubicToTonnes?: InputMaybe<Scalars['Float']['input']>;
  isForPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  type: ConcreteComponentEnum;
  uiOrder: Scalars['Int']['input'];
};

export type ProductContainerCapacityEntity = {
  __typename?: 'ProductContainerCapacityEntity';
  calcCapacityTonne?: Maybe<Scalars['Float']['output']>;
  capacityTonne?: Maybe<Scalars['Float']['output']>;
  container?: Maybe<ProductContainerEntity>;
  containerId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  waste?: Maybe<ProductWasteEntity>;
  wasteId: Scalars['ID']['output'];
};

export type ProductContainerCapacityInputArgs = {
  calcCapacityTonne?: InputMaybe<Scalars['Float']['input']>;
  capacityTonne?: InputMaybe<Scalars['Float']['input']>;
  containerId: Scalars['ID']['input'];
  wasteId: Scalars['ID']['input'];
};

export type ProductContainerEntity = {
  __typename?: 'ProductContainerEntity';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  tooltip: Scalars['String']['output'];
  uiOrder: Scalars['Int']['output'];
  uiPicture: Scalars['String']['output'];
  volumeM3?: Maybe<Scalars['Float']['output']>;
};

export type ProductContainerInputArgs = {
  name: Scalars['String']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  uiOrder: Scalars['Float']['input'];
  uiPicture?: InputMaybe<Scalars['String']['input']>;
  volumeM3: Scalars['Float']['input'];
};

export type ProductDataInput = {
  acceptedWaste?: InputMaybe<Scalars['String']['input']>;
  disabled: Scalars['Boolean']['input'];
  fractionId?: InputMaybe<Scalars['Int']['input']>;
  ignoreContainerRentPrice?: InputMaybe<Scalars['Boolean']['input']>;
  isForPublic?: InputMaybe<Scalars['Boolean']['input']>;
  margin?: InputMaybe<Scalars['Float']['input']>;
  materialId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  tonToCubes?: InputMaybe<Scalars['Float']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
  uiOrder?: InputMaybe<Scalars['Int']['input']>;
  uiPicture?: InputMaybe<Scalars['String']['input']>;
  unsupportedWaste?: InputMaybe<Scalars['String']['input']>;
  wasteDescription?: InputMaybe<Scalars['String']['input']>;
  wasteTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductMaterialComponentEntity = {
  __typename?: 'ProductMaterialComponentEntity';
  cubicToTonnes?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tooltip?: Maybe<Scalars['String']['output']>;
  type: MaterialTypeEnum;
  uiOrder: Scalars['Int']['output'];
  uiPicture?: Maybe<Scalars['String']['output']>;
};

export type ProductMaterialComponentInputArgs = {
  cubicToTonnes?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  tooltip?: InputMaybe<Scalars['String']['input']>;
  type: MaterialTypeEnum;
  uiOrder: Scalars['Int']['input'];
};

export type ProductMaterialEntity = {
  __typename?: 'ProductMaterialEntity';
  cubicToTonnes?: Maybe<Scalars['Float']['output']>;
  fractionComponent?: Maybe<ProductMaterialComponentEntity>;
  fractionId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isForPublic: Scalars['Boolean']['output'];
  materialComponent: ProductMaterialComponentEntity;
  materialId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  tooltip?: Maybe<Scalars['String']['output']>;
  typeComponent: ProductMaterialComponentEntity;
  typeId: Scalars['String']['output'];
  uiOrder: Scalars['Float']['output'];
  uiPicture?: Maybe<Scalars['String']['output']>;
};

export type ProductMaterialInputArgs = {
  cubicToTonnes?: InputMaybe<Scalars['Float']['input']>;
  fractionId?: InputMaybe<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
  isForPublic: Scalars['Boolean']['input'];
  materialId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  typeId: Scalars['String']['input'];
  uiOrder: Scalars['Float']['input'];
  uiPicture?: InputMaybe<Scalars['String']['input']>;
};

export enum ProductType {
  Collection = 'COLLECTION',
  Delivery = 'DELIVERY'
}

export type ProductWasteEntity = {
  __typename?: 'ProductWasteEntity';
  childWastes?: Maybe<Array<ProductWasteEntity>>;
  cubicToTonnes?: Maybe<Scalars['Float']['output']>;
  hasAdditivesSpecification: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isForPublic: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  parentWaste?: Maybe<ProductWasteEntity>;
  parentWasteId?: Maybe<Scalars['String']['output']>;
  tooltip?: Maybe<Scalars['String']['output']>;
  uiOrder: Scalars['Int']['output'];
  uiPicture?: Maybe<Scalars['String']['output']>;
  wasteCategory: WasteCategory;
  wasteCodeId: Scalars['Float']['output'];
  wasteType: WasteTypeEntity;
};

export type ProductWasteInputArgs = {
  cubicToTonnes?: InputMaybe<Scalars['Float']['input']>;
  isActive: Scalars['Boolean']['input'];
  isForPublic: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  parentWasteId?: InputMaybe<Scalars['ID']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  uiOrder: Scalars['Float']['input'];
  uiPicture?: InputMaybe<Scalars['String']['input']>;
  wasteCategory: WasteCategory;
  wasteCodeId: Scalars['Int']['input'];
};

export type Query = {
  __typename?: 'Query';
  accountingDocuments: Array<Maybe<AccountingDocument>>;
  accountingGetGroupOrders?: Maybe<Array<Maybe<AccountingGroupOrders>>>;
  accountingGroupInvoices: AccountingGroupInvoiceResult;
  adminOwners?: Maybe<Array<Maybe<Login>>>;
  advanceAccountInvoices: Array<AccountingDocumentEntity>;
  advanceAccountTransactions: Array<AccountingAdvanceAccountTransactionEntity>;
  b2bPriceLists?: Maybe<Array<Maybe<B2bPriceList>>>;
  calculateCollectionServiceOptions: Array<PriceCalculatorResponse>;
  calculateDeliveryPrice?: Maybe<CalculateDeliveryPriceAdminResult>;
  calculatePrice?: Maybe<CalculatePriceAdminResult>;
  checkAdvanceAccountAvailability: CheckAdvanceAccountAvailabilityResponse;
  checkAdvanceAccountStatus: CheckAdvanceAccountStatusResponseEnum;
  containerTypes?: Maybe<Array<Maybe<Container>>>;
  customerAdvanceAccounts: Array<AccountingAdvanceAccountEntity>;
  customerList: CustomerListPaginatedResponse;
  deliveryPriceLists?: Maybe<Array<Maybe<DeliveryPriceList>>>;
  emailHistory?: Maybe<Array<Maybe<EmailHistory>>>;
  /** Finds info about company by ICO */
  findByCompanyId: FindByCompanyIdResponse;
  /** Finds ICZ company by ICO */
  findIcz?: Maybe<Scalars['String']['output']>;
  getAvailableResourcesForWasteCollection: GetAvailableResourcesForWasteCollectionResponse;
  getAvailableServicesForLocality: GetAvailableServicesForLocalityResponse;
  getBranchListByService: Array<SupplierBranchEntity>;
  getBranchListByTransportCategory: Array<SupplierBranchEntity>;
  getContainerFromToDates?: Maybe<FromToDatesResult>;
  getDownloadUrl?: Maybe<Scalars['String']['output']>;
  getFioSyncLatestResult?: Maybe<FioSyncLatestResult>;
  getFioSyncSettings?: Maybe<FioSyncSettings>;
  getFioTransactionList?: Maybe<FioTransactionListResult>;
  getFioTransactionStateStats?: Maybe<Array<Maybe<FioTransactionStateStat>>>;
  getOrderComments: Array<OrderCommentsEntity>;
  getOrderLock?: Maybe<OrderLocksEntity>;
  getResponses?: Maybe<UserResponses>;
  getTerminalsWithWasteCategory: Array<SupplierBranchEntity>;
  getWasteOriginDescSuggestions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getZpoDetail?: Maybe<ZpoDetail>;
  listOrderContentFiles?: Maybe<Array<Maybe<FileList>>>;
  listOrp?: Maybe<Array<Maybe<ListOrp>>>;
  listZuj?: Maybe<Array<Maybe<ListZuj>>>;
  logins?: Maybe<Array<Maybe<Login>>>;
  loginsCount?: Maybe<Scalars['Int']['output']>;
  materials?: Maybe<Array<Maybe<Material>>>;
  myLockedOrders: Array<OrderLocksEntity>;
  orderChangeLog: Array<DemandChangeLogEntity>;
  orderContentList?: Maybe<OrderContentListResult>;
  orderContentPriceItems?: Maybe<Array<Maybe<OrderContentPriceItem>>>;
  orderContentStateHistory: Array<OrderContentStateHistory>;
  orderContentStates?: Maybe<Array<Maybe<OrderContentStateType>>>;
  orderContentStatesStat?: Maybe<Array<Maybe<StateStatType>>>;
  orderDetail: OrderContentsResult;
  productConcreteComponents: Array<ProductConcreteComponentEntity>;
  productContainerCapacity: Array<ProductContainerCapacityEntity>;
  productContainerList: Array<ProductContainerEntity>;
  productMaterialComponents: Array<ProductMaterialComponentEntity>;
  productMaterialList: Array<ProductMaterialEntity>;
  productWasteList: Array<ProductWasteEntity>;
  productWasteListFlat: Array<ProductWasteEntity>;
  products?: Maybe<Array<Maybe<Product>>>;
  productsCount?: Maybe<Scalars['Int']['output']>;
  searchWholeDb?: Maybe<Array<Maybe<SearchWholeDbResult>>>;
  statisticsContainers?: Maybe<Array<Maybe<StatisticsContainers>>>;
  statisticsOverview?: Maybe<Array<Maybe<StatisticsOverview>>>;
  statisticsProducts?: Maybe<Array<Maybe<StatisticsGeneral>>>;
  statisticsProductsTurnover?: Maybe<Array<Maybe<StatisticsTurnover>>>;
  statisticsStates?: Maybe<Array<Maybe<StatisticsGeneral>>>;
  statisticsSuppliers?: Maybe<Array<Maybe<StatisticsGeneral>>>;
  statisticsTotal?: Maybe<StatisticsTotal>;
  suggestIcz?: Maybe<Array<Maybe<IczSuggest>>>;
  suggestPou: Array<ListPouEntity>;
  suggestPouFromCity: ListPouEntity;
  suggestZpoData?: Maybe<ZpoSuggestion>;
  supplierBranchList: SupplierBranchListResponse;
  supplierContainerList: Array<SupplierContainerEntity>;
  supplierMaterialPriceLists: Array<SupplierMaterialPriceListEntity>;
  supplierPriceList?: Maybe<Array<Maybe<SupplierPriceList>>>;
  supplierServiceConcretePriceList: Array<SupplierConcretePriceListEntity>;
  supplierServiceLoadTransport: Array<SupplierTruckEntity>;
  supplierSupplierList: SupplierSupplierListResponse;
  supplierTerminalPriceLists: Array<SupplierTerminalPriceListEntity>;
  supplierTransportSupplierLink: Array<SupplierTransportSupplierLinkEntity>;
  supplierWasteCollectionFlatRates: Array<Maybe<SupplierWasteCollectionFlatResponse>>;
  supplierWasteCollectionLinks: Array<SupplierWasteCollectionSupplierLinkEntity>;
  suppliers?: Maybe<Array<Maybe<Supplier>>>;
  suppliers2: SupplierList;
  suppliersCount?: Maybe<Scalars['Int']['output']>;
  suppliersIdsWithTypes?: Maybe<Array<Maybe<SupplierIdWithTypes>>>;
  terminalPriceList?: Maybe<Array<Maybe<TerminalPriceList>>>;
  terminals?: Maybe<Array<Maybe<Terminal>>>;
  terminalsCount?: Maybe<Scalars['Int']['output']>;
  wasteFinals?: Maybe<Array<Maybe<WasteFinal>>>;
  wasteTypes?: Maybe<Array<Maybe<WasteType>>>;
};


export type QueryAccountingDocumentsArgs = {
  orderId: Scalars['Int']['input'];
};


export type QueryAccountingGetGroupOrdersArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  isDek?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAccountingGroupInvoicesArgs = {
  createdDateFrom?: InputMaybe<Scalars['String']['input']>;
  createdDateTo?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<Scalars['String']['input']>;
  emailSent?: InputMaybe<Scalars['String']['input']>;
  externalReference?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  variableSymbol?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAdvanceAccountInvoicesArgs = {
  advanceAccountId: Scalars['ID']['input'];
};


export type QueryAdvanceAccountTransactionsArgs = {
  advanceAccountId: Scalars['ID']['input'];
};


export type QueryB2bPriceListsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCalculateCollectionServiceOptionsArgs = {
  allowNearestLargerContainer: Scalars['String']['input'];
  calculationDate: Scalars['String']['input'];
  containerId: Scalars['ID']['input'];
  containerRentLengthDays: Scalars['Int']['input'];
  customContainerCapacity?: InputMaybe<Scalars['Float']['input']>;
  customerLatLng: LatLngInput;
  productId: Scalars['ID']['input'];
  radiusKm: Scalars['Int']['input'];
  routingStrategy: Scalars['String']['input'];
  wasteCategory: WasteCategory;
};


export type QueryCalculateDeliveryPriceArgs = {
  b2bCustomerId?: InputMaybe<Scalars['Int']['input']>;
  cod?: InputMaybe<Scalars['Boolean']['input']>;
  deliveryDate?: InputMaybe<Scalars['String']['input']>;
  forcedSupplierId?: InputMaybe<Scalars['Int']['input']>;
  locality: GpsCoordinations;
  productId: Scalars['Int']['input'];
  weightTonnes: Scalars['Float']['input'];
};


export type QueryCalculatePriceArgs = {
  b2bCustomerId?: InputMaybe<Scalars['Int']['input']>;
  cod: Scalars['Boolean']['input'];
  containers?: InputMaybe<Array<RequestedContainer>>;
  forcedSupplierId?: InputMaybe<Scalars['Int']['input']>;
  locality: GpsCoordinations;
};


export type QueryCheckAdvanceAccountAvailabilityArgs = {
  customerId: Scalars['Int']['input'];
  gps: GpsCoordinationInput;
  orderTotalKc?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryCheckAdvanceAccountStatusArgs = {
  orderId: Scalars['ID']['input'];
};


export type QueryCustomerAdvanceAccountsArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryCustomerListArgs = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  flags?: InputMaybe<Array<CustomerFilterFlagsEnum>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['String']['input']>;
  isResellCompany?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDeliveryPriceListsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryEmailHistoryArgs = {
  orderContentId: Scalars['Int']['input'];
};


export type QueryFindByCompanyIdArgs = {
  companyId: Scalars['String']['input'];
};


export type QueryFindIczArgs = {
  companyId: Scalars['String']['input'];
  street: Scalars['String']['input'];
};


export type QueryGetAvailableResourcesForWasteCollectionArgs = {
  calculationDate: Scalars['String']['input'];
  customerLatLng: LatLngInput;
  productId?: InputMaybe<Scalars['ID']['input']>;
  radiusKm: Scalars['Int']['input'];
  wasteCategory: WasteCategory;
};


export type QueryGetAvailableServicesForLocalityArgs = {
  calculationDate: Scalars['String']['input'];
  customerLatLng: LatLngInput;
  radiusKm: Scalars['Int']['input'];
};


export type QueryGetBranchListByServiceArgs = {
  serviceCategory: ServiceCategory;
};


export type QueryGetBranchListByTransportCategoryArgs = {
  transportCategory: TransportCategory;
};


export type QueryGetDownloadUrlArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetFioTransactionListArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pairingStatus?: InputMaybe<PairingStatusEnum>;
  payerName?: InputMaybe<Scalars['String']['input']>;
  processingStatus?: InputMaybe<ProcessingStatusEnum>;
  transactionDateFrom?: InputMaybe<Scalars['String']['input']>;
  transactionDateTo?: InputMaybe<Scalars['String']['input']>;
  variableSymbol?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetOrderCommentsArgs = {
  orderId: Scalars['Int']['input'];
};


export type QueryGetOrderLockArgs = {
  orderId: Scalars['Int']['input'];
};


export type QueryGetTerminalsWithWasteCategoryArgs = {
  wasteCategory: WasteCategory;
};


export type QueryGetZpoDetailArgs = {
  orderContentId: Scalars['Int']['input'];
};


export type QueryListOrderContentFilesArgs = {
  orderContentId: Scalars['Int']['input'];
};


export type QueryLoginsArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isMasterAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLoginsCountArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrderChangeLogArgs = {
  orderId: Scalars['Int']['input'];
};


export type QueryOrderContentListArgs = {
  adminOwner?: InputMaybe<Scalars['String']['input']>;
  b2bCustomerId?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  containerFromDate?: InputMaybe<Scalars['String']['input']>;
  containerId?: InputMaybe<Scalars['String']['input']>;
  containerToDate?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  createdAtFrom?: InputMaybe<Scalars['String']['input']>;
  createdAtTo?: InputMaybe<Scalars['String']['input']>;
  flags?: InputMaybe<FlagEnum>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  partnerSource?: InputMaybe<Scalars['String']['input']>;
  paymentStatus?: InputMaybe<Scalars['String']['input']>;
  paymentType?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  realisedAtFrom?: InputMaybe<Scalars['String']['input']>;
  realisedAtTo?: InputMaybe<Scalars['String']['input']>;
  serviceType?: InputMaybe<ServiceTypeEnum>;
  state?: InputMaybe<Scalars['String']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrderContentStateHistoryArgs = {
  orderContentId: Scalars['Int']['input'];
};


export type QueryOrderDetailArgs = {
  orderContentId?: InputMaybe<Scalars['Int']['input']>;
  orderId?: InputMaybe<Scalars['Int']['input']>;
  orderNumber?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductContainerCapacityArgs = {
  containerId?: InputMaybe<Scalars['ID']['input']>;
  wasteId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProductMaterialComponentsArgs = {
  type?: InputMaybe<MaterialTypeEnum>;
};


export type QueryProductMaterialListArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProductWasteListArgs = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  wasteCategory?: InputMaybe<WasteCategory>;
};


export type QueryProductWasteListFlatArgs = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  wasteCategory?: InputMaybe<WasteCategory>;
};


export type QueryProductsArgs = {
  disabled?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCollection?: InputMaybe<Scalars['String']['input']>;
  isDelivery?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  wasteTypeId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductsCountArgs = {
  disabled?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCollection?: InputMaybe<Scalars['String']['input']>;
  isDelivery?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  wasteTypeId?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchWholeDbArgs = {
  searchString?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStatisticsContainersArgs = {
  fromDate?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStatisticsOverviewArgs = {
  fromDate?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStatisticsProductsArgs = {
  fromDate?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStatisticsProductsTurnoverArgs = {
  fromDate?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStatisticsStatesArgs = {
  fromDate?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStatisticsSuppliersArgs = {
  fromDate?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStatisticsTotalArgs = {
  fromDate?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySuggestIczArgs = {
  icz: Scalars['String']['input'];
};


export type QuerySuggestPouArgs = {
  search: Scalars['String']['input'];
};


export type QuerySuggestPouFromCityArgs = {
  city: Scalars['String']['input'];
};


export type QuerySuggestZpoDataArgs = {
  orderContentId: Scalars['Int']['input'];
};


export type QuerySupplierBranchListArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerySupplierContainerListArgs = {
  supplierBranchId: Scalars['ID']['input'];
};


export type QuerySupplierMaterialPriceListsArgs = {
  branchServiceId: Scalars['ID']['input'];
};


export type QuerySupplierPriceListArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySupplierServiceConcretePriceListArgs = {
  branchServiceId: Scalars['ID']['input'];
};


export type QuerySupplierServiceLoadTransportArgs = {
  supplierBranchId: Scalars['ID']['input'];
};


export type QuerySupplierSupplierListArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  serviceCategories?: InputMaybe<Array<ServiceCategory>>;
  serviceCategory?: InputMaybe<ServiceCategory>;
};


export type QuerySupplierTerminalPriceListsArgs = {
  branchServiceId: Scalars['ID']['input'];
  wasteCategory?: InputMaybe<WasteCategory>;
};


export type QuerySupplierTransportSupplierLinkArgs = {
  branchServiceId: Scalars['ID']['input'];
  transportCategory: TransportCategory;
};


export type QuerySupplierWasteCollectionFlatRatesArgs = {
  branchServiceId: Scalars['ID']['input'];
};


export type QuerySupplierWasteCollectionLinksArgs = {
  branchServiceId: Scalars['ID']['input'];
  wasteCategory: WasteCategory;
};


export type QuerySuppliersArgs = {
  disabled?: InputMaybe<Scalars['String']['input']>;
  flags?: InputMaybe<SupplierFlagEnum>;
  id?: InputMaybe<Scalars['String']['input']>;
  isMigrated?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orpName?: InputMaybe<Scalars['String']['input']>;
  regionCode?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySuppliers2Args = {
  aroundCustomerGps?: InputMaybe<GpsCoordinations>;
  disabled?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  operationType?: InputMaybe<SupplierOperationType>;
  orpName?: InputMaybe<Scalars['String']['input']>;
  withProductId?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySuppliersCountArgs = {
  disabled?: InputMaybe<Scalars['String']['input']>;
  flags?: InputMaybe<SupplierFlagEnum>;
  id?: InputMaybe<Scalars['String']['input']>;
  isMigrated?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orpName?: InputMaybe<Scalars['String']['input']>;
  regionCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTerminalPriceListArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTerminalsArgs = {
  disabled?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTerminalsCountArgs = {
  disabled?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryWasteFinalsArgs = {
  orderContentId: Scalars['Int']['input'];
};

export type RequestedContainer = {
  arriveTime?: InputMaybe<Scalars['String']['input']>;
  collectionTime?: InputMaybe<Scalars['String']['input']>;
  containerId: Scalars['Int']['input'];
  count: Scalars['Int']['input'];
  fromDate?: InputMaybe<Scalars['String']['input']>;
  onsiteContactFirstname?: InputMaybe<Scalars['String']['input']>;
  onsiteContactLastname?: InputMaybe<Scalars['String']['input']>;
  onsiteContactPhone?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['Int']['input'];
  toDate?: InputMaybe<Scalars['String']['input']>;
};

export enum ResultEnum {
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

export type RouteSpecification = {
  __typename?: 'RouteSpecification';
  bestSupplierId: Scalars['Int']['output'];
  bestTerminalId?: Maybe<Scalars['Int']['output']>;
  distanceKm?: Maybe<Scalars['Float']['output']>;
};

export type SearchWholeDbResult = {
  __typename?: 'SearchWholeDbResult';
  id?: Maybe<Scalars['Int']['output']>;
  tableName?: Maybe<Scalars['String']['output']>;
};

export enum ServiceCategory {
  ConcreteSeller = 'CONCRETE_SELLER',
  LoadTransport = 'LOAD_TRANSPORT',
  MaterialSeller = 'MATERIAL_SELLER',
  TransportOfConcrete = 'TRANSPORT_OF_CONCRETE',
  TransportOfDekMachinery = 'TRANSPORT_OF_DEK_MACHINERY',
  TransportOfFuel = 'TRANSPORT_OF_FUEL',
  TransportOfMaterial = 'TRANSPORT_OF_MATERIAL',
  WasteCollectionAdr = 'WASTE_COLLECTION_ADR',
  WasteCollectionLiquid = 'WASTE_COLLECTION_LIQUID',
  WasteCollectionScrap = 'WASTE_COLLECTION_SCRAP',
  WasteCollectionSolid = 'WASTE_COLLECTION_SOLID',
  WasteTerminal = 'WASTE_TERMINAL'
}

export type ServiceLoadTransportInput = {
  capacityTonne?: InputMaybe<Scalars['Float']['input']>;
  hasHydraulicArm?: InputMaybe<Scalars['Boolean']['input']>;
  hydraulicArmPriceKc?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
  manipulationPriceKc?: InputMaybe<Scalars['Float']['input']>;
  minimumPriceOfTransport?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  pricePerKmKc: Scalars['Float']['input'];
  supportedCollectionServices: Array<WasteCategory>;
  supportedContainerTypes: Array<Scalars['String']['input']>;
  supportedDeliveryServices: Array<Scalars['String']['input']>;
  truckType: Scalars['String']['input'];
};

export enum ServiceTypeEnum {
  MaterialDelivery = 'MATERIAL_DELIVERY',
  WasteCollection = 'WASTE_COLLECTION'
}

export type StateStatType = {
  __typename?: 'StateStatType';
  contentStateName?: Maybe<Scalars['String']['output']>;
  contentStateSysName?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type StatisticsContainers = {
  __typename?: 'StatisticsContainers';
  capacityM3?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type StatisticsGeneral = {
  __typename?: 'StatisticsGeneral';
  count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type StatisticsOverview = {
  __typename?: 'StatisticsOverview';
  containersSum?: Maybe<Scalars['Int']['output']>;
  monthYear?: Maybe<Scalars['String']['output']>;
  ordersCount?: Maybe<Scalars['Int']['output']>;
  profitForMetrak?: Maybe<Scalars['Float']['output']>;
  supplierProfitWoVatKc?: Maybe<Scalars['Float']['output']>;
  turnover?: Maybe<Scalars['Float']['output']>;
};

export type StatisticsTotal = {
  __typename?: 'StatisticsTotal';
  codCount?: Maybe<Scalars['Int']['output']>;
  distanceTotalKm?: Maybe<Scalars['Float']['output']>;
  ordersByB2b?: Maybe<Scalars['Int']['output']>;
  totalOrders?: Maybe<Scalars['Int']['output']>;
};

export type StatisticsTurnover = {
  __typename?: 'StatisticsTurnover';
  name?: Maybe<Scalars['String']['output']>;
  turnover?: Maybe<Scalars['Float']['output']>;
};

export type SubjectInfoType = {
  __typename?: 'SubjectInfoType';
  city?: Maybe<Scalars['String']['output']>;
  countryIso?: Maybe<Scalars['String']['output']>;
  dic?: Maybe<Scalars['String']['output']>;
  ico?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  acceptCod?: Maybe<Scalars['Boolean']['output']>;
  allowWorkOnHolidays?: Maybe<Scalars['Boolean']['output']>;
  allowWorkOnSaturday?: Maybe<Scalars['Boolean']['output']>;
  allowWorkOnSunday?: Maybe<Scalars['Boolean']['output']>;
  autoApproveOrders?: Maybe<Scalars['Boolean']['output']>;
  capability?: Maybe<Array<Maybe<CapabilityEnum>>>;
  city?: Maybe<Scalars['String']['output']>;
  companyIdentification?: Maybe<Scalars['String']['output']>;
  companyTaxId?: Maybe<Scalars['String']['output']>;
  contactPerson?: Maybe<Scalars['String']['output']>;
  containerRentPriceFromDay?: Maybe<Scalars['Int']['output']>;
  containerRentPricePerDayKc?: Maybe<Scalars['Float']['output']>;
  deliveryDayThresholdMinutes?: Maybe<Scalars['Int']['output']>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expressDeliveryFeeKc?: Maybe<Scalars['Float']['output']>;
  expressDeliveryFromMinutes?: Maybe<Scalars['Int']['output']>;
  gps: Point;
  hasIcz?: Maybe<Scalars['Boolean']['output']>;
  iczNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  loginToSuppliers?: Maybe<Array<Maybe<LoginToSupplier>>>;
  migratedOn?: Maybe<Scalars['String']['output']>;
  migratedSupplierId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  normalDeliveryFromMinutes?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  operationRadiusM?: Maybe<Scalars['Int']['output']>;
  orpName?: Maybe<Scalars['String']['output']>;
  ourMarginPercent?: Maybe<Scalars['Float']['output']>;
  pathCalculationStrategy?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  priceLimitNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  priceListIsFinal?: Maybe<Scalars['Boolean']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  publicHolidayFeeKc?: Maybe<Scalars['Float']['output']>;
  regionCode?: Maybe<Scalars['Int']['output']>;
  residenceCity?: Maybe<Scalars['String']['output']>;
  residenceStreet?: Maybe<Scalars['String']['output']>;
  residenceStreetNumber?: Maybe<Scalars['String']['output']>;
  residenceZip?: Maybe<Scalars['String']['output']>;
  sendOrderAllEmails?: Maybe<Scalars['Boolean']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  transportFlatRate?: Maybe<Scalars['Float']['output']>;
  useFlatRatePriceList?: Maybe<Scalars['Boolean']['output']>;
  web?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
  zpoNote?: Maybe<Scalars['String']['output']>;
};

export type SupplierBranchEntity = {
  __typename?: 'SupplierBranchEntity';
  address?: Maybe<AddressEntity>;
  branchServices?: Maybe<Array<SupplierBranchServiceEntity>>;
  containers?: Maybe<Array<SupplierContainerEntity>>;
  defaultMetrakMargin: Scalars['Float']['output'];
  extraServices?: Maybe<Array<Scalars['String']['output']>>;
  gps: GpsCoordinationClass;
  icz?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
  orderDeliverySettings: OrderDeliverySettings;
  orderNotificationOptions?: Maybe<Array<Scalars['String']['output']>>;
  ordersContact?: Maybe<ContactType>;
  paymentCapabilities?: Maybe<Array<Scalars['String']['output']>>;
  pou?: Maybe<ListPouEntity>;
  setupIsFinal: Scalars['Boolean']['output'];
  specification?: Maybe<Scalars['String']['output']>;
  supplier?: Maybe<SupplierSupplierEntity>;
  useGpsAddressInput: Scalars['Boolean']['output'];
  vacations?: Maybe<Array<SupplierVacationType>>;
  workingHours?: Maybe<Array<SupplierWorkingHours>>;
  zpoNote?: Maybe<Scalars['String']['output']>;
};

export type SupplierBranchInputArgs = {
  address: AddressTypeInclGpsInput;
  defaultMetrakMargin: Scalars['Float']['input'];
  extraServices: Array<Scalars['String']['input']>;
  icz?: InputMaybe<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  orderDeliverySettings: OrderDeliverySettingsInput;
  orderNotificationOptions: Array<Scalars['String']['input']>;
  ordersContact?: InputMaybe<ContactTypeInput>;
  paymentCapabilities: Array<Scalars['String']['input']>;
  pouId?: InputMaybe<Scalars['String']['input']>;
  setupIsFinal: Scalars['Boolean']['input'];
  specification?: InputMaybe<Scalars['String']['input']>;
  useGpsAddressInput: Scalars['Boolean']['input'];
  vacations?: InputMaybe<Array<SupplierVacationInput>>;
  workingHours?: InputMaybe<Array<SupplierWorkingHoursInput>>;
  zpoNote?: InputMaybe<Scalars['String']['input']>;
};

export type SupplierBranchListResponse = {
  __typename?: 'SupplierBranchListResponse';
  items: Array<SupplierBranchEntity>;
  total: Scalars['Int']['output'];
};

export type SupplierBranchServiceEntity = {
  __typename?: 'SupplierBranchServiceEntity';
  higherPriority: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  metrakMargin: Scalars['Float']['output'];
  serviceCategory: ServiceCategory;
  settings?: Maybe<Scalars['JSONObject']['output']>;
  terminalPriceLists: Array<SupplierTerminalPriceListEntity>;
  trucks: Array<SupplierTruckEntity>;
  validFrom?: Maybe<Scalars['String']['output']>;
  validTo?: Maybe<Scalars['String']['output']>;
  wasteCollectionFlatRates: Array<SupplierWasteCollectionFlatRateEntity>;
  wasteCollectionSupplierLinks: Array<SupplierWasteCollectionSupplierLinkEntity>;
};

export type SupplierBranchServiceInputArgs = {
  higherPriority: Scalars['Boolean']['input'];
  isActive: Scalars['Boolean']['input'];
  metrakMargin: Scalars['Float']['input'];
  settings: AvailableServicesSettings;
  validFrom?: InputMaybe<Scalars['String']['input']>;
  validTo?: InputMaybe<Scalars['String']['input']>;
};

export type SupplierConcretePriceListEntity = {
  __typename?: 'SupplierConcretePriceListEntity';
  concreteComponent: ProductConcreteComponentEntity;
  concreteComponentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  priceForPublicKc?: Maybe<Scalars['Float']['output']>;
  priceKc?: Maybe<Scalars['Float']['output']>;
};

export type SupplierContainerEntity = {
  __typename?: 'SupplierContainerEntity';
  adrHandling?: Maybe<Scalars['Boolean']['output']>;
  adrSurchargeKc?: Maybe<Scalars['Float']['output']>;
  availableCount?: Maybe<Scalars['Int']['output']>;
  container: ProductContainerEntity;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isVirtualContainer?: Maybe<Scalars['Boolean']['output']>;
  rentPerDayKc: Scalars['Float']['output'];
};

export type SupplierContainerInputArgs = {
  adrHandling?: InputMaybe<Scalars['Boolean']['input']>;
  adrSurchargeKc?: InputMaybe<Scalars['Float']['input']>;
  availableCount?: InputMaybe<Scalars['Float']['input']>;
  containerId: Scalars['String']['input'];
  isVirtualContainer?: InputMaybe<Scalars['Boolean']['input']>;
  rentPerDayKc: Scalars['Float']['input'];
};

export type SupplierDataInput = {
  acceptCod?: InputMaybe<Scalars['Boolean']['input']>;
  allowWorkOnHolidays?: InputMaybe<Scalars['Boolean']['input']>;
  allowWorkOnSaturday?: InputMaybe<Scalars['Boolean']['input']>;
  allowWorkOnSunday?: InputMaybe<Scalars['Boolean']['input']>;
  autoApproveOrders: Scalars['Boolean']['input'];
  capability?: InputMaybe<Array<InputMaybe<CapabilityEnum>>>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyIdentification?: InputMaybe<Scalars['String']['input']>;
  companyTaxId?: InputMaybe<Scalars['String']['input']>;
  contactPerson?: InputMaybe<Scalars['String']['input']>;
  containerRentPriceFromDay: Scalars['Int']['input'];
  containerRentPricePerDayKc: Scalars['Float']['input'];
  deliveryDayThresholdMinutes: Scalars['Int']['input'];
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expressDeliveryFeeKc: Scalars['Float']['input'];
  expressDeliveryFromMinutes: Scalars['Int']['input'];
  gps: GpsCoordinations;
  hasIcz?: InputMaybe<Scalars['Boolean']['input']>;
  iczNumber?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  normalDeliveryFromMinutes: Scalars['Int']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  operationRadiusM?: InputMaybe<Scalars['Int']['input']>;
  orpName: Scalars['String']['input'];
  ourMarginPercent: Scalars['Float']['input'];
  pathCalculationStrategy: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  priceLimitNotApplicable?: InputMaybe<Scalars['Boolean']['input']>;
  priceListIsFinal?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  publicHolidayFeeKc: Scalars['Float']['input'];
  regionCode?: InputMaybe<Scalars['Int']['input']>;
  residenceCity?: InputMaybe<Scalars['String']['input']>;
  residenceStreet?: InputMaybe<Scalars['String']['input']>;
  residenceStreetNumber?: InputMaybe<Scalars['String']['input']>;
  residenceZip?: InputMaybe<Scalars['String']['input']>;
  sendOrderAllEmails?: InputMaybe<Scalars['Boolean']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  transportFlatRate?: InputMaybe<Scalars['Float']['input']>;
  useFlatRatePriceList: Scalars['Boolean']['input'];
  web?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
  zpoNote?: InputMaybe<Scalars['String']['input']>;
};

export enum SupplierFlagEnum {
  Cod = 'COD',
  Holidays = 'HOLIDAYS',
  PricelistFinal = 'PRICELIST_FINAL',
  PricelistNotFinal = 'PRICELIST_NOT_FINAL',
  Weekends = 'WEEKENDS',
  WeekendsAndHolidays = 'WEEKENDS_AND_HOLIDAYS',
  WoCod = 'WO_COD'
}

export type SupplierFlatRateList = {
  __typename?: 'SupplierFlatRateList';
  flatPriceKc?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  product: Product;
};

export type SupplierIdWithTypes = {
  __typename?: 'SupplierIdWithTypes';
  id?: Maybe<Scalars['Int']['output']>;
  types?: Maybe<Array<Maybe<SupplierOperationType>>>;
};

export type SupplierList = {
  __typename?: 'SupplierList';
  items?: Maybe<Array<Supplier>>;
  total: Scalars['Int']['output'];
};

export type SupplierMaterialPriceListEntity = {
  __typename?: 'SupplierMaterialPriceListEntity';
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  material: ProductMaterialEntity;
  priceForPublicKc?: Maybe<Scalars['Float']['output']>;
  priceKc: Scalars['Float']['output'];
  productMaterialId: Scalars['ID']['output'];
};

export type SupplierMaterialPriceListInputArgs = {
  isActive: Scalars['Boolean']['input'];
  priceForPublicKc?: InputMaybe<Scalars['Float']['input']>;
  priceKc: Scalars['Float']['input'];
  productMaterialId: Scalars['ID']['input'];
};

export enum SupplierOperationType {
  Collection = 'COLLECTION',
  Delivery = 'DELIVERY'
}

export type SupplierPriceList = {
  __typename?: 'SupplierPriceList';
  baseRentPriceKc?: Maybe<Scalars['Float']['output']>;
  container?: Maybe<Container>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  flatRateRadius?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  minimumPriceForTransport?: Maybe<Scalars['Float']['output']>;
  pricePerKmKc?: Maybe<Scalars['Float']['output']>;
  supplierFlatRates?: Maybe<Array<Maybe<SupplierFlatRateList>>>;
};

export type SupplierPriceListDataInput = {
  baseRentPriceKc?: InputMaybe<Scalars['Float']['input']>;
  containerId?: InputMaybe<Scalars['Int']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  flatRateRadius?: InputMaybe<Scalars['Int']['input']>;
  minimumPriceForTransport?: InputMaybe<Scalars['Float']['input']>;
  pricePerKmKc?: InputMaybe<Scalars['Float']['input']>;
};

export type SupplierServiceConcreteSellerInput = {
  concreteComponentId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  priceForPublicKc?: InputMaybe<Scalars['Float']['input']>;
  priceKc?: InputMaybe<Scalars['Float']['input']>;
};

export type SupplierSupplierEntity = {
  __typename?: 'SupplierSupplierEntity';
  branches?: Maybe<Array<SupplierBranchEntity>>;
  businessContact?: Maybe<ContactType>;
  companyIdentification?: Maybe<Scalars['String']['output']>;
  companyTaxId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoicingAddress?: Maybe<AddressEntity>;
  invoicingContact?: Maybe<ContactType>;
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type SupplierSupplierInputArgs = {
  businessContact?: InputMaybe<ContactTypeInput>;
  companyIdentification?: InputMaybe<Scalars['String']['input']>;
  companyTaxId?: InputMaybe<Scalars['String']['input']>;
  invoicingAddress?: InputMaybe<AddressTypeInput>;
  invoicingContact?: InputMaybe<ContactTypeInput>;
  isActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type SupplierSupplierListResponse = {
  __typename?: 'SupplierSupplierListResponse';
  items: Array<SupplierSupplierEntity>;
  total: Scalars['Int']['output'];
};

export type SupplierTerminalPriceListEntity = {
  __typename?: 'SupplierTerminalPriceListEntity';
  branchService: SupplierBranchServiceEntity;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  priceForPublicKc?: Maybe<Scalars['Float']['output']>;
  priceKc: Scalars['Float']['output'];
  productWaste: ProductWasteEntity;
  productWasteId: Scalars['ID']['output'];
  wasteCategory: WasteCategory;
};

export type SupplierTerminalPriceListInputArgs = {
  isActive: Scalars['Boolean']['input'];
  priceForPublicKc?: InputMaybe<Scalars['Float']['input']>;
  priceKc: Scalars['Float']['input'];
  productWasteId: Scalars['ID']['input'];
  wasteCategory: WasteCategory;
};

export type SupplierTransportSupplierLinkEntity = {
  __typename?: 'SupplierTransportSupplierLinkEntity';
  branchServiceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  supplierBranch?: Maybe<SupplierBranchEntity>;
  supplierBranchId: Scalars['ID']['output'];
  transportCategory: TransportCategory;
};

export type SupplierTruckEntity = {
  __typename?: 'SupplierTruckEntity';
  branchServiceId: Scalars['String']['output'];
  capacityTonne?: Maybe<Scalars['Float']['output']>;
  hasHydraulicArm: Scalars['Boolean']['output'];
  hydraulicArmPriceKc?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  manipulationPriceKc?: Maybe<Scalars['Float']['output']>;
  minimumPriceOfTransport?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  pricePerKmKc: Scalars['Float']['output'];
  supportedCollectionServices: Array<WasteCategory>;
  supportedContainerTypes: Array<Scalars['String']['output']>;
  supportedDeliveryServices: Array<Scalars['String']['output']>;
  truckType: Scalars['String']['output'];
};

export type SupplierVacationInput = {
  vacationFrom: Scalars['String']['input'];
  vacationTo: Scalars['String']['input'];
};

export type SupplierVacationType = {
  __typename?: 'SupplierVacationType';
  vacationFrom: Scalars['String']['output'];
  vacationTo: Scalars['String']['output'];
};

export type SupplierWasteCollectionFlatRateEntity = {
  __typename?: 'SupplierWasteCollectionFlatRateEntity';
  branchServiceId: Scalars['String']['output'];
  container: ProductContainerEntity;
  containerId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  priceForPublicKc: Scalars['Float']['output'];
  priceKc: Scalars['Float']['output'];
  productWasteId: Scalars['String']['output'];
  waste: ProductWasteEntity;
};

export type SupplierWasteCollectionFlatRateInputArgs = {
  isActive: Scalars['Boolean']['input'];
  pricesForPublicKc: Array<InputMaybe<Scalars['Float']['input']>>;
  pricesKc: Array<Scalars['Float']['input']>;
  productContainerIds: Array<Scalars['ID']['input']>;
  productWasteId: Scalars['String']['input'];
};

export type SupplierWasteCollectionFlatResponse = {
  __typename?: 'SupplierWasteCollectionFlatResponse';
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  pricesForPublicKc: Array<Maybe<Scalars['Float']['output']>>;
  pricesKc: Array<Scalars['Float']['output']>;
  productContainers: Array<ProductContainerEntity>;
  productWaste: ProductWasteEntity;
};

export type SupplierWasteCollectionSupplierLinkEntity = {
  __typename?: 'SupplierWasteCollectionSupplierLinkEntity';
  branchServiceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  includedKmInFlatRate?: Maybe<Scalars['Int']['output']>;
  terminalBranch: SupplierBranchEntity;
  terminalBranchId: Scalars['ID']['output'];
  transportFlatRateKc?: Maybe<Scalars['Float']['output']>;
  wasteCategory: WasteCategory;
};

export type SupplierWorkingHours = {
  __typename?: 'SupplierWorkingHours';
  dayName: Scalars['String']['output'];
  from: Scalars['String']['output'];
  isWorking: Scalars['Boolean']['output'];
  surcharge?: Maybe<Scalars['Float']['output']>;
  to: Scalars['String']['output'];
};

export type SupplierWorkingHoursInput = {
  dayName: Scalars['String']['input'];
  from: Scalars['String']['input'];
  isWorking: Scalars['Boolean']['input'];
  surcharge?: InputMaybe<Scalars['Float']['input']>;
  to: Scalars['String']['input'];
};

export type Terminal = {
  __typename?: 'Terminal';
  city?: Maybe<Scalars['String']['output']>;
  companyIdentification?: Maybe<Scalars['String']['output']>;
  contactPerson?: Maybe<Scalars['String']['output']>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  gps?: Maybe<Point>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  operationRadiusM?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  web?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type TerminalDataInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  companyIdentification?: InputMaybe<Scalars['String']['input']>;
  contactPerson?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gps: GpsCoordinations;
  name: Scalars['String']['input'];
  operationRadiusM?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  web?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type TerminalPriceList = {
  __typename?: 'TerminalPriceList';
  disabled: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  pricePerTonneKc: Scalars['Float']['output'];
  wasteType: WasteType;
};

export type TerminalPriceListDataInput = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  pricePerTonneKc?: InputMaybe<Scalars['Float']['input']>;
  wasteTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export enum TransportCategory {
  Concrete = 'CONCRETE',
  Fuel = 'FUEL',
  Material = 'MATERIAL'
}

export type UpdateCustomerInputArgs = {
  advanceAccountsSetup?: InputMaybe<CustomerAdvanceAccountSetupInput>;
  allowedPaymentMethods?: InputMaybe<Array<PaymentMethodEnum>>;
  b2bMarginCorrection?: InputMaybe<Scalars['Float']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyIdentification?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyTaxId?: InputMaybe<Scalars['String']['input']>;
  countryIso?: InputMaybe<Scalars['String']['input']>;
  createInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  customerDiscount?: InputMaybe<Scalars['Float']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  hasFixedPrice?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  invoicingEmail?: InputMaybe<Scalars['String']['input']>;
  isResellCompany?: InputMaybe<Scalars['Boolean']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  limitEndCustomerEmails?: InputMaybe<Scalars['Boolean']['input']>;
  metrakMarginCorrection?: InputMaybe<Scalars['Float']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  primaryPaymentMethod?: InputMaybe<PaymentMethodEnum>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum UpdateFieldEnum {
  AdminNote = 'ADMIN_NOTE'
}

export type UpdateMaterialInput = {
  name: Scalars['String']['input'];
  tonToCubes?: InputMaybe<Scalars['Float']['input']>;
  tooltip?: InputMaybe<Scalars['String']['input']>;
  uiOrder?: InputMaybe<Scalars['Int']['input']>;
  uiPicture?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSupplierContainerInputArgs = {
  adrHandling?: InputMaybe<Scalars['Boolean']['input']>;
  adrSurchargeKc?: InputMaybe<Scalars['Float']['input']>;
  availableCount?: InputMaybe<Scalars['Float']['input']>;
  isActive: Scalars['Boolean']['input'];
  isVirtualContainer?: InputMaybe<Scalars['Boolean']['input']>;
  rentPerDayKc: Scalars['Float']['input'];
};

export type UserResponses = {
  __typename?: 'UserResponses';
  customResponses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  stats?: Maybe<Array<Maybe<UserStats>>>;
};

export type UserStats = {
  __typename?: 'UserStats';
  count?: Maybe<Scalars['Int']['output']>;
  response?: Maybe<Scalars['String']['output']>;
};

export enum WasteCategory {
  HazardousWaste = 'HAZARDOUS_WASTE',
  LiquidWaste = 'LIQUID_WASTE',
  ScrapWaste = 'SCRAP_WASTE',
  SolidWaste = 'SOLID_WASTE'
}

export type WasteFinal = {
  __typename?: 'WasteFinal';
  customerSentTimestamp?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  icz?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  s3key?: Maybe<Scalars['String']['output']>;
  terminalName?: Maybe<Scalars['String']['output']>;
  wasteType?: Maybe<WasteType>;
  wasteTypeId?: Maybe<Scalars['Int']['output']>;
  weightTonne?: Maybe<Scalars['Float']['output']>;
};

export type WasteFinalDateInput = {
  icz?: InputMaybe<Scalars['String']['input']>;
  terminalName?: InputMaybe<Scalars['String']['input']>;
  wasteTypeId?: InputMaybe<Scalars['Int']['input']>;
  weightTonne?: InputMaybe<Scalars['Float']['input']>;
};

export type WasteType = {
  __typename?: 'WasteType';
  code: Scalars['String']['output'];
  code1?: Maybe<Scalars['Int']['output']>;
  code2?: Maybe<Scalars['Int']['output']>;
  code3?: Maybe<Scalars['Int']['output']>;
  code4?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type WasteTypeEntity = {
  __typename?: 'WasteTypeEntity';
  code: Scalars['String']['output'];
  codeMerged: Scalars['String']['output'];
  dangerCode: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type ZpoDetail = {
  __typename?: 'ZpoDetail';
  fileName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  notificationEmails?: Maybe<Scalars['String']['output']>;
  orp?: Maybe<ListOrp>;
  s3key?: Maybe<Scalars['String']['output']>;
  supplierNotifiedTimestamp?: Maybe<Scalars['String']['output']>;
  wasteCompositionDesc?: Maybe<Scalars['String']['output']>;
  wasteOriginDesc?: Maybe<Scalars['String']['output']>;
  wasteType?: Maybe<WasteType>;
  zpoCreatedDate?: Maybe<Scalars['String']['output']>;
  zuj?: Maybe<ListZuj>;
};

export type ZpoDetailInput = {
  listOrpId?: InputMaybe<Scalars['ID']['input']>;
  listZujId?: InputMaybe<Scalars['ID']['input']>;
  notificationEmails?: InputMaybe<Scalars['String']['input']>;
  wasteCompositionDesc?: InputMaybe<Scalars['String']['input']>;
  wasteOriginDesc?: InputMaybe<Scalars['String']['input']>;
  wasteTypeId?: InputMaybe<Scalars['Int']['input']>;
  zpoCreatedDate?: InputMaybe<Scalars['String']['input']>;
};

export type ZpoSuggestion = {
  __typename?: 'ZpoSuggestion';
  listOrpId?: Maybe<Scalars['ID']['output']>;
  listZujId?: Maybe<Scalars['ID']['output']>;
  notificationEmails?: Maybe<Scalars['String']['output']>;
  wasteCompositionDesc?: Maybe<Scalars['String']['output']>;
  wasteOriginDesc?: Maybe<Scalars['String']['output']>;
  wasteTypeId?: Maybe<Scalars['Int']['output']>;
  zpoCreatedDate?: Maybe<Scalars['String']['output']>;
};

export enum CustomerFilterFlagsEnum {
  IsUsingAdvanceAccounts = 'IS_USING_ADVANCE_ACCOUNTS'
}

export enum OrderOperationEnum {
  CertificateDone = 'CERTIFICATE_DONE',
  InvoicingNoteDoneFalse = 'INVOICING_NOTE_DONE_FALSE',
  InvoicingNoteDoneTrue = 'INVOICING_NOTE_DONE_TRUE',
  SendOrderAcceptedEmailToCustomer = 'SEND_ORDER_ACCEPTED_EMAIL_TO_CUSTOMER',
  SendSupplierFullOrderInfoEmail = 'SEND_SUPPLIER_FULL_ORDER_INFO_EMAIL',
  UndoCertificateDone = 'UNDO_CERTIFICATE_DONE'
}

export enum PerformStateActionEnum {
  Approve = 'APPROVE',
  CancelByAdmin = 'CANCEL_BY_ADMIN',
  CancelBySupplier = 'CANCEL_BY_SUPPLIER',
  Confirm = 'CONFIRM',
  ContainerArrive = 'CONTAINER_ARRIVE',
  ContainerTake = 'CONTAINER_TAKE',
  MaterialDelivered = 'MATERIAL_DELIVERED',
  OrderChargedByAdmin = 'ORDER_CHARGED_BY_ADMIN',
  OrderFinishedBySupplier = 'ORDER_FINISHED_BY_SUPPLIER',
  Pay = 'PAY',
  SkipPayment = 'SKIP_PAYMENT',
  UndoApprove = 'UNDO_APPROVE'
}
