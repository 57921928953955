import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { SupplierBranchListResponse, SupplierContainerEntity, SupplierSupplierListResponse } from '../../../graphql/aminTypes'

export const supplierContainerListQuery = gql`
  query supplierContainerList($supplierBranchId: ID!) {
    supplierContainerList(supplierBranchId: $supplierBranchId) {
      id
      isActive
      container {
        id
        name
        volumeM3
      }
      adrHandling
      adrSurchargeKc
      availableCount
      isVirtualContainer
      rentPerDayKc
    }
  }
`

export function useSupplierContainerListQuery(supplierBranchId?: string) {
  return useQuery<{ supplierContainerList: SupplierContainerEntity[] }>(supplierContainerListQuery, {
    fetchPolicy: 'network-only',
    variables: { supplierBranchId },
    skip: !supplierBranchId
  })
}
