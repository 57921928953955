import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'
import { customerListQuery } from '../../../CustomerList/services/customerListQuery'

const updateCustomerMutation = gql`
  mutation updateB2bCustomer($data: UpdateCustomerInputArgs!) {
    updateCustomer(data: $data) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateCustomerMutation() {
  return useMutation<{ updateCustomer: IMutationResult }>(updateCustomerMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: customerListQuery }]
  })
}
