import { gql, useQuery } from '@apollo/client'
import { SupplierTerminalPriceListEntity } from '../../../graphql/aminTypes'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'

export const supplierTerminalPriceListsQuery = gql`
  query supplierTerminalPriceLists($branchServiceId: ID!, $wasteCategory: WasteCategory) {
    supplierTerminalPriceLists(branchServiceId: $branchServiceId, wasteCategory: $wasteCategory) {
      id
      isActive
      wasteCategory
      priceKc
      priceForPublicKc
      productWaste {
        id
        name
        wasteType {
          id
          code
          codeMerged
        }
      }
    }
  }
`

export function useSupplierTerminalPriceListsQuery(branchServiceId?: string, params?: IGqlPagingQueryParams) {
  return useQuery<{ supplierTerminalPriceLists: SupplierTerminalPriceListEntity[] }>(supplierTerminalPriceListsQuery, {
    fetchPolicy: 'network-only',
    variables: { branchServiceId, ...params },
    skip: !branchServiceId
  })
}
