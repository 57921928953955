import PageCard from '../../components/Cards/pageCard'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomerEditor from './CustomerEditor/CustomerEditor'
import CustomerAdvanceAccount from './CustomerAdvanceAccount/CustomerAdvanceAccount'
import { pageCardHeaderButton } from '../../components/Cards/pageCardHeaderButton'
import { useReturnBackWithHighlight } from '../../services/pageHooks/highlightRowAfterReturn'
import { getParamFromUrl } from '../../services/functions/getParamFromUrl'
import { useCustomerDetailQuery } from './queries/customerDetailQuery'

export default function Customer() {
  const customerId = getParamFromUrl('customerId') as string | null
  const customerQR = useCustomerDetailQuery(customerId ?? undefined)
  const urlParts = new URL(window.location.href).pathname.split('/')
  const defaultPage = urlParts.length === 3 ? `${urlParts[1]}-${urlParts[2]}` : 'customer-editor'
  const formMethods = useForm({ defaultValues: { pageCardTabs: defaultPage } })
  const showWindow = formMethods.watch('pageCardTabs')
  const navigate = useNavigate()
  const location = useLocation()
  const { returnBack } = useReturnBackWithHighlight()

  const customer = customerQR.data?.customerList.items[0]
  const customerName = customer?.companyName ?? customer?.firstname + ' ' + customer?.lastname ?? ''
  return (
    <FormProvider {...formMethods}>
      <PageCard
        showLoader={customerQR.loading}
        headerTitle={showWindow === 'customer-editor' ? `Detail zákazníka ${customerName}` : `Zálohové účty zákazníka ${customerName}`}
        cardTabs={[
          { value: 'customer-editor', label: 'Editor detailu', onClick: goToCustomerEditor },
          { value: 'customer-advance-accounts', label: 'Zálohové účty', onClick: goToCustomerAdvanceAccount }
        ]}
        cardOptions={[pageCardHeaderButton('Zpět', returnBack, 'back', '', 'btn-green')]}
      >
        {showWindow === 'customer-editor' && <CustomerEditor customerQR={customerQR} />}
        {showWindow === 'customer-advance-accounts' && <CustomerAdvanceAccount customerName={customerName} />}
      </PageCard>
    </FormProvider>
  )

  function goToCustomerEditor() {
    navigate('/customer/editor' + location.search)
  }

  function goToCustomerAdvanceAccount() {
    navigate('/customer/advance-accounts' + location.search)
  }
}
