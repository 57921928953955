import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../../services/types/gqlTypes'
import { supplierBranchListQuery } from '../../../../SupplierBranchList/queries/supplier-branch-list.query'

const enableBranchServiceMutation = gql`
  mutation enableBranchService($branchId: ID!, $serviceCategory: ServiceCategory!) {
    enableBranchService(branchId: $branchId, serviceCategory: $serviceCategory) {
      status {
        reason
        result
      }
    }
  }
`

export function useEnableBranchService() {
  return useMutation<{ enableBranchService: IMutationResult }>(enableBranchServiceMutation, { refetchQueries: [supplierBranchListQuery] })
}
