import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import ToggleField from '../../../components/formComponents/ToggleField'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import { useMigrateSupplierMutation } from './mutations/migrate-supplier.mutation'
import LoadingContainer from '../../../components/LoadingContainer'
import { SuccessAlert } from '../../../components/formComponents/alert'
import FormField from '../../../components/formComponents/FormField'

export default function MigrateSupplier({ name, supplierId, hideModal }: { supplierId: number; name: string; hideModal: any }) {
  const [migrateSupplierMut, migrateSupplierMR] = useMigrateSupplierMutation()
  const [migrationResult, setMigrationResult] = useState<string>('')
  const formMethods = useForm({
    defaultValues: { overwriteExisting: false }
  })
  return (
    <FormProvider {...formMethods}>
      <LoadingContainer showLoader={migrateSupplierMR.loading}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <div className="row mb-2">
            {migrationResult && (
              <div className="col-md-12">
                <SuccessAlert message={migrationResult} />
              </div>
            )}
            <div className="col-md-12">
              <p>
                <strong>Zmigrovat dodavatele {name} do nových ceníků?</strong>
              </p>
            </div>
            <div className="col-md-12">
              <ToggleField name={'overwriteExisting'} label={'Přepsat dodavatele, pokud má stejný název, nebo IČO'} />
            </div>
            <div className="col-md-12">
              <ToggleField name={'treatAsBranch'} label={'Přidat jako pobočku pro dodavatele se jménem'} />
            </div>
            <div className="col-md-6">
              <FormField name={'branchForSupplierWithName'} label={'Název dodavatele'} />
            </div>
            <div className="col-md-2 offset-8">
              <button type="submit" className="btn btn-primary btn-block" disabled={!!migrationResult}>
                Migrovat
              </button>
            </div>
            <div className="col-md-2">
              <button type="button" className="btn btn-secondary btn-block" onClick={() => hideModal()}>
                Zrušit
              </button>
            </div>
          </div>
        </form>
      </LoadingContainer>
    </FormProvider>
  )

  function handleFormSubmit(data) {
    const updateSupplierForMigrationPromise = migrateSupplierMut({
      variables: {
        supplierId,
        overwriteExisting: data.overwriteExisting,
        branchForSupplierWithName: data.branchForSupplierWithName,
        treatAsBranch: data.treatAsBranch
      }
    })
    processPageOperation({
      promise: updateSupplierForMigrationPromise,
      successAction: () => {
        setMigrationResult(`Dodavatel '${name}' byl úspěšně zmigrován.`)
      }
    })
  }
}
