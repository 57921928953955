import React, { useContext } from 'react'
import { hasQueryArrayData, isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import { FormProvider, useForm } from 'react-hook-form'
import FormField, { inputType } from '../../../components/formComponents/FormField'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import LoadingContainer from '../../../components/LoadingContainer'
import BootstrapTable from 'react-bootstrap-table-next'
import useBootstrapTableDataMapper from '../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { useCustomerAdvanceAccountInvoices } from './queries/customer-advance-accounts-invoices.query'
import { advanceAccountsInvoicesDataDefinition } from './services/advance-accounts-invoices-data-definition'
import DatePickerComp from '../../../components/formComponents/datePickerComp'
import { AccountingDocumentEntity } from '../../../graphql/aminTypes'
import { useCreateAdvanceAccountInvoice } from './mutations/create-advance-account-invoice.mutation'
import { useSendAdvanceAccountInvoiceEmailMutation } from './mutations/send-advance-account-invoice-email.mutation'
import { addInvoiceActions } from '../../../services/bootstrapTable/add-invoice-actions'
import { useSyncAccountingDocumentHandler } from '../../../services/mutations/sync-accounting-document-handler/sync-accounting-document-handler'
import { useCancelAccountingDocumentMutation } from './mutations/cancel-accounting-document.mutation'

export default function CustomerAdvanceAccountInvoices({ advanceAccountId, exitHandler }: { advanceAccountId: string; exitHandler: () => void }) {
  const formMethods = useForm<Record<string, any>>()
  const invoicesQR = useCustomerAdvanceAccountInvoices(advanceAccountId)
  const [createAdvanceAccountMut, createAdvanceAccountQR] = useCreateAdvanceAccountInvoice()
  const [sendInvoiceEmailMut, sendInvoiceEmailQR] = useSendAdvanceAccountInvoiceEmailMutation()
  const [cancelAccountingDocumentMut, cancelAccountingDocumentQR] = useCancelAccountingDocumentMutation()
  const { generateColumnListForBootstrapTable, mapDataArrayToTable } =
    useBootstrapTableDataMapper<AccountingDocumentEntity>(advanceAccountsInvoicesDataDefinition)
  const [syncAccountingDocumentHandler, syncAccountingDocumentQR] = useSyncAccountingDocumentHandler(['advanceAccountInvoices'])

  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      sendInvoiceByEmail: sendInvoiceByEmailHandler,
      cancelAccountingDocument: cancelAccountingDocumentHandler,
      SyncAccountingDocument: syncAccountingDocumentHandler
    }
  })
  const highlightCancelled = (row: any) => (row.isCancelled ? 'redBackground' : '')

  let invoices: any[] = []
  if (invoicesQR.data && hasQueryArrayData(invoicesQR)) invoices = mapDataArrayToTable(invoicesQR.data.advanceAccountInvoices)

  return (
    <LoadingContainer
      showLoader={isAnyQueryLoading(invoicesQR, createAdvanceAccountQR, sendInvoiceEmailQR, cancelAccountingDocumentQR, syncAccountingDocumentQR)}
    >
      <div className="table-responsive-xl">
        <BootstrapTable
          bootstrap4
          striped
          condensed
          rowClasses={highlightCancelled}
          classes="table-responsive-lg digiTable"
          noDataIndication={() => <>"Žádné faktury nenalezeny"</>}
          keyField="id"
          data={invoices}
          columns={columnDefinition}
        />
      </div>
      <h2>Nová faktura</h2>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <fieldset className="form-fieldset bg-lime-lt">
            <div className="row mb-2">
              <div className="col-md-2">
                <DatePickerComp name={'dueDate'} label={'Splatnost'} required registerOptions={{ required: true }} />
              </div>
              <div className="col-md-2">
                <FormField
                  type={inputType.number}
                  name={'amountInclVatKc'}
                  label={'Částka s DPH'}
                  required
                  registerOptions={{ required: true }}
                  valueAsNumber
                />
              </div>
              <div className="col-md-8">
                <FormField
                  type={inputType.text}
                  name={'invoiceItemName'}
                  label={'Řádek faktury'}
                  required
                  registerOptions={{ required: true }}
                  defaultValue={'Záloha na služby'}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <FormField type={inputType.text} name={'note'} label={'Interní poznámka'} />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-2 mt-2 offset-8">
                <button type="submit" className="btn btn-primary ms-auto btn-lg">
                  {'Vytvořit novou zálohovou fakturu'}
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </FormProvider>
      <div className="modal-footer">
        <button type="button" className="btn me-auto" onClick={exitHandler}>
          Zpět
        </button>
      </div>
    </LoadingContainer>
  )

  function sendInvoiceByEmailHandler(row: any) {
    if (!window.confirm('Odeslat email s fakturou zákazníkovi?')) return
    const promise = sendInvoiceEmailMut({
      variables: {
        accountingDocumentId: row.id
      }
    })
    processPageOperation({
      promise: promise,
      successMessage: 'Email s fakturou byl odeslán zákazníkovi.'
    })
  }
  function cancelAccountingDocumentHandler(row: any) {
    if (!window.confirm('Opravdu zrušit fakturu?')) return
    const promise = cancelAccountingDocumentMut({
      variables: {
        accountingDocumentId: row.id
      }
    })
    processPageOperation({
      promise: promise,
      successMessage: 'Faktura byla zrušena.'
    })
  }

  function handleFormSubmit(data: Record<string | number | symbol, any>) {
    const resultPromise = createAdvanceAccountMut({
      variables: {
        advanceAccountId,
        data
      }
    })

    processPageOperation({
      promise: resultPromise,
      successMessage: 'Faktura byla vytvořena.',
      successAction: () => formMethods.reset({})
    })
  }
}
