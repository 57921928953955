import { useSupplierTransportSupplierLinkQuery } from './queries/supplier-transport-supplier-link.query'
import React from 'react'
import LoadingContainer from '../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import useBootstrapTableDataMapper from '../../../services/bootstrapTable/useBootstrapTableDataMapper'
import { transportSupplierLinkDataDefinition } from './services/transport-supplier-link-data-definition'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import { TransportCategory } from '../../../graphql/aminTypes'
import { useDeleteSupplierTransportSupplierLinkMutation } from './mutations/delete-transport-supplier-link.mutation'
import { AddSupplier } from './components/AddSupplier/AddSupplier'

export function TransportLinkComponent({ transportCategory, branchServiceId }: { transportCategory: TransportCategory; branchServiceId: string }) {
  const supplierTransportSupplierLinkQR = useSupplierTransportSupplierLinkQuery(branchServiceId, transportCategory)

  const [deleteTransportLinkMutation, deleteTransportLinkQR] = useDeleteSupplierTransportSupplierLinkMutation()

  const { generateColumnListForBootstrapTable, mapDataArrayToTable } = useBootstrapTableDataMapper(transportSupplierLinkDataDefinition)
  const columnDefinition = generateColumnListForBootstrapTable({
    actionsReference: {
      deleteLink: deleteLinkHandler
    }
  })

  const tableData = mapDataArrayToTable(supplierTransportSupplierLinkQR.data?.supplierTransportSupplierLink)
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(supplierTransportSupplierLinkQR, deleteTransportLinkQR)}>
      <AddSupplier
        transportCategory={transportCategory}
        branchServiceId={branchServiceId}
        doNotShowSuppliers={supplierTransportSupplierLinkQR.data?.supplierTransportSupplierLink.map(x => x.supplierBranchId) ?? []}
      />
      <br />{' '}
      <BootstrapTable
        bootstrap4
        striped
        condensed
        classes="table-responsive-lg digiTable"
        noDataIndication={() => <>Nejsou k dispozici žádní dodavatelé</>}
        keyField="id"
        data={tableData}
        columns={columnDefinition}
      />
    </LoadingContainer>
  )

  function deleteLinkHandler({ id }) {
    const promise = deleteTransportLinkMutation({ variables: { id } })
    processPageOperation({
      promise,
      successMessage: 'Dodavatel byl odpojen'
    })
  }
}
