import { FormProvider, useForm } from 'react-hook-form'
import LoadingContainer from '../../../../components/LoadingContainer'
import React, { useContext, useEffect } from 'react'
import { HtmlDataList } from '../../../../components/HtmlDataList'
import { orderStateDataBuilder } from './data-builders/order-state-data.builder'
import { orderAccountingDataBuilder } from './data-builders/order-accounting-data.builder'
import SelectField from '../../../../components/formComponents/SelectField'
import { usePaymentActionMutation } from './mutation/paymentActionMutation'
import { accountingOperations } from './payment-operations/operation-options'
import { WarningAlert } from '../../../../components/formComponents/alert'
import { operationsDescription } from './payment-operations/operations-description'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { paymentMethodsOptions } from '../../../../services/types/payment-method'
import { paymentStatusOptions } from '../../../../services/types/payment-status'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import DatePickerComp from '../../../../components/formComponents/datePickerComp'
import { AccountingOptionsFormType } from './types/accounting-options-form.type'
import dayjs from 'dayjs'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import ToggleField from '../../../../components/formComponents/ToggleField'
import { LoginContext } from '../../../../services/loginAndToken/useLogin'
import { useAccountingDocumentsQuery } from '../AccountingDocuments/query/accountingDocumentsQuery'
import { useOrderDetailQuery } from '../../queries/orderDetailQuery'
import { additionalInvoiceReasons } from './payment-operations/additional-invoice-reasons'

export default function AccountingOptionsComponent({ onFinish }: { onFinish: () => void }) {
  const login = useContext(LoginContext)

  const orderDetailQR = useOrderDetailQuery()
  const orderContent = orderDetailQR.data?.orderDetail
  const orderDetail = orderContent?.order

  const [paymentActionMut, paymentActionQR] = usePaymentActionMutation(orderContent?.order.id)
  const accountingDocumentsQR = useAccountingDocumentsQuery(orderContent?.order.id)
  const formMethods = useForm<AccountingOptionsFormType>({
    defaultValues: {
      paymentAction: undefined,
      paymentMethodTo: undefined,
      dueDays: '7',
      createdDate: dayjs().format('YYYY-MM-DD'),
      duzpDate: null,
      isFullRefund: true,
      refundReason: 'objednávka byla zrušena',
      refundDate: dayjs().add(5, 'days').format('YYYY-MM-DD'),
      additionalInvoiceTotalWoVatKc: '0',
      additionalInvoiceReason: ''
    }
  })

  useEffect(() => {
    formMethods.register('refundDate')
    formMethods.register('duzpDate')
    formMethods.register('createdDate')
    formMethods.register('moneyToRefundWoVat')
    formMethods.register('paymentMethodTo')
    formMethods.register('dueDays')
    formMethods.register('isFullRefund')
    formMethods.register('refundReason')
    formMethods.register('additionalInvoiceTotalWoVatKc')
    formMethods.register('additionalInvoiceReason')
  }, [])
  useEffect(() => {
    if (!orderContent || !orderDetail) return
    formMethods.setValue('moneyToRefundWoVat', String(orderDetail.totalWoVatKc))
    updateDUZPDate()
  }, [orderContent])
  useEffect(() => {
    if (formMethods.watch('paymentAction') === 'CreateAdditionalInvoice') formMethods.setValue('dueDays', '5')
    else formMethods.setValue('dueDays', '7')
  }, [formMethods, formMethods.watch('paymentAction')])

  if (!orderContent) return <LoadingContainer showLoader />
  const opravnyDanovyDoklad = accountingDocumentsQR.data?.accountingDocuments.find(x => x.type === 'OPRAVNY_DANOVY_DOKLAD' && !x.isCancelled)

  const selectedPaymentAction = formMethods.watch('paymentAction')
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(accountingDocumentsQR, paymentActionQR)}>
      <div className="row mb-2">
        <div className="col-md-6">
          <fieldset className="form-fieldset">
            <HtmlDataList label="Údaje o objednávce" data={orderStateDataBuilder(orderContent)} />
          </fieldset>
        </div>
        <div className="col-md-6">
          <fieldset className="form-fieldset">
            <HtmlDataList label="Platební informace" data={orderAccountingDataBuilder(orderContent, accountingDocumentsQR.data?.accountingDocuments)} />
          </fieldset>
        </div>
      </div>

      <FormProvider {...formMethods}>
        <form autoComplete={'off'} onSubmit={formMethods.handleSubmit(paymentActionHandler)}>
          <fieldset className="form-fieldset">
            <div className="row mb-2">
              <div className="col-md-12">
                <SelectField
                  required
                  name="paymentAction"
                  label={'Požadovaná operace'}
                  optionsData={accountingOperations(
                    orderContent,
                    accountingDocumentsQR.data?.accountingDocuments,
                    ['info@metrak.cz', 'ilona.kubickova@metrak.cz'].includes(login?.currentJWT?.email || '')
                  )}
                />
              </div>
            </div>
            {selectedPaymentAction === 'ChangePaymentMethod' && (
              <div className="row mb-2">
                <div className="col-md-6">
                  <SelectField
                    required
                    name="paymentMethodTo"
                    label={'Změnit platební metodu na'}
                    optionsData={paymentMethodsOptions.filter(x => x.value !== orderDetail?.paymentType)}
                  />
                </div>
              </div>
            )}
            {['CreateAdditionalInvoice', 'CreateNewInvoice'].includes(selectedPaymentAction ?? '') && (
              <>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <DatePickerComp dateAsString required name={'createdDate'} label={'Datum vytvoření faktury'} />
                  </div>
                  <div className="col-md-6">
                    <DatePickerComp dateAsString required name={'duzpDate'} label={'Datum DUZP'} />
                  </div>
                </div>
                {dayjs(formMethods.watch('createdDate')).diff(formMethods.watch('duzpDate'), 'day') > 15 && (
                  <div className="row mb-2">
                    <div className="col-md-12">
                      <WarningAlert message={'DUZP může být od data vytvoření faktury max 15 dní.'}></WarningAlert>
                    </div>
                  </div>
                )}
                <div className="row mb-2 mt-2">
                  <div className="col-md-6">
                    <FormField name="dueDays" label={'Dní do splatnosti'} type={inputType.number} isPositive required />
                  </div>
                  {selectedPaymentAction === 'CreateAdditionalInvoice' && (
                    <div className="col-md-6">
                      <FormField name="additionalInvoiceTotalWoVatKc" label={'Částka bez DPH'} type={inputType.number} isMoney />
                    </div>
                  )}
                </div>
                {selectedPaymentAction === 'CreateAdditionalInvoice' && (
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <SelectField name="additionalInvoiceReason" label={'Důvod dofakturace'} optionsData={additionalInvoiceReasons} isCreatable />
                    </div>
                  </div>
                )}
              </>
            )}
            {selectedPaymentAction === 'LinkVyfakturujInvoice' && (
              <div className="row mb-2">
                <div className="col-md-8">
                  <FormField
                    name="invoiceLink"
                    label={'Zadejte odkaz na detail dokumentu ve tvaru "https://app.vyfakturuj.cz/faktury/detail/xxxxxxx/"'}
                    type={inputType.text}
                  />
                </div>
              </div>
            )}
            {selectedPaymentAction === 'ChangePaymentStatus' && (
              <div className="row mb-2">
                <div className="col-md-6">
                  <SelectField
                    name="paymentStatusTo"
                    label={'Změnit stav zaplacení na'}
                    optionsData={paymentStatusOptions.filter(x => x.value !== orderDetail?.paymentStatus)}
                  />
                </div>
              </div>
            )}
            {selectedPaymentAction === 'Refund' && (
              <div className="row mb-2">
                <div className="col-md-3">
                  <ToggleField name="isFullRefund" label={'Vratka je na plnou částku - zrušení objednávky'} />
                </div>
                <div className="col-md-3">
                  <DatePickerComp dateAsString name={'refundDate'} label={'Datum splatnosti'} />
                </div>
                <div className="col-md-3">
                  <FormField
                    name="moneyToRefundWoVat"
                    label={'Vrátit (bez DPH)'}
                    type={inputType.number}
                    isDecimal
                    isPositive
                    defaultValue={orderDetail?.totalWoVatKc ?? 0}
                    disabled={formMethods.watch('isFullRefund')}
                  />
                </div>
                <div className="col-md-6">
                  <FormField
                    name="refundReason"
                    label={'Důvod vrácení - bude v emailu zákazníkovi a na ODD'}
                    type={inputType.text}
                    defaultValue={'objednávka byla zrušena'}
                    disabled={formMethods.watch('isFullRefund')}
                  />
                </div>
              </div>
            )}
            <div className="row mt-4">
              <div className="col-md-2">
                <button type="submit" className="btn btn-primary ms-auto btn-lg" disabled={opravnyDanovyDoklad && selectedPaymentAction === 'Refund'}>
                  Provést
                </button>
              </div>
              <div className="col-md-10">
                {selectedPaymentAction && <WarningAlert>{operationsDescription[selectedPaymentAction].infoMessage}</WarningAlert>}
              </div>
            </div>
          </fieldset>
        </form>
      </FormProvider>
      <div className="d-flex justify-content-md-center">
        <button type="button" className="btn btn-secondary ms-auto btn-lg" onClick={onFinish}>
          Zpět
        </button>
      </div>
    </LoadingContainer>
  )

  function updateDUZPDate() {
    const realisationDate =
      orderContent?.serviceType === 'MATERIAL_DELIVERY'
        ? dayjs(orderContent?.containerFromDate)
        : orderContent?.containerToDate
          ? dayjs(orderContent?.containerToDate)
          : undefined
    const createDate = dayjs(formMethods.watch('createdDate'))
    const duzp = realisationDate && realisationDate.isBefore(createDate) ? realisationDate : createDate
    if (duzp && createDate.diff(duzp, 'day') > 15) {
      formMethods.reset({
        ...formMethods.watch(),
        duzpDate: duzp.format('YYYY-MM-DD'),
        dueDays: `${dayjs().diff(duzp, 'days') - 15 + 7}`,
        createdDate: duzp.add(15, 'days').format('YYYY-MM-DD')
      })
    } else if (duzp) {
      formMethods.reset({
        ...formMethods.watch(),
        duzpDate: duzp.format('YYYY-MM-DD'),
        dueDays: `${dayjs().diff(duzp, 'days') - 15 + 7}`
      })
    }
  }

  function paymentActionHandler(data: AccountingOptionsFormType) {
    if (!orderDetail || !data.paymentAction) return
    data.refundReason = formMethods.watch('refundReason') //nevim proc to tam neni automaticky
    processPaymentAction(orderDetail.id, data.paymentAction, data)
  }

  function sendRefundEmailHandler() {
    if (!orderDetail) return
    if (window.confirm('Opravdu chcete odeslat email o vratce zákazníkovi?')) processPaymentAction(orderDetail.id, 'RefundEmail2Customer', {})
  }

  function processPaymentAction(orderId: number, paymentAction: string, data: AccountingOptionsFormType) {
    const promise = paymentActionMut({
      variables: {
        orderId,
        action: paymentAction,
        paramsJson: JSON.stringify(data)
      }
    })
    processPageOperation({
      promise: promise,
      successMessage: operationsDescription[paymentAction].successMessage,
      successAction: async () => {
        await accountingDocumentsQR.refetch()
        await orderDetailQR.refetch()
        formMethods.setValue('paymentAction', undefined)
      }
    })
  }
}
