import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { ProductWasteEntity } from '../../../graphql/aminTypes'

export const productWasteListQuery = gql`
  query productWasteList($name: String, $wasteCategory: WasteCategory) {
    productWasteList(name: $name, wasteCategory: $wasteCategory) {
      id
      isActive
      isForPublic
      name
      tooltip
      wasteCodeId
      wasteCategory
      uiPicture
      uiOrder
      cubicToTonnes
      note
      wasteType {
        id
        name
        code
      }
      hasAdditivesSpecification
      childWastes {
        id
        isActive
        isForPublic
        name
        tooltip
        wasteCodeId
        wasteCategory
        parentWasteId
        uiPicture
        uiOrder
        cubicToTonnes
        note
        wasteType {
          id
          name
          code
        }
      }
    }
  }
`

export function useProductWasteListQuery(params: IGqlPagingQueryParams) {
  return useQuery<{ productWasteList: ProductWasteEntity[] }>(productWasteListQuery, { fetchPolicy: 'network-only', variables: params })
}
