import FormField, { inputType } from '../../../../components/formComponents/FormField'
import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useGetCompanyInfoFromAresQuery } from '../../../../services/queries/getCompanyInfoFromAresQuery'
import { useFormContext } from 'react-hook-form'
import { useCustomerListQuery } from './queries/b2bCustomersQuery'
import SelectField from '../../../../components/formComponents/SelectField'
import { IReactSelectItem } from '../../../../services/types/ReactSelect'
import { useOrderListForAutocompleteQuery } from './queries/order-content-list-for-autocomplete.query'
import { OrderContentsResult } from '../../../../graphql/aminTypes'
import ToggleField from '../../../../components/formComponents/ToggleField'
import CompanyNamePicker from '../../../../components/formComponents/CompanyNamePicker/CompanyNamePicker'
import { SubjectInfoType } from '../../../../graphql/publicTypes'
import { countryIsoOptions } from '../../../../services/values/country-iso.options'

export default function Customer({ disabled, className }: { disabled: boolean; className: string }) {
  const companyInfoQR = useGetCompanyInfoFromAresQuery()
  const customerListQR = useCustomerListQuery()
  const formMethods = useFormContext()
  const b2bCustomersData = useRef<IReactSelectItem[]>([])
  const orderContents = useOrderListForAutocompleteQuery()

  useEffect(() => {
    if (customerListQR?.data && customerListQR.data.customerList?.items && customerListQR.data.customerList.items.length > 0)
      b2bCustomersData.current = customerListQR.data.customerList.items.map(x => ({
        value: x.id,
        label: x.companyName ? `${x.companyName} <${x.email}>` : `${x.lastname} ${x.firstname} <${x.email}>`
      }))
  }, [customerListQR.data])
  return (
    <>
      <h2>Zákazník:</h2>
      <fieldset className={classNames('form-fieldset', className)} disabled={disabled}>
        <div className="row mb-2">
          <div className="col-md-6">
            <SelectField
              name={'b2bCustomerId'}
              label={'Na registrovaného klienta'}
              disabled={disabled}
              optionsData={b2bCustomersData.current}
              isLoading={customerListQR.loading}
              handleChange={setupCustomerData}
            />
          </div>
          <div className="col-md-6">
            <SelectField
              name={'historySearch'}
              label={'Vyhledat v historii objednávek'}
              placeholder={'Napište jméno, firmu, email nebo telefon zakázky'}
              disabled={disabled}
              isAsync
              loadOptions={loadOptionsForHistorySearch}
              handleChange={setupCustomerDataFromHistory}
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-md-6">
            <FormField type={inputType.text} name={'firstname'} label={'Jméno'} required registerOptions={{ required: true }} />
          </div>
          <div className="col-md-6">
            <FormField type={inputType.text} name={'lastname'} label={'Příjmení'} required registerOptions={{ required: true }} />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6">
            <FormField type={inputType.text} name={'phone'} label={'Telefon'} required registerOptions={{ required: true }} />
          </div>
          <div className="col-md-6">
            <FormField type={inputType.text} name={'email'} label={'E-mail (více emailů oddělte čárkou nebo středníkem)'} />
          </div>
        </div>
        <h3>Firemní údaje</h3>
        <fieldset className="form-fieldset">
          <div className="row mb-2">
            <div className="col-md-6">
              <CompanyNamePicker
                name={'companyNameSearch'}
                label={'Vyhledat firmu podle názvu'}
                setupCompanyDataFromSelection={setupCompanyDataFromSelection}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-6">
              <FormField type={inputType.text} name={'companyName'} label={'Jméno firmy'} />
            </div>
            <div className="col-md-6">
              <SelectField name={'companyResidenceCountryIso'} label={'Stát'} required optionsData={countryIsoOptions} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-3">
              <FormField type={inputType.text} name={'ico'} label={'IČO'} />
            </div>
            <div className="col-md-3">
              <button type="button" className="btn btn-primary mt-3" onClick={loadDataFromAresHandler}>
                Načíst z ARES
              </button>
            </div>
            <div className="col-md-6">
              <FormField type={inputType.text} name={'dic'} label={formMethods.watch('companyResidenceCountryIso') === 'SK' ? 'IČ DPH' : 'DIČ'} />
            </div>
          </div>
          <div className={`row mb-2`}>
            <div className="col-md-6">
              <FormField type={inputType.text} name={'companyResidenceStreet'} label={'Adresa sídla - ulice'} />
            </div>
            <div className="col-md-6">
              <FormField type={inputType.text} name={'companyResidenceStreetNumber'} label={'Adresa sídla - č.p/o.'} />
            </div>
            <div className="col-md-6">
              <FormField type={inputType.text} name={'companyResidenceCity'} label={'Adresa sídla - město'} />
            </div>
            <div className="col-md-6">
              <FormField type={inputType.text} name={'companyResidenceZip'} label={'Adresa sídla - PSČ'} />
            </div>
          </div>
        </fieldset>

        <div className={`${formMethods.watch('b2bCustomerId') ? '' : 'd-none'}`}>
          <h3>Firma pro kterou B2B objednává (zákazník DEKu)</h3>
          <fieldset className="form-fieldset">
            <div className="row mb-2">
              <div className="col-md-6">
                <CompanyNamePicker
                  name={'targetCompanySearch'}
                  label={'Vyhledat firmu podle názvu'}
                  setupCompanyDataFromSelection={setupTargetCompanyDataFromSelection}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-3">
                <FormField type={inputType.text} name={'targetCompanyIco'} label={'IČO'} />
              </div>
              <div className="col-md-3">
                <button type="button" className="btn btn-primary mt-3" onClick={loadDataFromAresHandler2}>
                  Načíst z ARES
                </button>
              </div>
              <div className="col-md-6">
                <FormField type={inputType.text} name={'targetCompanyName'} label={'Jméno firmy'} />
              </div>
            </div>
          </fieldset>
        </div>
        <div className={`row mb-2`}>
          <div className="col-md-6">
            <FormField type={inputType.text} name={'onsiteContactFirstname'} label={'Kontakt na místě - jméno'} />
          </div>
          <div className="col-md-6">
            <FormField type={inputType.text} name={'onsiteContactLastname'} label={'Kontakt na místě - přijmení'} />
          </div>
          <div className="col-md-6">
            <FormField type={inputType.text} name={'onsiteContactPhone'} label={'Kontakt na místě - telefon'} />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-md-12">
            <FormField type={inputType.text} name={'orderNote'} label={'Poznámka'} />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-12">
            <ToggleField name={'notificationSettings.disableUnpaidOrderNotification'} label={'Neposílat notifikace o nezaplacení objednávky'} />
          </div>
        </div>
      </fieldset>
    </>
  )

  async function loadDataFromAresHandler() {
    const ico = formMethods.watch('ico')
    const companyResidenceCountryIso = formMethods.watch('companyResidenceCountryIso')
    if (!ico || !['CZ', 'SK'].includes(companyResidenceCountryIso)) {
      window.alert('Zkontrolujte IČO a zemi sídla firmy. Lze napovídat jen pro CZ a SK firmy.')
      return
      return
    }
    const result = await companyInfoQR.refetch({ ico, countryIso: companyResidenceCountryIso })

    if (!result || !result.data || !result.data.getCompanyInfoFromAres) {
      window.alert('Nepodařilo se získat data z registru')
      return
    }
    const subjectInfo = result.data.getCompanyInfoFromAres
    formMethods.setValue('companyName', subjectInfo.name)
    formMethods.setValue('companyResidenceStreet', subjectInfo.street)
    formMethods.setValue('companyResidenceStreetNumber', subjectInfo.streetNumber)
    formMethods.setValue('companyResidenceCity', subjectInfo.city)
    formMethods.setValue('companyResidenceZip', subjectInfo.zip)
    if (subjectInfo.dic) formMethods.setValue('dic', subjectInfo.dic)
  }

  async function loadDataFromAresHandler2() {
    const ico = formMethods.watch('targetCompanyIco')
    if (!ico || ico.length !== 8) {
      window.alert('Vyplňte IČO aby mělo 8 znaků')
      return
    }
    const result = await companyInfoQR.refetch({ ico })

    if (!result || !result.data || !result.data.getCompanyInfoFromAres) {
      window.alert('Nepodařilo se získat data z registru')
      return
    }
    const subjectInfo = result.data.getCompanyInfoFromAres
    formMethods.setValue('targetCompanyName', subjectInfo.name)
  }

  function setupCustomerData(b2bCustomerId?: number) {
    if (!b2bCustomerId || !customerListQR.data) return
    const b2bCustomer = customerListQR?.data.customerList?.items!.find(x => +x.id === b2bCustomerId)
    if (!b2bCustomer) return

    const selectedPaymentMethod = formMethods.watch('paymentType')
    if (selectedPaymentMethod !== b2bCustomer.primaryPaymentMethod) {
      if (window.confirm('Zvolená platební metoda se neshoduje s primární platební metodou zákazníka. Chcete ji změnit?')) {
        formMethods.setValue('paymentType', b2bCustomer.primaryPaymentMethod)
        if (selectedPaymentMethod === 'COD') window.alert('Původně byla vybrána platba dobírkou. Přepočítejte cenu, aby tam nezůstala cena za dobírku.')
      }
    }
    formMethods.setValue('firstname', b2bCustomer.firstname)
    formMethods.setValue('lastname', b2bCustomer.lastname)
    formMethods.setValue('email', b2bCustomer.email)
    formMethods.setValue('phone', b2bCustomer.phone)
    formMethods.setValue('companyName', b2bCustomer.companyName)
    formMethods.setValue('dic', b2bCustomer.companyTaxId)
    formMethods.setValue('ico', b2bCustomer.companyIdentification)
    formMethods.setValue('companyResidenceStreet', b2bCustomer.street)
    formMethods.setValue('companyResidenceStreetNumber', b2bCustomer.streetNumber)
    formMethods.setValue('companyResidenceCity', b2bCustomer.city)
    formMethods.setValue('companyResidenceZip', b2bCustomer.zip)
    formMethods.setValue('companyResidenceCountryIso', b2bCustomer.countryIso)
  }

  function loadOptionsForHistorySearch(inputValue: string, callback: (options: IReactSelectItem<OrderContentsResult>[]) => void) {
    if (!inputValue || inputValue.length < 2) return
    orderContents.refetch({ limit: 20, offset: 0, name: inputValue }).then(x => {
      if (!x || !x.data || !x.data.orderContentList || x.data.orderContentList.items.length === 0) {
        callback([])
        return
      }
      callback(
        x.data.orderContentList.items.map(y => ({
          value: y.id,
          label: `${y.order.firstname ?? ''} ${y.order.lastname ?? ''} (${y.order.companyName ?? ''} - ${y.order.ico ?? ''}) - ${y.order.phone}, ${
            y.order.email
          }`,
          data: y
        }))
      )
    })
  }

  function setupCustomerDataFromHistory(orderContentId: number, data?: OrderContentsResult) {
    if (!data || !data.order) return
    const order = data.order

    formMethods.setValue('b2bCustomerId', undefined)

    formMethods.setValue('firstname', order.firstname)
    formMethods.setValue('lastname', order.lastname)
    formMethods.setValue('email', order.email)
    formMethods.setValue('phone', order.phone)
    formMethods.setValue('companyName', order.companyName)
    formMethods.setValue('dic', order.dic)
    formMethods.setValue('ico', order.ico)
    formMethods.setValue('companyResidenceStreet', order.companyResidence?.street)
    formMethods.setValue('companyResidenceStreetNumber', order.companyResidence?.streetNumber)
    formMethods.setValue('companyResidenceCity', order.companyResidence?.city)
    formMethods.setValue('companyResidenceZip', order.companyResidence?.zip)
    formMethods.setValue('companyResidenceCountryIso', order.companyResidence?.countryIso)
    formMethods.setValue('onsiteContactFirstname', data.onsiteContactFirstname)
    formMethods.setValue('onsiteContactLastname', data.onsiteContactLastname)
    formMethods.setValue('onsiteContactPhone', data.onsiteContactPhone)
  }

  function setupCompanyDataFromSelection(company: SubjectInfoType) {
    if (!company) return
    formMethods.setValue('companyName', company.name)
    formMethods.setValue('dic', company.dic)
    formMethods.setValue('ico', company.ico)
    formMethods.setValue('companyResidenceStreet', company.street)
    formMethods.setValue('companyResidenceStreetNumber', company.streetNumber)
    formMethods.setValue('companyResidenceCity', company.city)
    formMethods.setValue('companyResidenceZip', company.zip)
    formMethods.setValue('companyResidenceCountryIso', company.countryIso)
  }
  function setupTargetCompanyDataFromSelection(company: SubjectInfoType) {
    if (!company) return
    formMethods.setValue('targetCompanyName', company.name)
    formMethods.setValue('targetCompanyIco', company.ico)
  }
}
