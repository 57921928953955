import { gql, useQuery } from '@apollo/client'
import { IczSuggest, WasteFinal } from '../../../graphql/aminTypes'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'

export const suggestIczQuery = gql`
  query suggestIcz($icz: String!) {
    suggestIcz(icz: $icz) {
      icz
      name
    }
  }
`

export function useSuggestIczQuery(isForSupplier: boolean) {
  return useQuery<{ suggestIcz: IczSuggest[] }>(suggestIczQuery, {
    context: contextEndpoint(isForSupplier ? EndpointEnum.supplier : EndpointEnum.admin),
    skip: true
  })
}
