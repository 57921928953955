import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../services/types/gqlTypes'

const performGroupActionMutation = gql`
  mutation performGroupChange($orderContentIds: [Int]!, $action: String!, $payload: String) {
    performGroupChange(orderContentId: $orderContentIds, action: $action, payload: $payload) {
      status {
        reason
        result
      }
    }
  }
`

export function usePerformGroupChangeMutation() {
  return useMutation<{ performGroupChange: IMutationResult }>(performGroupActionMutation)
}

export function createParamsForPerformGroupActionMutation(ids: number[], action: performGroupActionType, payload?: string) {
  return { orderContentIds: ids, action: action, payload }
}

export type performGroupActionType = 'SET_GROUP_INVOICE' | 'TAKE_OWNERSHIP'

