import { gql, useQuery } from '@apollo/client'
import { GetAvailableServicesForLocalityResponse, PriceCalculatorResponse } from '../../../graphql/aminTypes'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'

export const calculateCollectionServiceOptionsQuery = gql`
  query calculateCollectionServiceOptions(
    $customerLatLng: LatLngInput!
    $radiusKm: Int!
    $calculationDate: String!
    $allowNearestLargerContainer: String!
    $routingStrategy: String!
    $containerId: ID!
    $productId: ID!
    $wasteCategory: WasteCategory!
    $containerRentLengthDays: Int!
    $customContainerCapacity: Float
  ) {
    calculateCollectionServiceOptions(
      customerLatLng: $customerLatLng
      radiusKm: $radiusKm
      calculationDate: $calculationDate
      allowNearestLargerContainer: $allowNearestLargerContainer
      routingStrategy: $routingStrategy
      containerId: $containerId
      productId: $productId
      wasteCategory: $wasteCategory
      containerRentLengthDays: $containerRentLengthDays
      customContainerCapacity: $customContainerCapacity
    ) {
      supplierBranch {
        id
        gps {
          lat
          lng
        }
        address {
          id
          city
        }
        supplier {
          id
          name
        }
      }
      container {
        id
        name
        volumeM3
      }
      calculationOptions {
        distanceKm
        encodedGeometry
        priceBreakdown {
          amount
          note
          priceItemType {
            id
            name
          }
        }
        priceInclVatKc
        priceWoVatKc
        type
        terminalBranch {
          id
          specification
          address {
            id
            city
          }
          gps {
            lat
            lng
          }
          supplier {
            id
            name
          }
        }
      }
    }
  }
`

export function useCalculateCollectionServiceOptionsQuery() {
  return useQuery<{ calculateCollectionServiceOptions: PriceCalculatorResponse[] }>(calculateCollectionServiceOptionsQuery, {
    fetchPolicy: 'network-only',
    skip: true,
    context: contextEndpoint(EndpointEnum.admin)
  })
}
