import { gql, useQuery } from '@apollo/client'
import { ProductWasteEntity, WasteCategory } from '../../../../../graphql/aminTypes'

export const productWasteListFlatQuery = gql`
  query productWasteListFlat($wasteCategory: WasteCategory, $isActive: Boolean) {
    productWasteListFlat(wasteCategory: $wasteCategory, isActive: $isActive) {
      id
      isActive
      name
      tooltip
      wasteCategory
      uiOrder
      wasteType {
        id
        name
        code
        codeMerged
      }
      hasAdditivesSpecification
      parentWasteId
      childWastes {
        id
      }
    }
  }
`

export function useProductWasteListFlatQuery(wasteCategory: WasteCategory) {
  return useQuery<{ productWasteListFlat: ProductWasteEntity[] }>(productWasteListFlatQuery, {
    fetchPolicy: 'network-only',
    variables: { wasteCategory, isActive: true }
  })
}
