import React, { useContext, useEffect } from 'react'
import { useProductWasteListFlatQuery } from '../../../common/components/ProductWasteSelector/queries/product-waste-list.query'
import { SupplierBranchInputArgs, SupplierWasteCollectionFlatResponse, WasteCategory } from '../../../../graphql/aminTypes'
import LoadingContainer from '../../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { FormProvider, useForm } from 'react-hook-form'
import ToggleField from '../../../../components/formComponents/ToggleField'
import FormField, { inputType } from '../../../../components/formComponents/FormField'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { useSupplierContainerListQuery } from '../../../SupplierContainerList/queries/supplier-container-list.query'
import { SelectedSupplierModalContext } from '../../../SelectedSupplierModal/SelectedSupplierModal'
import { useUpsertSupplierWasteCollectionFlatRateMutation } from './mutations/upsert-supplier-waste-collection-flat-rate.mutation'
import { ProductWasteSelector } from '../../../common/components/ProductWasteSelector/ProductWasteSelector'

export default function FlatRateWasteCollectionEditor({
  branchServiceId,
  onClose,
  editItem,
  doNotShowWastes = []
}: {
  branchServiceId?: string
  onClose: () => void
  editItem?: SupplierWasteCollectionFlatResponse
  doNotShowWastes?: string[]
}) {
  const { state } = useContext(SelectedSupplierModalContext)
  const formMethods = useForm({
    defaultValues: { isActive: true, defaultMetrakMargin: 17, 'paymentCapabilities.CASH': true, 'orderNotificationOptions.EMAIL': true } as any
  })
  const [upsertSupplierWasteCollectionFlatRateMut, upsertSupplierWasteCollectionFlatRateQR] = useUpsertSupplierWasteCollectionFlatRateMutation()
  const productWasteListFlatQR = useProductWasteListFlatQuery(WasteCategory.SolidWaste)
  const supplierContainerQR = useSupplierContainerListQuery(state.selectedBranch?.id)

  useEffect(() => {
    if (!editItem || !supplierContainerQR.data || !productWasteListFlatQR.data) return

    formMethods.setValue('isActive', editItem?.isActive)
    formMethods.setValue('productWasteId', editItem?.productWaste.id)
    const supportedContainerTypes = {}
    editItem?.productContainers.forEach(x => {
      supportedContainerTypes[x.id] = {
        price_kc: editItem.pricesKc[editItem.productContainers.indexOf(x)],
        priceForPublicKc: editItem.pricesForPublicKc[editItem.productContainers.indexOf(x)]
          ? editItem.pricesForPublicKc[editItem.productContainers.indexOf(x)]
          : undefined
      }
    })
    formMethods.setValue('supportedContainerTypes', supportedContainerTypes)
  }, [editItem, supplierContainerQR.data, productWasteListFlatQR.data])

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(productWasteListFlatQR, supplierContainerQR, upsertSupplierWasteCollectionFlatRateQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleFormSubmit)} autoComplete={'off'}>
          <div className="row mb-2">
            <div className="col-md-12">
              <ToggleField name={'isActive'} label={'Aktivní'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-8">
              <ProductWasteSelector wasteCategory={WasteCategory.SolidWaste} label={'Vybraný odpad'} disabled={!!editItem} doNotShowWastes={doNotShowWastes} />
            </div>
          </div>
          {supplierContainerQR.data?.supplierContainerList.map(x => (
            <div className="row mb-2" key={x.id}>
              <div className="col-md-2 pt-3">
                {x.container.name} {x.container.volumeM3}m³
              </div>
              <div className="col-md-3">
                <FormField
                  type={inputType.number}
                  isMoney
                  name={`supportedContainerTypes.${x.container.id}.price_kc`}
                  label={'Paušální cena pro Metrák.cz [Kč]'}
                  valueAsNumber
                />
              </div>
              <div className="col-md-3">
                <FormField
                  type={inputType.number}
                  isMoney
                  name={`supportedContainerTypes.${x.container.id}.priceForPublicKc`}
                  label={'Paušální cena pro veřejnost [Kč]'}
                  valueAsNumber
                />
              </div>
            </div>
          ))}
          <div className="form-footer">
            <button type="submit" className="btn btn-primary" disabled={!formMethods.watch('productWasteId')}>
              Uložit
            </button>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function handleFormSubmit(formData: SupplierBranchInputArgs) {
    const containersWithPrices = Object.keys(formData['supportedContainerTypes'])
      .filter(x => !!formData['supportedContainerTypes'][x].price_kc)
      .map(x => ({
        containerId: x,
        priceKc: formData['supportedContainerTypes'][x].price_kc,
        priceForPublicKc: formData['supportedContainerTypes'][x].priceForPublicKc ? formData['supportedContainerTypes'][x].priceForPublicKc : undefined
      }))
    const data = {
      isActive: formData.isActive,
      productWasteId: formData['productWasteId'],
      productContainerIds: containersWithPrices.map(x => x.containerId),
      pricesKc: containersWithPrices.map(x => x.priceKc),
      pricesForPublicKc: containersWithPrices.map(x => x.priceForPublicKc)
    }
    const promise = upsertSupplierWasteCollectionFlatRateMut({
      variables: { branchServiceId, data: data }
    })
    processPageOperation({
      promise: promise,
      successMessage: 'Pobočka byla úspěšně uložena',
      successAction: onClose
    })
    return false
  }
}
