import { IStringObject } from '../types/IStringObject'
import { useLocation } from 'react-router-dom'
import { useCallback, useMemo } from 'react'

export const DEFAULT_PAGE_SIZE = 10
const MAX_PAGE_SIZE = 500

export interface IPageSort extends IStringObject {
  sortField?: string
  sortOrder?: SortOrder
}

//can contain filters inside
export interface IGqlPagingQueryParams extends IPageSort {
  limit?: number
  offset?: number
}

export interface IQueryParamsInclPage extends IGqlPagingQueryParams {
  page?: number
}

export interface ISortConfiguration {
  dataField: string
  order: SortOrder
}

export type pageConfigurationType = {
  currentPage: number
  pageSize: number
  filterBy?: IStringObject<string>
  sort?: ISortConfiguration
}

const emptyQueryParams = (defaultPageSize?: number) => {
  return {
    limit: defaultPageSize ?? DEFAULT_PAGE_SIZE
  } as IGqlPagingQueryParams
}

const emptyPageConfiguration = (defaultPageSize?: number) => {
  return {
    currentPage: 1,
    pageSize: defaultPageSize ?? DEFAULT_PAGE_SIZE,
    filterBy: {}
  } as pageConfigurationType
}

function getCurrentQueryParams(search: string, validFiltrationKeys: string[]) {
  const currentParams: IStringObject = { filterBy: {}, filterByNotValid: {} }
  for (const pair of new URLSearchParams(search).entries()) {
    if (validFiltrationKeys.includes(pair[0])) currentParams.filterBy[pair[0]] = pair[1]
    else {
      if (!['page', 'limit', 'offset', 'sortField', 'sortOrder'].includes(pair[0])) currentParams.filterByNotValid[pair[0]] = pair[1]
    }

    if (['page', 'limit', 'offset'].includes(pair[0])) currentParams[pair[0]] = Number(pair[1])
    if (['sortField', 'sortOrder'].includes(pair[0])) currentParams[pair[0]] = pair[1]
  }
  return currentParams
}

function queryParamsAreValidForPaging(currentQueryParams: IQueryParamsInclPage) {
  if (currentQueryParams.sortField && !currentQueryParams.sortOrder) return false
  if (currentQueryParams.page && currentQueryParams.page < 0) return false
  if (currentQueryParams.offset && currentQueryParams.offset < 0) return false
  if (currentQueryParams.limit && (currentQueryParams.limit < 0 || currentQueryParams.limit > MAX_PAGE_SIZE)) return false

  return true
}

//loads params from URL query and validates them
export default function usePagingFromURLQuery(
  validFiltrationKeys: string[],
  nameForGqlTranslation?: IStringObject<{ sort: string; filter: string }>,
  params?: { alternativeOrderByName?: boolean; defaultSort?: IPageSort; defaultPageSize?: number }
) {
  const location = useLocation()
  const currentQueryParams: IQueryParamsInclPage = useMemo<IQueryParamsInclPage>(
    () => getCurrentQueryParams(location.search, validFiltrationKeys),
    [location.search, validFiltrationKeys]
  )
  const translateSortNameForGqlCB = useCallback(translateSortNameForGql, [])
  const translateFilterNameForGqlCB = useCallback(translateFilterNameForGql, [])
  return useMemo(() => {
    const pageConfiguration: pageConfigurationType = { ...emptyPageConfiguration(params?.defaultPageSize) }
    let gqlQueryParams: IGqlPagingQueryParams = { limit: params?.defaultPageSize ?? DEFAULT_PAGE_SIZE, offset: 0 }
    if (queryParamsAreValidForPaging(currentQueryParams)) {
      pageConfiguration.currentPage = currentQueryParams.page as number
      if (currentQueryParams.limit) {
        pageConfiguration.pageSize = currentQueryParams.limit
        gqlQueryParams.limit = currentQueryParams.limit
      }

      if (currentQueryParams.offset) gqlQueryParams.offset = currentQueryParams.offset
    } else {
      gqlQueryParams = { ...emptyQueryParams(params?.defaultPageSize) }
    }
    if (!currentQueryParams.sortField && params?.defaultSort && params?.defaultSort.sortField) {
      currentQueryParams.sortField = params.defaultSort.sortField
      currentQueryParams.sortOrder = params.defaultSort.sortOrder
    }
    if (currentQueryParams.sortField && (currentQueryParams.sortOrder === 'asc' || currentQueryParams.sortOrder === 'desc')) {
      pageConfiguration.sort = { dataField: currentQueryParams.sortField, order: currentQueryParams.sortOrder }
      gqlQueryParams[params?.alternativeOrderByName ? 'orderBy' : 'order'] =
        currentQueryParams.sortOrder === 'desc'
          ? `reverse:${translateSortNameForGqlCB(currentQueryParams.sortField)}`
          : translateSortNameForGqlCB(currentQueryParams.sortField)
    }
    if (Object.keys(currentQueryParams.filterBy).length > 0) {
      pageConfiguration.filterBy = currentQueryParams.filterBy
      for (const filter in currentQueryParams.filterBy) gqlQueryParams[translateFilterNameForGqlCB(filter)] = currentQueryParams.filterBy[filter]
    }
    if (Object.keys(currentQueryParams.filterByNotValid).length > 0)
      pageConfiguration.filterBy = { ...pageConfiguration.filterBy, ...currentQueryParams.filterByNotValid }

    return { pageConfiguration, gqlQueryParams }
  }, [currentQueryParams, translateFilterNameForGqlCB, translateSortNameForGqlCB])

  function translateSortNameForGql(name: string) {
    return nameForGqlTranslation?.[name]?.sort ? nameForGqlTranslation[name].sort : name
  }

  function translateFilterNameForGql(name: string) {
    return nameForGqlTranslation?.[name]?.filter ? nameForGqlTranslation[name].filter : name
  }
}
