export const containerTimesArrive = [
  { label: '07:00-09:00', value: '07:00-09:00' },
  { label: '09:00-11:00', value: '09:00-11:00' },
  { label: '11:00-13:00', value: '11:00-13:00' },
  { label: '13:00-15:00', value: '13:00-15:00' },
  { label: '15:00-17:00', value: '15:00-17:00' }
]

export const containerTimesLeave = [
  { label: '07:00-11:00', value: '07:00-11:00' },
  { label: '11:00-14:00', value: '11:00-14:00' },
  { label: '14:00-18:00', value: '14:00-18:00' },
  { label: 'Na zavolání', value: 'Na zavolání' }
]
