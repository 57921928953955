import { AccountingDocument } from '../../../../../graphql/aminTypes'
import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../../services/bootstrapTable/services/tableSpecificationTypes'
import { accountingDocumentsTranslation } from './accounting-document-translation'
import { formatDateTimeForDisplayLong, formatDateTimeForDisplayShort } from '../../../../../services/dataToString/dateTimeFormatter'
import { moneyToLocaleString } from '../../../../../services/dataToString/decimalToLocaleString'
import { FlagIcon } from '../../../../../components/svgIcons/FlagIcon'
import React from 'react'
import { addInvoiceActions } from '../../../../../services/bootstrapTable/add-invoice-actions'

export const accountingDocumentsDataDefinition: TableDefinitionTypes<AccountingDocument> = {
  columnDefinition: {
    id: { hidden: true },
    isCancelled: { hidden: true },
    type: { name: 'Typ' },
    createdAt: { name: 'Vytvořen' },
    amountInclVatKc: { name: 'Částka' },
    flags: { name: 'Příznaky' },
    actions: {
      name: 'A',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.right
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    isCancelled: x => x.isCancelled,
    type: x => accountingDocumentsTranslation[x.type],
    createdAt: x => formatDateTimeForDisplayShort(parseInt(x.createdAt)),
    amountInclVatKc: x => (x.amountInclVatKc ? moneyToLocaleString(x.amountInclVatKc, 'CZK') : 'N/A'),
    flags: x => (
      <>
        {x.isCancelled && <FlagIcon className="fe fe-x fe-larger" desc={'Zrušená'} value={true} />}
        {x.isGroupInvoice && <FlagIcon className="fe fe-layers fe-larger" desc={'Hromadná faktura'} value={true} />}
        {x.isAdditionalInvoice && <FlagIcon className="fe fe-file-plus fe-larger" desc={'Dofakturace'} value={true} />}
        {x.emailSentAt && (
          <FlagIcon className="fe fe-paperclip fe-larger" desc={`Odesláno emailem ${formatDateTimeForDisplayLong(parseInt(x.emailSentAt))}`} value={true} />
        )}
        {x.note && <FlagIcon className="fe fe-message-circle fe-larger" value={true} desc={'Poznámka: ' + x.note} />}
      </>
    ),
    actions: x => {
      const ret: IRowAction[] = []
      addInvoiceActions(ret, x)
      if (!x.isCancelled && (x.type === 'INVOICE' || x.type === 'PROFORMA_INVOICE') && !x.isGroupInvoice)
        ret.push({
          type: RowActionType.codeAction,
          value: 'SendInvoiceByEmail',
          icon: 'fe-mail',
          title: 'Odeslat zákazníkovi fakturu emailem'
        })
      if (!x.isCancelled && x.type === 'OPRAVNY_DANOVY_DOKLAD' && !x.emailSentAt)
        ret.push({
          type: RowActionType.codeAction,
          value: 'RefundEmail2Customer',
          icon: 'fe-send',
          title: 'Označit objednávku jako vrácenou, označit zaplacení ve Vyfakturuj a odeslat zákazníkovi emailem'
        })
      return ret
    }
  }
}

const openInvoiceLink = (invoiceLink: string) => () => {
  window.open(invoiceLink, '_blank', 'noopener,noreferrer')
}
