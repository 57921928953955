import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import SelectField from '../../../../components/formComponents/SelectField'
import ToggleField from '../../../../components/formComponents/ToggleField'
import FormField, { inputType } from '../../../../components/formComponents/FormField'

import LoadingContainer from '../../../../components/LoadingContainer'

import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { useUpsertSupplierMaterialPriceListMutation } from './mutations/upsert-supplier-material-price-list.mutation'
import { useProductMaterialListQuery } from '../../../ProductMaterialList/queries/product-material-list.query'
import { SupplierMaterialPriceListEntity } from '../../../../graphql/aminTypes'

export default function SupplierEditMaterialPrice({
  branchServiceId,
  onClose,
  editItem,
  doNotShowMaterials
}: {
  editItem?: SupplierMaterialPriceListEntity
  branchServiceId?: string
  onClose: () => void
  doNotShowMaterials: string[]
}) {
  const [upsertSupplierMaterialPriceListMut, upsertSupplierMaterialPriceListQR] = useUpsertSupplierMaterialPriceListMutation()
  const productMaterialListQR = useProductMaterialListQuery({})

  const formMethods = useForm<SupplierMaterialPriceListEntity>({
    defaultValues: {
      isActive: true,
      priceKc: 0,
      productMaterialId: '',
      priceForPublicKc: undefined
    }
  })

  const availableMaterials =
    productMaterialListQR.data?.productMaterialList.filter(x => !doNotShowMaterials.includes(x.id)).map(x => ({ value: x.id, label: x.name })) || []

  useEffect(() => {
    if (editItem && productMaterialListQR.data) {
      formMethods.reset({
        isActive: editItem.isActive,
        priceKc: editItem.priceKc,
        priceForPublicKc: editItem.priceForPublicKc,
        productMaterialId: editItem.productMaterialId
      })
    }
  }, [editItem, productMaterialListQR.data])

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(upsertSupplierMaterialPriceListQR, productMaterialListQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(updateMaterialPriceList)} autoComplete={'off'}>
          <div className="row mb-2">
            <div className="col-md-2">
              <ToggleField name={'isActive'} label={'Aktivní'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-6">
              <SelectField name={'productMaterialId'} label={'Materiál'} optionsData={availableMaterials} disabled={!!editItem} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <FormField type={inputType.number} name={'priceKc'} label={'Cena za 1t pro Metrák.cz'} valueAsNumber />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.number} name={'priceForPublicKc'} label={'Cena za 1t pro veřejnost'} valueAsNumber />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-2 offset-6">
              <button type="button" className="btn btn-secondary btn-block" onClick={() => onClose()}>
                Zavřít bez uložení
              </button>
            </div>
            <div className="col-md-2">
              <button type="button" className="btn btn-primary btn-block" onClick={() => upsertAndClose()}>
                Uložit a zavřít
              </button>
            </div>
            <div className="col-md-2">
              {!editItem && (
                <button type="submit" className="btn btn-primary btn-block">
                  Uložit a přidat další
                </button>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function upsertAndClose() {
    updateMaterialPriceList(formMethods.getValues())
    onClose()
  }
  function updateMaterialPriceList(data: any) {
    const promise = upsertSupplierMaterialPriceListMut({ variables: { branchServiceId: branchServiceId, data, materialPriceListId: editItem?.id } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Ceník byl uložen',
      successAction: () => {
        doNotShowMaterials.push(data.productMaterialId)
        formMethods.reset({
          isActive: true,
          priceKc: 0,
          productMaterialId: undefined
        })
      }
    })
  }
}
